import * as React from "react";
import cloneDeep from "lodash/cloneDeep";

import { useState, useEffect, useRef } from "react";
import useMobileBreakpoint from "@src/hooks/useMobileBreakpoint";
import { mobileBreakpoint } from "../../../../entrypoints/theme";
import { ProductTile } from "@project-stories/ecommerce/Product/ProductTile";
import PromotionTile from "@project-stories/ecommerce/PromotionalTile/PromotionTile";
import Breadcrumbs from "../Product/Breadcrumbs";
import FiltersSidebar from "@src/stories/ecommerce/Collection/FiltersSidebar";
import CollectionToolbar from "@project-stories/ecommerce/Collection/CollectionToolbar";
import LoadingSpinner from "@src/stories/elements/Spinner/LoadingSpinner";

export const Collection = ({ settings }) => {
  const [
    displayedProductsWithPromoBlocks,
    setDisplayedProductsWithPromoBlocks,
  ] = useState([]);
  const [products, setProducts] = useState([]);
  const [filters, setFilters] = useState([]);
  const isMobile = useMobileBreakpoint(mobileBreakpoint);
  const [isSidebarVisible, setSidebarVisible] = useState(false);

  const sortedPromotionBlocks = settings.promotion_blocks.sort((a, b) => {
    return b.sort_order - a.sort_order;
  });
  const promoBlocks = useRef(sortedPromotionBlocks);
  const paginationHaveLoaded = useRef(false);
  const isFiltering = useRef(false);


  const initialized = useRef(false);
  // const adjustedProductsPerPage = useRef(settings.products_per_page);

  const toggleSidebar = () => {
    setSidebarVisible((prevState) => {
      const newState = !prevState;
      document.body.classList.toggle("no-scroll__filters", newState);
      return newState;
    });
  };

  const {
    columns_mobile,
    columns_desktop,
    column_gap_mobile,
    column_gap_desktop,
    row_gap_mobile,
    row_gap_desktop,
    id,
  } = settings.section;

  const handleProductsResults = (event) => {
    if (event.detail.type === "plp") {
      window.dispatchEvent(
        new CustomEvent("hide-spinner", {
          detail: {
            id: "spinner-collection",
          },
        }),
      );

      isFiltering.current = event.detail.isFiltering;

      if (isFiltering.current) {
        setProducts(event.detail.products);

        if (paginationHaveLoaded.current) {
          window.dispatchEvent(
            new CustomEvent("current-products-updated", {
              detail: {
                totalViewed: event.detail.products.length,
              },
            }),
          );
        } else {
          const intervalId = setInterval(() => {
            if (paginationHaveLoaded.current) {
              window.dispatchEvent(
                new CustomEvent("current-products-updated", {
                  detail: {
                    totalViewed: event.detail.products.length,
                  },
                }),
              );

              clearInterval(intervalId);
            }
          }, 100);
        }
      }

      const { products, page } = event.detail;

      if (!isFiltering.current) {
        isFiltering.current === false;
        const currentPage = page + 1;
        const supposedToBeCurrentlyDisplayed =
          currentPage * settings.products_per_page;

        const promoBlocksToBeInserted = getPromoBlocksToBeInserted(
          supposedToBeCurrentlyDisplayed,
        );

        const nextPageCount =
          supposedToBeCurrentlyDisplayed - promoBlocksToBeInserted.length;

        const productsToBeAdded = getProductsFromLoadedProducts(
          products,
          nextPageCount,
        );

        const updatedDisplayedProductsWithPromoBlocks = insertPromoBlocks(
          productsToBeAdded,
          promoBlocksToBeInserted,
        );
        setDisplayedProductsWithPromoBlocks(
          updatedDisplayedProductsWithPromoBlocks,
        );

        if (paginationHaveLoaded.current) {
          window.dispatchEvent(
            new CustomEvent("current-products-updated", {
              detail: {
                totalViewed: productsToBeAdded.length,
              },
            }),
          );
        } else {
          const intervalId = setInterval(() => {
            if (paginationHaveLoaded.current) {
              window.dispatchEvent(
                new CustomEvent("current-products-updated", {
                  detail: {
                    totalViewed: productsToBeAdded.length,
                  },
                }),
              );

              clearInterval(intervalId);
            }
          }, 100);
        }
      }
    }
  };

  function handlePaginationLoad() {
    paginationHaveLoaded.current = true;
  }

  const handleSortResults = (event) => {
    if (event.detail.sortBy) {
      setSortOptions(event.detail.sortBy);
    }
  };

  const handleFiltersResults = (event) => {
    if (event.detail.type === "plp") {
      if (event.detail.filters) {
        setFilters(event.detail.filters);
      }
    }
  };

  const handleEscapeKey = (event) => {
    if (event.key === "Escape") {
      setSidebarVisible(false);
    }
  };

  useEffect(() => {
    if (!initialized.current) {
      initialized.current = true;

      window.dispatchEvent(new CustomEvent("collection-search-loaded"));

      window.addEventListener("pagination-have-loaded", handlePaginationLoad);
      window.addEventListener("product-list", handleProductsResults);
      window.addEventListener("filter-list", handleFiltersResults);
      window.addEventListener("keydown", handleEscapeKey);

      // Cleanup the event listeners on component unmount
      return () => {
        // window.removeEventListener("product-list", handleProductsResults);
        // window.removeEventListener("filter-list", handleFiltersResults);
        window.removeEventListener("keydown", handleEscapeKey);
      };
    }
  }, []);

  const getProductsFromLoadedProducts = (products, nextPageCount) => {
    return products.slice(0, nextPageCount);
  };

  const insertPromoBlocks = (productsToBeAdded, promoBlocksForNextPage) => {
    let productsCopy = productsToBeAdded.concat([]);

    promoBlocksForNextPage.forEach(({ position, block }) => {

      productsCopy.splice(position, 0, block);
    });

    return productsCopy;
  };

  const getPromoBlocksToBeInserted = (supposedToBeCurrentlyDisplayed) => {
    const blocksForNextPage: any = [];

    promoBlocks.current.forEach((block) => {
      block.position.sort((a, b) => a - b);
      block.position.forEach((position) => {
        if (position <= supposedToBeCurrentlyDisplayed) {
          blocksForNextPage.push({
            position: position - 1,
            block,
          });
        }
      });
    });
    return blocksForNextPage;
  };


  return (
    <div x-init={`initialise('${settings.collection.handle}', true)`}>
      <FiltersSidebar
        isVisible={isSidebarVisible}
        filters={filters}
        metaobject_filters={settings.filters}
        toggleSidebar={toggleSidebar}
      />
      <div className="collection__toolbar flex justify-between mb-4 items-center">
        {!isMobile && (
          <Breadcrumbs
            templateName={settings.template.name}
            shop={settings.shop}
            collection={settings.collection}
            product={null}
            routes={settings.routes}
            blog={null}
            article={null}
            page={settings.page}
            request={null}
            pageTitle={null}
          />
        )}
        <CollectionToolbar toggleSidebar={toggleSidebar} />
      </div>
      <div
        id="product-grid"
        className={`collection__product-grid relative grid grid-cols-${columns_mobile} lg:grid-cols-${columns_desktop} gap-x-[${column_gap_mobile}px] lg:gap-x-[${column_gap_desktop}px] gap-y-[${row_gap_mobile}px] lg:gap-y-[${row_gap_desktop}px] auto-cols-max`}
        data-id={id}
      >
        <LoadingSpinner id="spinner-collection" />

        {isFiltering.current ? (
          <>
            {products.map((block, index) => (
              <React.Fragment key={index}>
                <div className={"collection-tile"}>
                  <ProductTile product={block} siblings={[]} />
                </div>
              </React.Fragment>
            ))}
          </>
        ) : (
          <>
            {displayedProductsWithPromoBlocks.map((block, index) => (
              <React.Fragment key={index}>
                {block.type === "promo" ? (
                  <PromotionTile promotion={block} />
                ) : (
                  <div className={"collection-tile"}>
                    <ProductTile product={block} siblings={[]} />
                  </div>
                )}
              </React.Fragment>
            ))}
          </>
        )}
      </div>
    </div>
  );
};

export default Collection;
