import * as React from "react";

import ListProductsDefault from "./Layouts/ListProductsDefault";
import ListProductsTabbed from "./Layouts/ListProductsTabbed";
import ListProductsScrollable from "./Layouts/ListProductsScrollable";
import ListProductsGrid from "./Layouts/ListProductsGrid";

import './ListProducts.scss';

const layouts = {
    'list-tabbed': ListProductsTabbed,
    'list-default': ListProductsDefault,
    'list-scrollable': ListProductsScrollable,
    'list-grid': ListProductsGrid
}

export const ListProducts = (props) => {
    const LayoutComponent = layouts[props.settings?.section.layout_type] || ListProductsDefault;
    return <LayoutComponent {...props} />
};
