import { BlogArticle } from "@arctheme-components/blog/BlogArticle/BlogArticle";
import { BlogList } from "@arctheme-components/blog/BlogList/BlogList";
import { FeaturedBlog } from "@arctheme-components/blog/FeaturedBlog/FeaturedBlog";
import { SocialShare } from "@arctheme-components/blog/SocialShare/SocialShare";
import {BlogArticleTile} from "@project-stories/blog/BlogArticleTile/BlogArticleTile";
import BlogArticlesList from "@project-stories/blog/BlogArticlesList/BlogArticlesList";

export function BlogArticlesListComponent({ ...props }) {
  const { articles, section } = props.settings;

  return (
    <BlogArticlesList
      initialArticles={articles}
      section={section}
      settings={props.settings}
      title={"Latest Entries"}
    />
  );
}

export function BlogArticleComponent({ ...props }) {
  return (
    <BlogArticle
      reverselayout={false}
      settings={props.settings.section}
      blocks={props.settings.blocks}
    />
  );
}

export function BlogListComponent({ ...props }) {
  return (
    <BlogList
      reverselayout={false}
      settings={props.settings.section}
      blocks={props.settings.blocks}
    />
  );
}

export function FeaturedBlogComponent({ ...props }) {
  return (
    <FeaturedBlog
      reverselayout={false}
      article={props.settings.article}
      settings={props.settings.section}
      blocks={props.settings.blocks}
    />
  );
}
export function BlogArticleTileComponent({ ...props }) {
  return <BlogArticleTile article={props.settings.article} />;
}

export function SocialShareComponent({ ...props }) {
  return <SocialShare props={props.settings} />;
}
