import React, { useEffect, useState } from "react";
import { EcommProductPrice } from "@arctheme-components/ecommerce/Price/Price";
import { Button } from "@arctheme-components/elements/Button/Button";
import ImageComponent from "@project-stories/elements/Image/Image";
import { clsx } from "clsx";
import { getAlpineStore } from "../../../../entrypoints/theme";
import shopify from "../../../../js/shopify";

export const ProductTile = ({
  product,
  swatches = [],
  siblings,
  show_add = false,
  show_price_inline = false,
}) => {
  const store = getAlpineStore("modelview");

  const [modelView, setModelView] = useState(store.isModelView);
  const [isHovered, setIsHovered] = useState(false);
  const [relatedProducts, setRelatedProducts] = useState([]);

  let imageIndex = 0;
  let hoverIndex = 1;

  useEffect(() => {
    async function fetchProductSwatches(product) {
      const relatedProductsShopify = await shopify.getRelatedProducts([
        product,
      ]);
      setRelatedProducts(relatedProductsShopify);
    }

    fetchProductSwatches(product);

    const handleModelViewToggle = (event) => {
      setModelView(event.detail.isModelView);
    };

    window.addEventListener("modelview-toggled", handleModelViewToggle);

    return () => {
      window.removeEventListener("modelview-toggled", handleModelViewToggle);
    };
  }, []);

  if (product.media) {
    product.images = product.media;
  }

  const onlyFirstImage = /_s/i.test(product?.images?.[1]?.src);
  let secondImageIsSwatch = /_s/i.test(product?.images?.[1]?.src);

  if (modelView && !onlyFirstImage) {
    if (product?.images?.length - 1 > imageIndex) {
      if (!secondImageIsSwatch) {
        imageIndex = 1;
        hoverIndex = 0;
      } else {
        imageIndex = 0;
        hoverIndex = 0;
      }
    } else {
      if (!secondImageIsSwatch) {
        imageIndex = 0;
        hoverIndex = 0;
      }
    }
  } else {
    hoverIndex = 1;
  }

  if (product.images) {
    product.images = product?.images?.filter(
      (image) => image.src && !image.src.match(/_s_|_s(\.|$)/i), // 'i' flag makes the regex case-insensitive
    );
  }


  const productPrice = product?.price / 100;
  const comparePrice = product?.compare_at_price / 100;

  const createKeyValuePairs = (tags) => {
    const tagsArray = Array.isArray(tags) ? tags : [tags];

    return tagsArray.reduce((acc, tag) => {
      const [key, value] = tag.split(":");
      if (value) {
        if (acc[key]) {
          if (Array.isArray(acc[key])) {
            acc[key].push(value);
          } else {
            acc[key] = [acc[key], value];
          }
        } else {
          acc[key] = value;
        }
      }
      return acc;
    }, {});
  };

  const productTags = createKeyValuePairs(product.tags);
  const productSwatches: any = [];

  if (productTags?.colour) {
    const colors = Array.isArray(productTags.colour)
      ? productTags.colour
      : [productTags.colour];

    colors.forEach((colorHandle) => {
      const siblingProduct = siblings?.find(
        (sibling) => sibling.handle === colorHandle,
      );
      let isProduct = false;
      const matchingProduct = siblings?.find(
        (sibling) => sibling.handle === product.handle,
      );

      if (matchingProduct === siblingProduct) {
        isProduct = true;
      }
      if (siblingProduct) {
        productSwatches.push({
          url: "/products/" + siblingProduct.handle,
          name: product.title,
          image:
            siblingProduct.images?.find((image) =>
              image.src?.match(/_s_|_s(\.|$)/),
            )?.src ||
            siblingProduct.images[siblingProduct.images?.length - 1].src,
          is_product: isProduct,
        });
      }
    });
  }

  if (relatedProducts) {
    const colors = Array.isArray(productTags.colour)
      ? productTags.colour
      : [productTags.colour];

    colors.forEach((colorHandle) => {
      const relatedProduct = relatedProducts?.find(
        (related) => related.handle === colorHandle,
      );
      let isProduct = false;
      const matchingProduct = relatedProducts?.find(
        (related) => related.handle === product.handle,
      );

      if (matchingProduct === relatedProduct) {
        isProduct = true;
      }
      if (relatedProduct) {
        productSwatches.push({
          url: "/products/" + relatedProduct.handle,
          name: product.title,
          image:
            relatedProduct.images?.edges?.find((edge) =>
              edge.node.src?.match(/_s_|_s(\.|$)/),
            )?.node.src ||
            relatedProduct.images?.edges[relatedProduct.images.edges.length - 1]
              ?.node.src,
          is_product: isProduct,
        });
      }
    });
  }

  const getImageUrl = (image) => {
    if (typeof image === "object" && image !== null && image.src) {
      return image.src;
    }
    return image;
  };

  const groupFit = productTags?.Group_Fit || productTags?.group_fit;
  // @ts-ignore
  const currencySymbol = window.Alpine?.store('currency').getCurrencySymbol();

  return (
    <div className={"theme__product-card"}>
      <div className={"productcard__content"}>
        <a
          href={"/products/" + product.handle}
          className="w-full relative"
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
        >
          <ImageComponent
            classes={clsx(
              "product-image",
              isHovered &&
                product.images.length > 1 &&
                "opacity-0 transition-opacity duration-500",
            )}
            imageUrl={getImageUrl(product.images[imageIndex])}
          />
          <ImageComponent
            classes={clsx(
              "product-image absolute top-0 left-0",
              isHovered && product.images.length > 1
                ? "opacity-100 transition-opacity duration-500"
                : "opacity-0",
            )}
            imageUrl={getImageUrl(product.images[hoverIndex])}
          />
        </a>
        <div className={"flex justify-between mt-[16px] flex-col lg:flex-row"}>
          <div>
            <a href={"/products/" + product?.handle}>
              <p className={"b2 text-[#54565A]"}>{product?.title}</p>
            </a>
            <p className={"bc uppercase text-[13px] font-[500] text-[#54565A]"}>
              {groupFit}
            </p>
          </div>
          <div
            className={
              "flex items-center justify-end flex-row-reverse lg:flex-col lg:items-end"
            }
          >
            <span className={"b1 font-regular text-[#54565a]"}>
              {!show_price_inline && (
                <EcommProductPrice
                  price={currencySymbol + productPrice.toFixed(2)}
                  compare_at_price={comparePrice}
                />
              )}
            </span>
            {comparePrice > 0 && productPrice < comparePrice && (
              <span
                className={
                  "text-[12px] line-through text-[#BBBBBD] mr-[8px] lg:mr-0"
                }
              >

                <div className="product__price" data-prodify-price-container="">
                  {currencySymbol + comparePrice.toFixed(2)}
                </div>
              </span>
            )}
          </div>
        </div>

        <div className="product__swatches mt-[16px]">
          {productSwatches.map((swatch, id) => (
            <a
              key={id}
              href={`${swatch.url}`}
              className={` ${clsx({
                "border-b-2 border-[#54565A]": swatch.is_product,
              })}`}
            >
              <div className="product__swatch h-[16px] w-[16px] mb-[2px] cursor-pointer transition-all hover:opacity-70">
                {swatch.color && (
                  <div className="mb-[2px]">
                    <div
                      className="product__swatch_color"
                      style={{ backgroundColor: swatch.color }}
                    ></div>
                  </div>
                )}
                {swatch.image && (
                  <div className="h-[16px] w-[16px] mb-[2px] cursor-pointer ">
                    <img
                      className="product__swatch_image w-full h-full object-cover"
                      src={swatch.image}
                      alt={swatch.name}
                    />
                  </div>
                )}
                {swatch.show_name && swatch.name && swatch.name}
              </div>
            </a>
          ))}
        </div>

        <div className={"mt-[16px]"}>
          <p className={"b3 text-[14px] font-[400] text-[#CDA176]"}>
            {productTags.automatic}
          </p>
        </div>
      </div>
    </div>
  );
};

export default ProductTile;
