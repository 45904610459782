import * as React from "react";

export const TextField = ({
      id,
      inputType = 'text',
      placeholder,
      name = '',
      value,
      required = false,
      onChange = (event) => {},
      disabled = false,
      type = 'default-input',
      style = {},
  }) => {
    if (!name) {
        name = id;
    }
    return (

        <div className={`field ${type}`}>
            <input
                type={inputType}
                id={id}
                data-testid={id}
                name={name}
                className="input_inputfield"
                value={value}
                placeholder={type === 'default-input' ? placeholder : ''}
                required={required}
                onChange={onChange}
                disabled={disabled}
                style={style}
            />
            {type === 'floating-input' && (
                <label htmlFor={id}>{placeholder}</label>
            )}
        </div>
    );
};
