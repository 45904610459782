import * as React from "react";
import "alpinejs";
import { TextField } from "@arctheme-components/elements/TextField/TextField";
import HeaderMenu from "@project-stories/navigation/Header/HeaderMenu";
import HeaderActions from "@project-stories/navigation/Header/HeaderActions";
import LogoBlock from "@project-stories/navigation/Header/HeaderLogo";
import MobileMenu from "@project-stories/navigation/Header/HeaderMobileMenu";

const Header = ({
  colorSchema,
  links,
  item_count = "",
  settings,
  type = "left",
  show_search_bar = false,
}) => {
  const LayoutComponent = Layouts[type];
  return (
    <div
      className={`header-container`}
      x-init="$nextTick(() => { $store.navigation.init() })"
    >
      <LayoutComponent
        settings={settings}
        links={links}
        item_count={item_count}
      />
    </div>
  );
};

const HeaderCenteredLayout = ({
  colorSchema,
  links,
  item_count = "",
  settings,
  type = "left",
  show_search_bar = false,
}) => {
  return (
    <div className="w-full flex items-center relative lg:py-[16px]">
      <div className={"mobile-menu-container lg:hidden lg:basis-1/3"}>
        <MobileMenu linkList={links} settings={settings} />
      </div>

      <div className={"hidden lg:block flex-grow lg:basis-1/3"}>
        <HeaderMenu link_list={links} />
      </div>

      <div className={"flex relative justify-center flex-grow-0 basis-1/3"}>
        <LogoBlock settings={settings} className={""} />
      </div>

      <div className="flex items-center space-x-[24px] justify-end header-toolbar flex-grow lg:basis-1/3">
        <HeaderActions item_count={item_count} settings={settings} />
      </div>
    </div>
  );
};

const HeaderRightLayout = ({
  colorSchema,
  links,
  item_count = "",
  settings,
  type = "left",
  show_search_bar = false,
}) => {
  return (
    <div className="container flex items-center relative">
      <MobileMenu linkList={links} settings={settings} />
      <HeaderActions item_count={item_count} settings={settings} />
      <HeaderMenu link_list={links} />
      <LogoBlock settings={settings} />
    </div>
  );
};
const HeaderLeftLayout = ({
  colorSchema,
  links,
  item_count = "",
  settings,
  type = "left",
  show_search_bar = false,
}) => {
  return (
    <div className="container flex items-center relative">
      <MobileMenu linkList={links} settings={settings} />
      <LogoBlock settings={settings} />

      <HeaderMenu link_list={links} />

      {show_search_bar && (
        <div className={"header-search"}>
          <TextField id={"search"} placeholder={"Search..."} value={""} />
        </div>
      )}
      <div className={"ml-auto"}>
        <HeaderActions item_count={item_count} settings={settings} />
      </div>
    </div>
  );
};
const Header2RowSplitLayout = ({
  colorSchema,
  links,
  item_count = "",
  settings,
  type = "left",
  show_search_bar = false,
}) => {
  return (
    <div className={"header-rows"}>
      <div className={"header-row"}>
        <LogoBlock settings={settings} />
        <div className={"header-search"}>
          <TextField id={"search"} placeholder={"Search..."} value={""} />
        </div>
        <HeaderActions item_count={item_count} settings={settings} />
      </div>
      <div className={`header-row`}>
        <MobileMenu linkList={links} settings={settings} />
        <HeaderMenu link_list={links} />
      </div>
    </div>
  );
};

const Layouts = {
  "header-layout-center": HeaderCenteredLayout,
  "header-layout-left": HeaderLeftLayout,
  "header-layout-right": HeaderRightLayout,
  "header-split-2row-left": Header2RowSplitLayout,
};

export default Header;
