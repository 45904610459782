import React, { useState, useEffect } from "react";
import AlpineWidget from "@arctheme-components/AlpineWidget";
import ImageComponent from "@project-stories/elements/Image/Image";

import { TextField } from "@arctheme-components/elements/TextField/TextField";
import { Paragraph } from "@arctheme-components/elements/Paragraph/Paragraph";
import LoadingSpinner from "@project-stories/elements/Spinner/LoadingSpinner";
import shopify from "../../../../js/shopify";
import ProductTag from "@src/stories/ecommerce/Product/ProductTag";

const Minicart = ({ cartData, style = "drawer", settings, props }) => {

  const [cart, setCart] = useState({
    items: cartData.items,
    item_count: cartData.item_count,
    items_subtotal_price: cartData.items_subtotal_price,
  });

  useEffect(() => {
    // Function to fetch the cart data
    const fetchCart = async (event) => {
      window.dispatchEvent(
        new CustomEvent("show-spinner", {
          detail: {
            id: "cart-spinner",
          },
        }),
      );

      // console.log('Cart Fetched: ', event.detail.cart);
      try {
        const response = await fetch(`/cart.js`);
        const cartData = await response.json();
        setCart(cartData);
      } catch (error) {
        console.error("Error fetching cart:", error);
      }
      window.dispatchEvent(
        new CustomEvent("hide-spinner", {
          detail: {
            id: "cart-spinner",
          },
        }),
      );
    };

    // Fetch cart data initially
    fetchCart({ detail: {} });

    // Set up an observer for cart changes using the Liquid Ajax Cart JS library
    document.addEventListener("liquid-ajax-cart:request-end", fetchCart);

    // Clean up the event listener when the component unmounts
    return () => {
      document.removeEventListener("liquid-ajax-cart:request-end", fetchCart);
    };
  }, []);

  return (
    <>
      <div
        className={`minicart-wrapper ${style} relative pointer-events-auto`}
        x-cloak="true"
        x-transition:enter="transition ease-out duration-300"
        x-transition:enter-start="off-screen-right"
        x-transition:enter-end="right-50"
        x-transition:leave="transition ease-in duration-300"
        x-transition:leave-start="right-50"
        x-transition:leave-end="off-screen-right"
        x-show="$store.global.isMinicartVisible"
      >
        <div className={"minicart w-full md:max-w-[450px]"}>
          <LoadingSpinner id={"cart-spinner"} />
          <div className="flex-none">
            <CartTitle settings={props} />
            <CartCount item_count={cart.item_count} />
          </div>
          <div className="flex-1 overflow-y-auto">
            <CartItems
              items={cart.items}
              show_vendor={false}
              show_total_price={settings.show_total_price}
            />

            {cart.items.length <= 0 && (
              <div
                className={
                  "flex flex-col gap-y-[16px] text-center h-full justify-center p-[16px]"
                }
              >
                <div className={"b2 text-[#54565A]"}>
                  {settings.empty_text_one}
                  <br />
                  {settings.empty_text_two}
                </div>
                <a
                  x-on:click="$store.global.toggleMinicart()"
                  className={"minicart-empty__button button btn-outline"}
                >
                  {settings.empty_button}
                </a>
              </div>
            )}
          </div>
          {cart.items.length > 0 && (
            <div
              className={
                "flex-none bg-white pb-6 border-t border-[#ECEBEB] px-4 lg:px-8"
              }
            >
              <CartTotals
                cart_subtotal={cart.total_price / 100}
                settings={settings}
              />
              {/*<CartNotes show={true}/>*/}
              <MiniCartActions show_cart={false} />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

const CartTitle = ({ settings }) => {
  const { globals } = settings;
  return (

           <div className="title-row px-[24px] py-[13px]">
            <div className="b1 text-[#BBBBBD]" id="minicart-label">My Shopping Bag</div>

             <div className="flex gap-[24px]">


               <a href="/account" className="antialiased [&>svg]:h-[28px]">
                 {!globals.is_customer_logged_in && (<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                   <path fillRule="evenodd" clipRule="evenodd"
                         d="M4.26005 19.5H19.74C19.909 19.5006 20.0668 19.4158 20.1596 19.2746C20.2524 19.1334 20.2676 18.9549 20.2 18.8L19.5 16.2C18.9121 14.0163 16.9315 12.4991 14.67 12.5H9.33005C7.06856 12.4991 5.08803 14.0163 4.50005 16.2L3.80005 18.8C3.73246 18.9549 3.74766 19.1334 3.84045 19.2746C3.93324 19.4158 4.09108 19.5006 4.26005 19.5Z"
                         stroke="currentColor" strokeLinecap="round"/>
                   <circle cx="12" cy="7" r="3.5" stroke="currentColor" strokeLinecap="round"/>
                 </svg>) }
                 {globals.is_customer_logged_in && (<svg
                     id="Layer_2"
                     data-name="Layer 2"
                     xmlns="http://www.w3.org/2000/svg"
                     version="1.1"
                     viewBox="320 308 440 600"
                 >
                   <path
                       className="cls-1"
                       d="M766.3,723.2c-4.5-17.5-9.3-35-14-52.5-19-72-67.7-114.6-138.1-117.2-50.8-1.9-101.8-1.6-152.7.1-58.3,2-108.8,41.3-127.1,96.7-7.9,24.1-14,48.8-20.8,73.3-6.9,24.5,4,38.7,29.6,38.7,65.4,0,130.9,0,196.3,0s130.9,0,196.3,0c26.9,0,37-13.1,30.4-39.2Z"
                   />
                   <path
                       className="cls-1"
                       d="M540.6,526.5c58.3-.3,104.4-47.1,103.9-105.4-.5-57.8-46.6-103.5-104.4-103.6-58.3,0-104.7,46.4-104.6,104.7.1,58.3,46.8,104.6,105.1,104.3Z"
                   />
                 </svg>
                   )}
               </a>


               <a className="cursor-pointer" x-on:click="$store.global.toggleMinicart()">
                 <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                   <path
                       d="M19.82 4.17999C19.58 3.94 19.1912 3.94 18.9513 4.17999L12 11.1313L5.04874 4.17999C4.80875 3.94 4.41996 3.94 4.17999 4.17999C3.94002 4.41998 3.94 4.80877 4.17999 5.04874L11.1313 12L4.17999 18.9513C3.94 19.1912 3.94 19.58 4.17999 19.82C4.41998 20.06 4.80876 20.06 5.04874 19.82L12 12.8687L18.9513 19.82C19.1912 20.06 19.58 20.06 19.82 19.82C20.06 19.58 20.06 19.1912 19.82 18.9513L12.8687 12L19.82 5.04874C20.06 4.80875 20.06 4.41996 19.82 4.17999Z"
                       fill="#99A8B2"/>
                 </svg>
               </a>
             </div>
           </div>

  );
};

const CartCount = ({item_count}) => {
  return (
      <div className="item-count-message b2 bg-[#F5F5F5] p-[24px] flex gap-x-[8px]">
        <span className={"b-characteristics text-[#99A8B2]"}>Total Items</span>
        <span className="b-characteristics text-[#00263E]">{item_count}</span>
      </div>
  );
};


const createKeyValuePairs = (tags) => {
  const tagsArray = Array.isArray(tags) ? tags : [tags];

  return tagsArray.reduce((acc, tag) => {
    const [key, value] = tag.split(":");
    if (value) {
      if (acc[key]) {
        if (Array.isArray(acc[key])) {
          acc[key].push(value);
        } else {
          acc[key] = [acc[key], value];
        }
      } else {
        acc[key] = value;
      }
    }
    return acc;
  }, {});
};

const CartItems = ({items, show_vendor = false, show_total_price = true}) => {
  const AjaxCartQuantity = "ajax-cart-quantity" as keyof JSX.IntrinsicElements;
  const [products, setProducts] = useState([]);
  // @ts-ignore
  const currencySymbol = window.Alpine?.store('currency').getCurrencySymbol();

  useEffect(() => {
    if (items && items.length > 0) {
      const productIdsList = [...new Set(items.map(product => product.product_id))];

      async function fetchProducts(productIds) {
        const products = await shopify.getShopifyProductList(productIds);
        setProducts(products);
      }

      fetchProducts(productIdsList);
    }
  }, [items]);

  return (
    <div className={"relative"}>
      {items.length > 0 && (
        <ul id="items" className="items-list px-[16px] flex flex-col flex-grow">
          {items.map((line_item, index) => {
            const line_item_price = line_item.line_price / 100;
            const line_item_quantity = line_item.quantity;
            const indexLineItem = index + 1;
            const size = line_item.options_with_values.find(
              (option) => option.name.toLowerCase() === "size",
            );
            const colour = line_item.options_with_values.find(
              (option) => option.name.toLowerCase() === "color",
            );
            const value = line_item.options_with_values.find(
              (option) => option.name.toLowerCase() === "value",
            );
            const properties = Object.keys(line_item.properties).map((key) => {
              return { name: key, value: line_item.properties[key] };
            });
            let productTags: any = [];
            if (products) {
              products.forEach((product) => {
                  if (line_item.product_id === product.id) {
                    line_item.product = product;
                    productTags = createKeyValuePairs(product.tags);
                  }
              });
            }

            let totalDiscount = 0;
            if (line_item.discounts) {
                line_item.discounts.forEach((discount) => {
                    totalDiscount += discount.amount;
                });
            }
            // console.log(line_item.product);

            return (
              <>

                <li
                  data-id={indexLineItem}
                  id={`${line_item.id}`}
                  data-line={indexLineItem}
                  key={line_item.id}
                  className="item"
                >
                  <a href={"/products/" + line_item.handle}>
                    <ImageComponent
                      imageUrl={line_item.image}
                      alt={line_item.product_title}
                      isLazy={false}
                      classes={"item__image"}
                    />
                  </a>

                  <div className={`item-details flex-auto`}>
                    <div>
                      <div className="item-details__title bc uppercase">
                        <span className={`item-details__title-product`}>
                          {line_item.product_title}
                        </span>
                        &nbsp;
                        {colour && (
                          <span className="item-details__title-colour">
                            {colour.value}
                          </span>
                        )}
                      </div>

                      {show_vendor && (
                        <small className={`item-vendor`}>
                          {line_item.vendor}
                        </small>
                      )}

                      <div className={`item-options`}>
                        {size && (
                          <p className={`option`} data-option-name={size.name}>
                            <span className={"b3 text-[#99A8B2]"}>
                              {size.name}:{" "}
                            </span>
                            <span className={"b3"}>{size.value}</span>
                          </p>
                        )}
                        {value && (
                          <p className={`option`} data-option-name={value.name}>
                            <span className={"b3 text-[#99A8B2] capitalize"}>
                              {value.name}:{" "}
                            </span>
                            <span className={"b3"}>{value.value}</span>
                          </p>
                        )}
                        {properties &&
                          properties.map((property) => (
                            <p
                              className={`option`}
                              data-option-name={property.name}
                            >
                              <span className={"b3 text-[#99A8B2] capitalize"}>
                                {property.name}:{" "}
                              </span>
                              <span className={"b3 break-all"}>{property.value}</span>
                            </p>
                          ))}
                      </div>


                      <div className={'discounts_applied'}>
                          {line_item.discounts.map((discount) => {
                            return (
                                <p className={"b3 text-[14px] font-[400] text-red-500"}>
                                {discount.title}
                              </p>
                            );
                          })}
                      </div>

                      {totalDiscount <= 0 && (
                      <p className={"b3 text-[14px] font-[400] text-[#CDA176]"}>
                        {productTags?.automatic}
                      </p>)}

                      <div
                        className={`flex items-center quantity-selector gap-[16px]`}
                      >
                        <AjaxCartQuantity>
                          <div className="flex items-center w-full max-w-[108px] h-[40px] bg-[#F5F5F5]">
                            <button
                              data-ajax-cart-quantity-minus
                              className="px-3 h-auto w-auto"
                            >
                              <span className="icon-minus text-[14px] text-[#99A8B2]"></span>
                            </button>
                            <input
                              data-ajax-cart-quantity-input={`${indexLineItem}`}
                              defaultValue={line_item_quantity}
                              type="number"
                              className="quantity-control-input b3"
                            />
                            <button
                              data-ajax-cart-quantity-plus
                              className="px-3 h-auto"
                            >
                              <span className="icon-plus text-[14px] text-[#99A8B2]"></span>
                            </button>
                          </div>
                        </AjaxCartQuantity>

                        <a className={`item__remove b3`}>
                          <div
                            data-ajax-cart-request-button={`/cart/change?id=${line_item.key}&quantity=0`}
                          >
                            Remove
                          </div>
                        </a>
                      </div>

                      <div
                        className="text-[10px] errors"
                        style={{ padding: 0 }}
                        data-ajax-cart-errors={`${line_item.key}`}
                      ></div>
                    </div>

                    <div className={"flex justify-end"}>
                      <div className={"flex flex-cols"}>
                        {!show_total_price ? (
                          <>
                            <p className={`b-price`}>
                              {currencySymbol + line_item_price.toFixed(2)}
                            </p>
                            {line_item.product?.compare_at_price > 0 && (
                              <p className={`b-price`}>
                                {currencySymbol + line_item.product?.compare_at_price / 100}
                              </p>
                            )}
                          </>
                        ) : (
                          <div className={'flex flex-col text-right'}>
                            <p className={`b-price`}>

                              {currencySymbol + (line_item_price).toFixed(
                                2,
                              )}
                            </p>
                            {line_item.product?.compare_at_price && line_item.discounts.length <= 0 && (
                              <p className={`b-price line-through text-[#BBBBBD] text-[12px]`}>

                                {currencySymbol}
                                {
                                    ((line_item.product?.compare_at_price * line_item.quantity) / 100).toFixed(2)
                                }
                              </p>
                            )}
                            {!line_item.product?.compare_at_price && line_item.discounts.length > 0 && (
                                <p className={`b-price line-through text-[#BBBBBD] text-[12px]`}>

                                  {currencySymbol}
                                  {(line_item.original_line_price  / 100)}
                                </p>
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </li>
                <hr className="border-none h-[1px] bg-[#ECEEEF]" />
              </>
            );
          })}
        </ul>
      )}
    </div>
  );
};

const CartTotals = ({ cart_subtotal, settings }) => {
  // @ts-ignore
  const currencySymbol = window.Alpine?.store('currency').getCurrencySymbol();


  return (
    <div className={"pt-3"}>
      <div className="minicart__totals uppercase">
        <Paragraph text={"Total"} type={"bc text-[#54565A] font-medium"} />
        <Paragraph text={`${currencySymbol}${cart_subtotal.toFixed(2)}`} type={"h3"} />
      </div>
      {settings.cart_info && (
        <Paragraph text={settings.cart_info} type={"minicart__info"} />
      )}
    </div>
  );
};

const CartNotes = ({ show = false }) => {
  return (
    <>
      <div className="cart_notes">
        <TextField
          type={"floating-input"}
          placeholder={"Enter notes for cart"}
          id={"cart_note"}
          value={undefined}
        />
      </div>
    </>
  );
};

const MiniCartActions = ({ show_cart }) => {
  return (
    <div className={"minicart__actions"}>
      <form id="checkout" action="/checkout" method="post" className=""></form>
      <form id="cart" action="/cart" method="get" className=""></form>

      {show_cart && (
        <button className="btn-outline" type="submit" form={"cart"}>
          View Cart
        </button>
      )}

      <div className={"minicart__buttons"}>
        <button
          className="btn-primary"
          type="submit"
          name="checkout"
          form="checkout"
        >
          Proceed to Checkout
        </button>
      </div>
    </div>
  );
};

export default Minicart;
