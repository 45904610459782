import React from 'react';

const Breadcrumbs = ({ templateName, shop, collection, product, routes, blog, article, page, request, pageTitle }) => {
    return (
        <div className="breadcrumbs-container hidden lg:block">
            <nav aria-label="breadcrumb" className="breadcrumb-container container px-3 lg:px-8">
                <ol className="breadcrumb flex flex-row items-center text-[#54565A] text-xs uppercase leading-none bc" itemScope itemType="http://schema.org/BreadcrumbList">
                    <li className="breadcrumb__item" itemProp="itemListElement" itemScope itemType="http://schema.org/ListItem">
                        <a href={shop.url} title={shop.name} itemProp="item" itemScope itemType="http://schema.org/Thing" itemID="/">
                            <span itemProp="name">Home</span>
                        </a>
                        <meta itemProp="position" content="1" />
                    </li>

                    {(() => {
                        switch (templateName) {
                            case 'product':
                                return (
                                    <>
                                        <li className="breadcrumb__item whitespace-nowrap" itemProp="itemListElement" itemScope itemType="http://schema.org/ListItem">
                                            {collection ? (
                                                <a href={collection.url} title={collection.title} itemProp="item" itemScope itemType="http://schema.org/Thing" itemID={collection.url}>
                                                    <span itemProp="name">{collection.title}</span>
                                                </a>
                                            ) : (
                                                <a href={routes.all_products_collection_url} title="All Products" itemProp="item" itemScope itemType="http://schema.org/Thing" itemID={routes.all_products_collection_url}>
                                                    <span itemProp="name">All Products</span>
                                                </a>
                                            )}
                                            <meta itemProp="position" content="2" />
                                        </li>
                                        <li className="breadcrumb__item whitespace-nowrap breadcrumb__item--active text-[#BBBBBD]" aria-current="page" itemProp="itemListElement" itemScope itemType="http://schema.org/ListItem">
                                            <span itemScope itemType="http://schema.org/Thing" itemProp="item" itemID={page.url}>
                                                <span itemProp="name">{product.title}</span>
                                            </span>
                                            <meta itemProp="position" content="3" />
                                        </li>
                                    </>
                                );
                            case 'cart':
                                return (
                                    <li className="breadcrumb__item whitespace-nowrap breadcrumb__item--active text-[#BBBBBD]" aria-current="page" itemProp="itemListElement" itemScope itemType="http://schema.org/ListItem">
                                        <span itemScope itemType="http://schema.org/Thing" itemProp="item" itemID={routes.cart_url}>
                                            <span itemProp="name">Cart</span>
                                        </span>
                                        <meta itemProp="position" content="2" />
                                    </li>
                                );
                            case 'collection':
                                return (
                                    <li className="breadcrumb__item whitespace-nowrap breadcrumb__item--active text-[#BBBBBD]" aria-current="page" itemProp="itemListElement" itemScope itemType="http://schema.org/ListItem">
                                        <span itemScope itemType="http://schema.org/Thing" itemProp="item" itemID={collection.url}>
                                            <span itemProp="name">{collection.title}</span>
                                        </span>
                                        <meta itemProp="position" content="2" />
                                    </li>
                                );
                            case 'blog':
                                return (
                                    <li className="breadcrumb__item whitespace-nowrap breadcrumb__item--active text-[#BBBBBD]" aria-current="page" itemProp="itemListElement" itemScope itemType="http://schema.org/ListItem">
                                        <span itemScope itemType="http://schema.org/Thing" itemProp="item" itemID={blog.url}>
                                            <span itemProp="name">{blog.title}</span>
                                        </span>
                                        <meta itemProp="position" content="2" />
                                    </li>
                                );
                            case 'article':
                                return (
                                    <>
                                        <li className="breadcrumb__item whitespace-nowrap" itemProp="itemListElement" itemScope itemType="http://schema.org/ListItem">
                                            <a href={blog.url} title={blog.title} itemProp="item" itemScope itemType="http://schema.org/Thing" itemID={blog.url}>
                                                <span itemProp="name">{blog.title}</span>
                                            </a>
                                            <meta itemProp="position" content="2" />
                                        </li>
                                        <li className="breadcrumb__item whitespace-nowrap breadcrumb__item--active text-[#BBBBBD]" aria-current="page" itemProp="itemListElement" itemScope itemType="http://schema.org/ListItem">
                                            <span itemScope itemType="http://schema.org/Thing" itemProp="item" itemID={page.url}>
                                                <span itemProp="name">{article.title}</span>
                                            </span>
                                            <meta itemProp="position" content="3" />
                                        </li>
                                    </>
                                );
                            default:
                                return (
                                    <li className="breadcrumb__item whitespace-nowrap breadcrumb__item--active text-[#BBBBBD]" aria-current="page" itemProp="itemListElement" itemScope itemType="http://schema.org/ListItem">
                                        <span itemScope itemType="http://schema.org/Thing" itemProp="item" itemID={request.path}>
                                            <span itemProp="name">{page.title || pageTitle}</span>
                                        </span>
                                        <meta itemProp="position" content="2" />
                                    </li>
                                );
                        }
                    })()}
                </ol>
            </nav>
        </div>
    );
};

export default Breadcrumbs;
