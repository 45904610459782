import React, { useState, useEffect, useRef } from "react";
import useMobileBreakpoint from "@src/hooks/useMobileBreakpoint";
import { mobileBreakpoint } from "@src/../entrypoints/theme";
import BannerOverlay from "@src/stories/sections/Banner/BannerOverlay/BannerOverlay";
import useVideoSource from "@src/hooks/useVideoSourceQuality";
import "./Banner.scss";

export const Banner = ({
                         banners,
                         useCarousel = true,
                         section_settings,
                         props,
                       }) => {
  const isMobile = useMobileBreakpoint(mobileBreakpoint);
  const [videoSources, setVideoSources] = useState([]);
  const [videoPlaying, setVideoPlaying] = useState(
      Array(banners.length).fill(false),
  );
  const videoRefs = useRef([]);

  const { globals } = props;
  let showButtons = section_settings.show_buttons;
  if (isMobile) {
    showButtons = section_settings.show_buttons_mobile;
  }

  let showDots = section_settings.show_indicators;
  if (isMobile) {
    showDots = section_settings.show_indicators_mobile;
  }

  let autoPlay: any = false;

  if (section_settings.auto_scroll) {
    if (section_settings.scroll_timer) {
      autoPlay = section_settings.scroll_timer * 1000;
    }
  }

  // Pre-process the video sources using the hook
  const processedVideos = banners.map((blockItem) => {
    if (blockItem.type === "block_video") {
      const videoSettings = isMobile
          ? blockItem.settings.video_mobile
          : blockItem.settings.video;

      const videoSources = videoSettings.sources;
      console.log(videoSources);
      // Call the hook to get video URL and poster for each video block
      const { videoUrl, videoPoster } = useVideoSource(
          videoSources,
          videoSettings.preview_image,
          isMobile,
          globals.video_quality
      );

      return { videoUrl, videoPoster };
    }
    return null;
  });

  useEffect(() => {
    console.log(processedVideos);
    setVideoSources(processedVideos);
  }, []); // useEffect will trigger when processedVideos changes

  useEffect(() => {
    banners.forEach((blockItem, index) => {
      if (blockItem.type === "block_video" && videoRefs.current[index]) {
        const videoElement = document.querySelector(`#video-${index}`);
        videoRefs.current[index] = videoElement;
      }
    });
  }, [banners]);

  const handlePlayButtonClick = (index) => {
    const updatedVideoPlaying = [...videoPlaying];
    updatedVideoPlaying[index] = true;
    setVideoPlaying(updatedVideoPlaying);

    const videoElement: any = videoRefs.current[index];
    if (videoElement) {
      videoElement.controls = false;
      videoElement.play();
    }
  };

  const height = section_settings.height;
  const heightClass = `${ height > 0 ? `lg:h-[${height}px]` : `lg:h-full ${section_settings.is_primary && "lg:h-screen"}`}`;

  return (
      <div
          className={`banner ${heightClass} h-[${section_settings.mobile_height}px]  w-full`}
          style={{
            minHeight: height > 0 ? 'auto' : 'initial',
          }}
      >
        <div
            className={"banner-carousel w-full h-full"}
            {...(useCarousel && {
              "x-data": "carousel",
              "x-init": `$nextTick(() => { initialise(${showDots}, ${showButtons}, false, false, ${autoPlay}, 'banner', false) })`,

              "x-ref": "carousel",
            })}
        >
          {banners.map((blockItem, index) => {
            let imageUrl =
                blockItem.settings.image?.src ?? blockItem.settings.image;
            if (isMobile) {
              imageUrl = blockItem.settings.mobile_image
                  ? blockItem.settings.mobile_image
                  : blockItem.settings.image?.src ?? blockItem.settings.image;
            }

            const videoStr = `
                      <video
                        id="video-${index}"
                        muted
                        ${videoPlaying[index] || blockItem.settings.should_autoplay ? "autoplay" : ""}
                        ${videoPlaying[index] || blockItem.settings.show_controls ? "controls" : ""}
                        ${blockItem.settings.should_loop ? "loop" : ""}
                        class="object-cover h-full w-full"
                        poster="${videoSources[index]?.videoPoster || ""}"
                        preload="metadata"
                        playsinline>
                        <source src="${videoSources[index]?.videoUrl || ""}" type="video/mp4"/>
                      </video>
                    `;

            const VideoContent = () => (
                <div
                    className={`flex lg:h-full w-full ${blockItem.settings.darken_image && "content-over-media"} relative ${section_settings.use_carousel && `h-full`} ${!section_settings.use_carousel && `h-[${section_settings.mobile_height}px]`}`}
                >
              <span
                  className="h-full w-full"
                  dangerouslySetInnerHTML={{ __html: videoStr }}
              />
                  {!videoPlaying[index] && !blockItem.settings.should_autoplay && (
                      <div className="absolute flex justify-center h-full w-full items-center">
                        <button
                            className="relative flex items-center justify-center text-black fill-black bg-[#e5e7eb] rounded-full w-[50px] h-[50px]"
                            onClick={() => handlePlayButtonClick(index)}
                        >
                          <i className="icon icon-play text-black"></i>
                        </button>
                      </div>
                  )}
                </div>
            );

            const BannerImageContent = () => {
              const focalPointX =
                  blockItem.image_focal_points && blockItem.image_focal_points.x
                      ? `${blockItem.image_focal_points.x}%`
                      : "center";
              const focalPointY =
                  blockItem.image_focal_points && blockItem.image_focal_points.y
                      ? `${blockItem.image_focal_points.y}%`
                      : "center";
              const backgroundSize = blockItem.settings.background_size;

              return (
                  <div
                      className={`banner-image ${blockItem.settings.darken_image && "content-over-media"} w-screen h-screen`}
                  >
                    {blockItem.settings.button_url ||
                    blockItem.settings.button_text ? (
                        <div
                            className="h-full w-full absolute top-0 left-0"
                            style={{
                              backgroundImage: `url(${imageUrl})`,
                              backgroundSize: backgroundSize,
                              backgroundPositionX: focalPointX,
                              backgroundPositionY: focalPointY,
                            }}
                        ></div>
                    ) : (
                        <div
                            className="h-full w-full absolute top-0 left-0 flex items-center justify-center"
                            style={{
                              backgroundImage: `url(${imageUrl})`,
                              backgroundSize: backgroundSize,
                              backgroundPositionX: focalPointX,
                              backgroundPositionY: focalPointY,
                            }}
                        />
                    )}
                  </div>
              );
            };

            return (
                <div key={index}>
                  {blockItem.type === "block_image" && (
                      <>
                        {blockItem.settings.make_banner_clickable ? (
                            <a href={blockItem.settings.button_url}>
                              {blockItem.settings.show_overlay && (
                                  <BannerOverlay blockItem={blockItem} section_settings={section_settings} isMobile={isMobile} />
                              )}
                              <BannerImageContent />
                            </a>
                        ) : (
                            <>
                              {blockItem.settings.show_overlay && (
                                  <BannerOverlay blockItem={blockItem} section_settings={section_settings} isMobile={isMobile} />
                              )}
                              <BannerImageContent />
                            </>
                        )}
                      </>
                  )}

                  {blockItem.type === "block_video" && (
                      <>
                        {blockItem.settings.make_banner_clickable ? (
                            <a href={blockItem.settings.button_url}>
                              {blockItem.settings.show_overlay && (
                                  <BannerOverlay blockItem={blockItem} section_settings={section_settings} isMobile={isMobile} />
                              )}
                              <VideoContent />
                            </a>
                        ) : (
                            <>
                              {blockItem.settings.show_overlay && (
                                  <BannerOverlay blockItem={blockItem} section_settings={section_settings} isMobile={isMobile} />
                              )}
                              <VideoContent />
                            </>
                        )}
                      </>
                  )}

                </div>
            );
          })}
        </div>
      </div>
  );
};

export default Banner;
