import { useEffect } from "react";

export function useToggleClassOnArrayChange(array, selector, className) {
  useEffect(() => {
    const elements = document.querySelectorAll(selector);

    if (elements.length > 0) {
      elements.forEach((element) => {
        if (array.length > 0) {
          element.classList.remove(className);
        } else {
          element.classList.add(className);
        }
      });
    }
  }, [array, selector, className]);
}
