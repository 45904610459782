import { StatusIndicator } from "@arctheme-components/elements/StatusIndicator/StatusIndicator";
import { EcommFindInStore } from "@arctheme-components/ecommerce/FindInstore/FindInstore";
import { useSyncExternalStore } from "react";
import { findinstore } from "../js/alpine/components/findinstore";
import { hasher } from "../js/alpine/stores/hasher";
import { ProductTile } from "@project-stories/ecommerce/Product/ProductTile";
import ProductAccordion from "@project-stories/ecommerce/Product/ProductAccordion/ProductAccordion";
import useMobileBreakpoint from "../src/hooks/useMobileBreakpoint";
import ProductGallery from "@project-stories/ecommerce/Product/ProductGallery/ProductGallery";
import ProductMain from "@project-stories/ecommerce/Product/ProductMain";
import ProductSwatches from "@project-stories/ecommerce/Product/ProductSwatches/ProductSwatches";

export function ProductTileComponent({ ...props }) {
  return (
    <ProductTile
      product={props.settings.product}
      siblings={props.settings.related_products}
      show_add={false}
    />
  );
}

export function ProductStockStatus({ ...props }) {
  return (
    <StatusIndicator
      message={"In Stock"}
      status={"success"}
      show_indicator={props.settings.show_indicator}
    />
  );
}

export function PDPMainComponent({ ...props }) {
  console.log(props);
  return <ProductMain settings={props.settings} />;
}

export function ProductSwatchesComponent({ ...props }) {
  const swatches = props.settings.swatches;
  const currentColor = props.settings.current_colour;

  return (
    <div className={"product__swatches-component"}>
      <ProductSwatches
        swatches={swatches}
        currentColor={currentColor}
      />
    </div>
  );
}

export function ProductGalleryComponent({ ...props }) {
  const isMobile = useMobileBreakpoint("1024px");

  const { globals, section } = props.settings;
  let productImages = [];

  const videoQuality = section.video_quality;
  props.settings.additional.map((image, index) =>
    productImages.push({ url: image.src, alt: image.alt }),
  );

  // Custom code for MJ Bale to remove swatch images from rendering in gallery
  const filteredImages = productImages.filter(
    (image) => !image.url.match(/_s_|_s(\.|$)/i), // 'i' flag makes the regex case-insensitive
  );

  const videoUrl = props.settings.video_url
    ? `https://player.vimeo.com/video/${props.settings.video_url}?autoplay=1&quality=${videoQuality}p&loop=1&autopause=0&background=1`
    : null;

  return (
    <>
      {!isMobile && (
        <div className={"product-gallery"}>
          <ProductGallery
            images={filteredImages}
            galleryType={props.settings.section.gallery_type}
            videoUrl={videoUrl}
            isGiftCard={props.settings.is_gift_card}
          />
        </div>
      )}
      {isMobile && (
        <div className={"product-gallery"}>
          <ProductGallery
            images={filteredImages}
            galleryType={"carousel"}
            videoUrl={videoUrl}
            isGiftCard={props.settings.is_gift_card}
          />
        </div>
      )}
    </>
  );
}

export function FindInStoreBlock({ ...props }) {
  console.log("Find in Store Block SETUP");
  async function storeStockCheck() {
    console.log("Finding Store Stock");
    findinstore.component().sku = props.settings.product_sku;

    await hasher.store().dispatchHash();
    await findinstore
      .component()
      .getStockForProduct(props.settings.product_sku);
    await findinstore.component().getDeliveryMethodsShopify();
  }

  function useInventoryLevels() {
    return useSyncExternalStore(
      findinstore.component().subscribe,
      findinstore.component().getInventoryLevels,
    );
  }

  function useDeliveryMethods() {
    return useSyncExternalStore(
      findinstore.component().subscribe,
      findinstore.component().getDeliveryMethods,
    );
  }

  return (
    <>
      <EcommFindInStore
        title={"Find in Store"}
        description={
          "Please enter your postcode to check the delivery and collection options. Make your selection as you proceed through the checkout."
        }
        buttonText={"Search"}
        clickEvent={storeStockCheck}
        inventoryLevels={useInventoryLevels()}
        deliveryMethods={useDeliveryMethods()}
      />
    </>
  );
}

export function Accordion({ ...props }) {
  const { block_settings, section_settings, product_sku } = props.settings;

  const parseDetails = (details) => {
    // Function to strip HTML tags
    const stripHtmlTags = (html) => {
      const doc = new DOMParser().parseFromString(html, "text/html");
      return doc.body.textContent || "";
    };

    // Split by newline first
    const detailLines = details.split("<br/>");

    let paragraphCount = 0;

    // Process each line based on whether it contains a semicolon
    return detailLines.map((item, index) => {
      if (item.includes(";")) {
        // If the line contains a semicolon, strip HTML tags and split by semicolons
        let cleanDetails = stripHtmlTags(item);
        return cleanDetails.split(";").map((subItem, subIndex) => (
            <li key={`${index}-${subIndex}`}>{subItem.trim()}</li>
        ));
      } else {
        // Otherwise, just return the line as is (with trimming)
        let cleanDetails = stripHtmlTags(item);
        let style_number = product_sku.replace(/(.*)(\-\w*\d+)/, (match, p1, p2) => {
          // p1 is everything before the last match, p2 is the last match
          return p1 + ""; // You can change "Replaced" to whatever you want
        });
        if (paragraphCount === 0){
          cleanDetails = '<br />' + cleanDetails.replace('[sku]', style_number);
        } else {
          cleanDetails = cleanDetails.replace('[sku]', style_number);
        }
        paragraphCount += 1
        return cleanDetails;
      }
    });
  };

  const sampleItems = [
    {
      title: block_settings.additional_info_1,
      content: parseDetails(block_settings.additional_content_1),
      icon: block_settings.additional_icon_1,
    },
    {
      title: block_settings.additional_info_2,
      content: block_settings.additional_content_2,
      icon: block_settings.additional_icon_2,
    },
    {
      title: block_settings.additional_info_3,
      content: block_settings.additional_content_3,
      icon: block_settings.additional_icon_3,
    },
  ];

  return <ProductAccordion items={sampleItems} show_header={false} />;
}
