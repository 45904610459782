import React, { useSyncExternalStore } from "react";
import { EcommTrackOrder } from "@arctheme-components/ecommerce/TrackOrder/TrackOrder";
import trackorder from "../js/alpine/components/trackorder";
import ReviewsList from "@arctheme-components/ecommerce/ReviewsList/ReviewsList";
import hasher from "../js/alpine/stores/hasher";
import Minicart from "@project-stories/ecommerce/Minicart/Minicart";
import Collection from "@project-stories/ecommerce/Collection/Collection";
import FiltersSidebar from "@project-stories/ecommerce/Collection/FiltersSidebar";
import CollectionToolbar from "@project-stories/ecommerce/Collection/CollectionToolbar";
import SearchResults from "@project-stories/ecommerce/Search/SearchResults";
import HeaderSearch from "@project-stories/ecommerce/HeaderSearch/HeaderSearch";
import AccountForgotPassword from "@src/stories/ecommerce/Account/AccountForgotPassword";
import AccountLanding from "@src/stories/ecommerce/Account/AccountLanding";
import AccountInformationDetails from "@src/stories/ecommerce/Account/Dashboard/AccountInformationDetails";

export function MinicartComponent({ ...props }) {
  return (
    <>
      <Minicart
        cartData={props.settings.cart}
        item_count={props.settings.cart.item_count}
        settings={props.settings.section_settings}
        props={props.settings}
      />
    </>
  );
}
export function ReviewsListComponent({ ...props }) {
  return (
    <>
      <ReviewsList
        reviews={[
          {
            author: "John Certeza-Vella",
            content: "This product is freaking insane.",
            date: "19th March, 2024",
          },
        ]}
        settings={props.settings.section}
      />
    </>
  );
}
export function TrackYourOrder({ ...props }) {
  async function orderCheck(id) {
    trackorder.component().order = id;
    await hasher.store().dispatchHash();
    await trackorder.component().getOrderTrackingInfo(id);
  }

  function useGetOrder() {
    return useSyncExternalStore(
      trackorder.component().subscribe,
      trackorder.component().getOrder,
    );
  }

  return (
    <>
      <EcommTrackOrder
        title={"Track Your Order"}
        description={
          "Please enter your order number below to find tracking information about your order."
        }
        buttonText={"Search"}
        clickEvent={orderCheck}
        orderData={useGetOrder()}
      />
    </>
  );
}

// Not in Core
export function CollectionPageComponent({ ...props }) {
  return <Collection settings={props.settings} />;
}

export function SearchResultsPageComponent({ ...props }) {
  return <SearchResults settings={props.settings} />;
}

export function HeaderSearchComponent({ ...props }) {
  return <HeaderSearch settings={props.settings} />;
}

export function FiltersSidebarComponent({ ...props }) {
  return <FiltersSidebar settings={props.settings} />;
}

export function CollectionToolbarComponent({ ...props }) {
  return <CollectionToolbar settings={props.settings} />;
}
export function AccountForgotPasswordComponent({ ...props }) {
    return <AccountForgotPassword settings={props.settings} />;
}
export function AccountLandingComponent({ ...props }) {
    return (
        <AccountLanding settings={props.settings} />
    )
}
export function AccountInformationDetailsComponent({ ...props }) {
    return (
        <AccountInformationDetails settings={props.settings} />
    )
}
