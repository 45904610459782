import React from "react";
import AccountRegisterForm from "./AccountRegisterForm";
import AccountLoginForm from "./AccountLoginForm";
import useMobileBreakpoint from "@src/hooks/useMobileBreakpoint";
import {mobileBreakpoint} from "../../../../entrypoints/theme";

const AccountForm = ({ settings, isLogin, isAccountForgotPassword, showBusinessFields, handleBusinessCheckbox }) => {
    const isMobile = useMobileBreakpoint(mobileBreakpoint);

    console.log('Form Page', settings);
    console.log('isAccountForgotPassword', isAccountForgotPassword);

    return (
        <div>
            {/* Register Form */}
            {(!isLogin && !isAccountForgotPassword) && (
                <>
                    <AccountRegisterForm settings={settings} showBusinessFields={showBusinessFields} handleBusinessCheckbox={handleBusinessCheckbox} />
                </>
            )}

            {/* Login Form */}
            {(isLogin || isAccountForgotPassword) && <AccountLoginForm settings={settings} isAccountForgotPasswordInvalid={isAccountForgotPassword}/>}

        </div>
    );
};

export default AccountForm;
