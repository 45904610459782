import React from 'react';

const IconCurvedArrowLeft = ({ size = 36, onClick }) => {
    return (
        <div
            className="icon-curved-arrow-left"
            style={{ fontSize: size }}
            onClick={onClick} // Add onClick handler
        >
            <span className="path1"></span>
            <span className="path2"></span>
            <span className="path3"></span>
        </div>
    );
};

export default IconCurvedArrowLeft;
