import * as React from "react";
import { useState, useEffect } from "react";

const PromoBar = ({ settings }) => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const [nextIndex, setNextIndex] = useState(null);
    const [animating, setAnimating] = useState(false);
    const { blocks } = settings;
    const numberOfBlocks = blocks.length;
    let rotationTime = settings.section.animation_speed * 1000; // Convert to milliseconds
    const animationTime = 500; // Animation duration in milliseconds

    useEffect(() => {
        if (settings.section.autoplay) {
            const interval = setInterval(() => {
                handleNext();
            }, rotationTime);
            return () => clearInterval(interval);
        }
    }, [currentIndex, rotationTime, settings.section.autoplay]);

    const handlePrev = () => {
        if (!animating) {
            setNextIndex((currentIndex - 1 + numberOfBlocks) % numberOfBlocks);
            setAnimating(true);
            setTimeout(() => {
                setCurrentIndex((prevIndex) => (prevIndex - 1 + numberOfBlocks) % numberOfBlocks);
                setNextIndex(null);
                setAnimating(false);
            }, animationTime);
        }
    };

    const handleNext = () => {
        if (!animating) {
            setNextIndex((currentIndex + 1) % numberOfBlocks);
            setAnimating(true);
            setTimeout(() => {
                setCurrentIndex((prevIndex) => (prevIndex + 1) % numberOfBlocks);
                setNextIndex(null);
                setAnimating(false);
            }, animationTime);
        }
    };

    return (
        <div id="site-promo-bar" className="promobar">
            <div className="promobar-items-container overflow-hidden h-[40px]">
                {settings.section.show_arrows && numberOfBlocks > 1 && (
                    <span onClick={handlePrev} className="promo-button promo-button-left">
                        <i className="icon-chevron-left"></i>
                    </span>
                )}
                <div className="promo-container flex place-items-center justify-center">
                    <div className={`b3 promo-content ${animating ? 'slide-out-left' : ''}`}>
                        <a href={blocks[currentIndex].settings.promotion_url}>
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: blocks[currentIndex].settings.promo_bar_content.replace(/\n/g, '<br />'),
                                }}
                            />
                        </a>
                    </div>

                    {nextIndex !== null && (
                        <div className={`b3 promo-content ${animating ? 'slide-in-from-right' : ''}`}>
                            <a href={blocks[nextIndex].settings.promotion_url}>
                                <div
                                    dangerouslySetInnerHTML={{
                                        __html: blocks[nextIndex].settings.promo_bar_content.replace(/\n/g, '<br />'),
                                    }}
                                />
                            </a>
                        </div>
                    )}
                </div>
                {settings.section.show_arrows && numberOfBlocks > 1 && (
                    <span onClick={handleNext} className="promo-button promo-button-right">
                        <i className="icon-chevron-right"></i>
                    </span>
                )}
            </div>
        </div>
    );
};

export default PromoBar;