import * as React from "react";
import { clsx } from "clsx";
import AccountPopup from "@project-stories/client/Account/AccountPopup";
import { useEffect, useRef, useState } from "react";
import Alpine from "alpinejs";
import useAnimationEnd from "@src/hooks/useAnimationEnd";

const MobileMenu = ({ linkList, settings }) => {
  const menuLinks = linkList[0]?.links;
  const [menuState, setMenuState] = useState({
    menuAnimClass: "",
    accountAnimClass: "",
    isAccountVisible: false,
    isMenuVisible: false,
  });
  const slidingDivRef = useRef(null);
  const isCustomerLoggedIn = settings.globals.is_customer_logged_in;

  const updateMenuState = (updates) =>
    setMenuState((prevState) => ({ ...prevState, ...updates }));

  const dispatchCustomEvent = (eventName, detail = { message: "" }) => {
    window.dispatchEvent(new CustomEvent(eventName, { detail }));
  };

  useAnimationEnd(slidingDivRef, () => {
    if (
      menuState.isAccountVisible &&
      menuState.accountAnimClass == "slide-out-to-right"
    ) {
      updateMenuState({
        isAccountVisible: false,
      });

      dispatchCustomEvent("toggle-display-close", {
        message: "header-account",
      });
    }
  });

  const menuItemClicked = (dataId) => {
    updateMenuState({
      menuAnimClass: "slide-out-left",
    });

    Alpine.store("navigation").setMenuId(`header-${dataId}`);
  };

  const handleSubMenuOpen = () => {
    updateMenuState({
      menuAnimClass: "slide-out-left",
      isAccountVisible: false,
      isMenuVisible: true,
    });
  };

  const handleSubMenuClose = () => {
    console.log("Sub menu closed");
    updateMenuState({
      menuAnimClass: "slide-in-left",
      accountAnimClass: "slide-out-to-right",
      isMenuVisible: true,
      isAccountVisible: false,
    });

    menuState.isMenuVisible = true;
    menuState.menuAnimClass = "slide-in-left";
    onMenuClick();
    dispatchCustomEvent("megamenu-close");
  };

  const onMenuClick = () => {
    updateMenuState({
      menuAnimClass: "slide-in-left",
      accountAnimClass: "slide-out-left",
      isMenuVisible: true,
      isAccountVisible: false,
    });

    dispatchCustomEvent("mobile-menu-open");
  };

  const onCloseClick = () => {
    updateMenuState({
      accountAnimClass: "slide-out-to-right",
      menuAnimClass: "slide-out-left",
      isAccountVisible: false,
      isMenuVisible: false,
    });

    dispatchCustomEvent("toggle-display-close", { message: "header-account" });
    dispatchCustomEvent("mobile-menu-closed");
    dispatchCustomEvent("megamenu-close");
  };

  const handleBackClick = () => {
    updateMenuState({
      accountAnimClass: "slide-out-to-right",
      menuAnimClass: "slide-in-left",
      isMenuVisible: true,
    });
  };

  const handleAccountClick = () => {
    if (!settings.globals.is_customer_logged_in) {
      updateMenuState({
        accountAnimClass: "slide-in-from-right",
        menuAnimClass: "slide-out-left",
        isAccountVisible: true,
      });

      dispatchCustomEvent("toggle-display-open", { message: "header-account" });
    }
  };

  useEffect(() => {
    window.addEventListener("megamenu-menu-close", handleSubMenuClose);
    window.addEventListener("megamenu-menu-open", handleSubMenuOpen);
    return () => {
      window.removeEventListener("megamenu-menu-close", handleSubMenuClose);
      window.removeEventListener("megamenu-menu-open", handleSubMenuOpen);
    };
  }, []);

  useEffect(() => {}, [menuState]);

  // Filter blocks to find the one with type 'account_popup'
  const accountPopupSettings = settings.blocks.find(
    (block) => block.type === "account_popup",
  );

  return (
    <div className="mobile-menu-container">
      <div
        className={`menu-background ${clsx({
          "slide-in-left": menuState.isMenuVisible,
          "slide-out-left force-hidden": !menuState.isMenuVisible,
        })}`}
      ></div>
      <nav
        aria-label="Mobile Navigation Overlay"
        className={`navigation-overlay ${clsx({
          open: menuState.isMenuVisible,
          closed: !menuState.isMenuVisible,
        })}`}
        id="mobile-nav"
        role="navigation"
      >
        <span
          aria-label="Mobile Navigation Button"
          aria-controls="mobile-menu-list"
        >
          {!menuState.isMenuVisible ? (
            <span aria-hidden="true" onClick={onMenuClick}>
              <i className="icon-hamburger text-[22px]"></i>
            </span>
          ) : (
            <span aria-hidden="true" onClick={onCloseClick}>
              <i className="icon-close text-[22px]"></i>
            </span>
          )}
        </span>
      </nav>
      <ul
        id="mobile-menu-list"
        className={`menu-list ${menuState.menuAnimClass} ${menuState.isMenuVisible ? "" : "hidden"}`}
        role="menu"
      >
        {menuLinks.map((link, id) => (
          <li
            key={id}
            className="mobile-menu-item menu-item"
            onClick={() =>
              menuItemClicked(link.title.toLowerCase().replace(" ", "-"))
            }
          >
            <a href="#" className={`b3 font-medium`}>
              {link.title}
            </a>
            <span className="icon-arrow-right"></span>
          </li>
        ))}
        {isCustomerLoggedIn && (
          <li
            className="mobile-menu-item account-item"
            onClick={handleAccountClick}
          >
            <i className="[&>svg]:h-[28px]">
              <svg
                id="Layer_2"
                data-name="Layer 2"
                xmlns="http://www.w3.org/2000/svg"
                version="1.1"
                viewBox="320 308 440 600"
              >
                <path
                  className="cls-1"
                  d="M766.3,723.2c-4.5-17.5-9.3-35-14-52.5-19-72-67.7-114.6-138.1-117.2-50.8-1.9-101.8-1.6-152.7.1-58.3,2-108.8,41.3-127.1,96.7-7.9,24.1-14,48.8-20.8,73.3-6.9,24.5,4,38.7,29.6,38.7,65.4,0,130.9,0,196.3,0s130.9,0,196.3,0c26.9,0,37-13.1,30.4-39.2Z"
                />
                <path
                  className="cls-1"
                  d="M540.6,526.5c58.3-.3,104.4-47.1,103.9-105.4-.5-57.8-46.6-103.5-104.4-103.6-58.3,0-104.7,46.4-104.6,104.7.1,58.3,46.8,104.6,105.1,104.3Z"
                />
              </svg>
            </i>
            <a href="/account?view=personal-details" className="b1">
              Account
            </a>
          </li>
        )}
        {!isCustomerLoggedIn && (
          <li
            className="mobile-menu-item account-item"
            onClick={handleAccountClick}
          >
            <i className="icon-account text-[22px]"></i>
            <a href="/account?view=personal-details" className="b1">
              Account
            </a>
          </li>
        )}
      </ul>
      <div
        ref={slidingDivRef}
        className={`header-mobile-account ${menuState.accountAnimClass} ${menuState.isAccountVisible ? "" : "force-hidden"}`}
      >
        <div
          className="b2 font-medium mobile-menu-item back-button"
          onClick={handleBackClick}
        >
          <i className="icon-chevron-left back-button-icon"></i>
          Back
        </div>

        <AccountPopup settings={accountPopupSettings.settings} show_always={true}/>
      </div>
    </div>
  );
};

export default MobileMenu;
