import React from "react";
import { Headings } from "@project-stories/elements/Headings/Headings";
import { Paragraph } from "@arctheme-components/elements/Paragraph/Paragraph";
import { ProductTile } from "@project-stories/ecommerce/Product/ProductTile";
import ListProductsCTA from "@src/stories/sections/ListProducts/ListProductsCTA/ListProductsCTA";
import useMobileBreakpoint from "@arctheme-hooks/useMobileBreakpoint";

export default function ListProductsScrollable({ products, settings }) {
  const { section, mobile_breakpoint } = settings;
  const isMobile = useMobileBreakpoint(mobile_breakpoint);
  let ctaPosition = "top";

  if (isMobile) {
    ctaPosition = "bottom";
  }

  return (
    <div className="product-list scrollable-list">
      <div className={"section-top"}>
        <div className={"section-bar w-full flex flex-row justify-between"}>
          <div className={"section-info"}>
            <Headings
              text={section?.title}
              type={"h2"}
              classes={`${isMobile ? section.header_style_mobile : section.header_style}`}
            />
            <Paragraph text={section?.subtext} type={section?.subtext_style} />
          </div>
          {ctaPosition === "top" && (
            <ListProductsCTA section={section} isMobile={isMobile} />
          )}
        </div>
      </div>
      <div className={"w-full"} x-data="carouselFilter()">
        <div className="row-start-1 col-start-1">
          <div className="carousel-item-container w-full">
            <div
              x-data="scrollable"
              className="product-list__scrollable w-full flex overflow-x-auto"
            >
              {products.map((product, id) => (
                <div
                  key={id}
                  className="carousel-item"
                  x-init="$nextTick(() => { initialise(false, true, false, false) })"
                >
                  <ProductTile
                    product={product}
                    swatches={product?.swatches}
                    siblings={undefined}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      <div className={"section-bottom flex justify-center mt-4"}>
        {ctaPosition === "bottom" && (
          <ListProductsCTA section={section} isMobile={isMobile} />
        )}
      </div>
    </div>
  );
}
