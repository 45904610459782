import React, { useState, useEffect } from "react";
import { Headings } from "@arctheme-components/elements/Headings/Headings";
import { ProductTile } from "@project-stories/ecommerce/Product/ProductTile";
import useMobileBreakpoint from "@src/hooks/useMobileBreakpoint";
import { mobileBreakpoint } from "@src/../entrypoints/theme";
import Paragraph from "@project-stories/elements/Paragraph";
import { useToggleClassOnArrayChange } from "@src/hooks/useToggleClassOnArrayChange";

const CompleteTheLook = ({ products, settings }) => {
  const [currentProducts, setCurrentProducts] = useState(products);
  const isMobile = useMobileBreakpoint(mobileBreakpoint);

  useToggleClassOnArrayChange(products, ".complete-the-look", "hidden");

  useEffect(() => {
    // Re-render the component when the products array changes
    setCurrentProducts(products);
  }, [products]);

  let headingClass = "h3";
  if (isMobile) {
    headingClass = "h4";
  }


  return (
    <div className="container recommendedproducts lg:px-[106px]">
      <div className="recommendedproducts__header">
        <div className="flex items-center mb-4">
          <div className="mr-8 text-left">
            <Headings text={settings.title} type={headingClass} />
            <Paragraph text={settings.content} type={"b2"} />
          </div>
        </div>
      </div>
      <div className="flex-1 border-b border-gray-300 hidden lg:flex my-[14px]"></div>
      <div
        className="recommendedproducts__container flex-col w-full max-w-full"
        x-data={"carousel"}
      >
        <div
          className={
            "carousel-controls lg:hidden flex gap-x-[18.5px] justify-end text-[24px] hidden"
          }
        >
          <div className={"left"} x-on:click={"prev"}>
            <i className={"icon icon-arrow-left"}></i>
          </div>
          <div className={"right"} x-on:click={"next"}>
            <i className={"icon icon-arrow-right"}></i>
          </div>
        </div>
        <div x-ref="carousel" className="carousel w-full h-auto">
          {currentProducts.map((product) => (
            <div
              key={product.handle}
              className="carousel-item w-full"
              x-init={
                `$nextTick(() => { initialise(${settings.show_indicators},${settings.show_navbuttons},${settings.fade_edgeitems},false,false, 'completethelook', false, ${settings.use_freescroll}) })`
              }
            >
              <ProductTile
                product={product}
                swatches={product.swatches}
                siblings={undefined}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default CompleteTheLook;
