import React, { useState } from "react";
import "./ToggleSwitch.scss"; // Make sure to include the CSS file for styling

const ToggleSwitch = ({ label, initialChecked = false, onChange }) => {
    const [isChecked, setIsChecked] = useState(initialChecked);

    const handleToggle = () => {
        setIsChecked(!isChecked);
        if (onChange) {
            onChange(!isChecked);
        }
    };

    return (
        <div className="toggle-switch" onClick={handleToggle}>
            <div className={`switch ${isChecked ? "checked" : ""}`}>
                <div className="switch-handle"></div>
            </div>
            {label && <span className="blabel switch-label">{label}</span>}
        </div>
    );
};

export default ToggleSwitch;
