import { useState } from 'react';
import api from '../../js/api';
import useCustomerLogin from "@src/hooks/useCustomerLogin";

const useCreateCustomer = () => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);
    const [customerId, setCustomerId] = useState(null);

    const reset = () => {
        setLoading(true);
        setError(null);
        setSuccess(null);
        setCustomerId(null);
    };

    const createCustomer = async (formData) => {
        reset();

        // Prepare metafield array from form data
        const metafieldArray = [
            {
                namespace: "custom",
                key: "business_name",
                value: formData.business_name,
            },
            {
                namespace: "custom",
                key: "ABN",
                value: formData.abn,
            },
            {
                namespace: "custom",
                key: "role",
                value: formData.role,
            },
            {
                namespace: "custom",
                key: "Industry",
                value: formData.industry,
            },
            {
                namespace: "custom",
                key: "gift_frequency",
                value: formData.gift_frequency,
            },
            {
                namespace: "custom",
                key: "common_gift_recipient",
                value: formData.common_gift_recipient,
            },
            {
                namespace: "custom",
                key: "company_size",
                value: formData.company_size,
            },
            {
                namespace: "custom",
                key: "website",
                value: formData.website,
            },
        ].filter((item) => item.value != null);

        // Prepare the customer payload
        const customerPayload = {
            customer: {
                email: formData.email,
                tags: "hook",
                metafields: metafieldArray,
            },
        };

        if (formData.phone) {
            customerPayload.customer.phone = formData.phone;
        }

        if (formData.password && formData.password_confirmation) {
            customerPayload.customer.password = formData.password;
            customerPayload.customer.password_confirmation = formData.password_confirmation;
        }

        if (formData.name){
            const nameParts = formData.name.split(' ');

            customerPayload.customer.first_name = nameParts[0]; // First part is first name
            if (nameParts.length > 1) {
                customerPayload.customer.last_name = nameParts.slice(1).join(' '); // Everything after the first part is last name
            }
        }

        if (formData.first_name){
            customerPayload.customer.first_name = formData.first_name;
        }
        if (formData.last_name){
            customerPayload.customer.last_name = formData.last_name;
        }

        // Add SMS marketing consent if phone and opt-in is provided
        if (formData.optInSMS && formData.phone) {
            customerPayload.customer.sms_marketing_consent = {
                state: "subscribed",
                opt_in_level: "single_opt_in",
            };
        }

        // Add email marketing consent if opted-in
        if (formData.optInEmail) {
            customerPayload.customer.email_marketing_consent = {
                state: "subscribed",
                opt_in_level: "single_opt_in",
            };
        }

        console.log('Customer Payload', customerPayload);

        try {
            const response = await fetch('/admin/api/2024-07/customers.json', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'X-Shopify-Access-Token': api.getPublicAccessToken(), // Secure access token
                },
                body: JSON.stringify(customerPayload),
            });

            const jsonResponse = await response.json();

            if (!response.ok) {
                let errorMessage = '';
                // return jsonResponse.errors ? jsonResponse.errors : 'Failed to create customer';
                console.error('Failed to create customer', jsonResponse);
                // Loop through the keys in the errors object
                Object.keys(jsonResponse.errors).forEach((key) => {
                    // Check if the key is either "email" or "phone"
                    if (["phone", "email"].includes(key)) {
                        // Append the error message with key and the corresponding error message
                        errorMessage += `${key} ${jsonResponse.errors[key].join(", ")}\n`;
                    } else {
                        // Handle other types of errors
                        errorMessage += `Error: ${jsonResponse.errors[key].join(", ")}\n`;
                    }
                });
                throw new Error(jsonResponse.errors ? errorMessage : 'Failed to create customer');
            }
            console.info('Customer created Data', jsonResponse);

            // On success, return customer ID
            setCustomerId(jsonResponse.customer.id);
            console.info('Customer created', customerId);
            setSuccess(true);

            return jsonResponse.customer.id;
        } catch (error) {
            const errorMessage = error.message;
            switch(true){
                case errorMessage.includes('already been taken'):
                    setError( 'Email or phone number already taken');
                break;
                default:
                    setError(error.message || "Error occurred while creating the customer");
                break;
            }
        } finally {
            setLoading(false);
        }
    };

    return {
        createCustomer,
        loading,
        error,
        success,
        customerId,
        reset,
    };
};

export default useCreateCustomer;
