import React, { useRef } from "react";
import useMobileBreakpoint from "@arctheme-hooks/useMobileBreakpoint";
import {Paragraph} from "@mindarc/arctheme-components/frontend/src/stories/components/elements/Paragraph/Paragraph";
import {Headings} from "@mindarc/arctheme-components/frontend/src/stories/components/elements/Headings/Headings";
import Tile from "@src/stories/components/Tile/Tile";
import { mobileBreakpoint } from "@src/../entrypoints/theme";

export default function Carousel({
    settings,
    columns,
    headerText,
    subtext,
    tagline,
    columns_count,
}) {
    const { section } = settings;
    const isMobile = useMobileBreakpoint(mobileBreakpoint);

    const isFirstColumnDefaultLayout = columns[0]?.settings.layout === "default";
    let firstBlockHeight = isMobile
        ? columns[0]?.settings.mobile_block_height
        : columns[0]?.settings.block_height;

    if (!isFirstColumnDefaultLayout) {
        firstBlockHeight = 0;
    }
    const gapBetweenBlocksX = isMobile
        ? settings.section.gap_mobile
        : settings.section.gap;
    const gapBetweenBlocksY = isMobile
        ? settings.section.gap_y_mobile
        : settings.section.gap_y;

    const titleStyle = isMobile
        ? settings.section.header_style_mobile
        : settings.section.header_style;

    const taglineStyle = isMobile
        ? settings.section.tagline_style_mobile
        : settings.section.tagline_style;

    const contentStyle = isMobile
        ? settings.section.subtext_style_mobile
        : settings.section.subtext_style;

    return (
        <div className="multicolumn">
            {(tagline || headerText || subtext) && (
                <div className="multicolumn-content-top w-full lg:w-[700px] my-[16px]">
                    {tagline && <Paragraph text={tagline} type={taglineStyle}/>}
                    {headerText && <Headings text={headerText} type={titleStyle}/>}
                    {subtext && <Paragraph text={subtext} type={contentStyle}/>}
                </div>
            )}

            <div
                className={`carousel flex w-full lg:h-[${firstBlockHeight}px] 
                ${settings.direction} 
                justify-${settings.section.content_alignment_mobile} 
                lg:justify-${settings.section.content_alignment_desktop}`}
                {...({
                    "x-data": "carousel",
                    "x-init": "$nextTick(() => { initialise(true, true, false, false, 0, 'multicolumn-carousel', true, true) })",
                    "x-ref": "carousel",
                })}
            >
                {columns.map((column, index) => {
                    const columnWidth = isMobile
                        ? column.settings.width_mobile
                        : column.settings.width;
                    const defaultFlexBasis = `${100 / columns_count}%`;
                    const flexBasis =
                        columnWidth > 0
                            ? `calc(${columnWidth}% - ${gapBetweenBlocksX}px)`
                            : `calc(${defaultFlexBasis} - ${gapBetweenBlocksX}px)`;
                    const columnHeight = isMobile
                        ? column.settings.mobile_block_height
                        : column.settings.block_height;
                    const heightStyle = columnHeight > 0 ? `${columnHeight}px` : "auto";

                    return (
                        <div
                            key={column.key || index}
                            style={{
                                flexBasis,
                                marginTop: `${gapBetweenBlocksY / 2}px`,
                                marginBottom: `${gapBetweenBlocksY / 2}px`,
                                marginLeft: `${gapBetweenBlocksX / 2}px`,
                                marginRight: `${gapBetweenBlocksX / 2}px`,
                            }}
                            className="carousel-item box-border overflow-hidden"
                        >
                            <Tile
                                data={column}
                                layout={column.settings.layout}
                                height={heightStyle}
                                settings={settings}
                            />
                        </div>
                    );
                })}
            </div>
        </div>
    );
}
