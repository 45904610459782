import React, { useSyncExternalStore } from "react";
import { TagList } from "@arctheme-components/blocks/TagList/TagList";
import { PageHeader } from "@arctheme-components/sections/PageHeader/PageHeader";
import { Modal } from "@arctheme-components/blocks/Modal/Modal";
import { modal } from "../js/alpine/components/modal";
import SocialLinks from "@project-stories/components/SocialLinks/SocialLinks";
import Title from "@project-stories/elements/Title/Title";
import Description from "@project-stories/elements/Description/Description";
import Button from "@project-stories/elements/Button/Button";
import Logo from "@project-stories/elements/Logo/Logo";

export function TagListBlock({ ...props }) {
  return (
    <>
      <TagList tags={props.settings.tags} />
    </>
  );
}

export function SocialLinksComponent({ ...props }) {
  const social_links = props.settings.shop.social_links;
  const section_settings = props.settings.settings;

  const links = Object.keys(social_links).map((key) => ({
    url: social_links[key],
    name: key,
  }));

  return (
    <>
      <SocialLinks type={"default"} links={links} settings={section_settings} />
    </>
  );
}

export function PageHeaderComponent({ ...props }) {
  return (
    <PageHeader
      reverselayout={false}
      settings={props.settings.section}
      blocks={props.settings.blocks}
    />
  );
}

export function ModalComponent({ ...props }) {
  function closeModal() {
    modal.component().hide();
  }

  function getIsOpen() {
    return useSyncExternalStore(
      modal.component().subscribe,
      modal.component().getIsOpen,
    );
  }

  return (
    <Modal
      modal_id={props.settings.id}
      isOpen={getIsOpen()}
      title={props.settings.title}
      description={props.settings.content}
      position={"right"}
      onClose={closeModal}
    />
  );
}

//Not in Core
export function LogoComponent({ ...props }) {
  return <Logo settings={props.settings} />;
}

export function TitleComponent({ ...props }) {
  return <Title settings={props.settings} />;
}

export function DescriptionComponent({ ...props }) {
  return <Description settings={props.settings} />;
}

export function ButtonComponent({ ...props }) {
  return <Button settings={props.settings} />;
}
