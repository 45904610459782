import React from "react";
import { Headings } from "@project-stories/elements/Headings/Headings";
import Image from "@project-stories/elements/Image/Image";
import { Paragraph } from "@arctheme-components/elements/Paragraph/Paragraph";
import { Button } from "@arctheme-components/elements/Button/Button";

export const BlogArticleTile = ({
  article,
  show_excerpt = false,
  settings,
}) => {
  const parseTags = (tags) => {
    let parsedTags: any = [];

    if (typeof tags === "string") {
      // Split string by comma to get individual tags
      tags = tags.split(",").map((tag) => tag.trim());
    }

    if (Array.isArray(tags)) {
      tags.forEach((tag) => {
        const parts = tag.split(":").map((item) => item.trim());
        if (parts.length === 2) {
          const key = parts[0];
          const value = parts[1];
          parsedTags.push(value);
        }
      });
    }

    return parsedTags;
  };

  let tags: any = parseTags(article.tags);

  const charLimit = 90;
  const excerpt_text = article.excerpt;
  const truncateText = (text, charLimit) => {
    if (text.length <= charLimit) {
      return text;
    }
    return text.substring(0, charLimit) + "...";
  };

  let article_url = "";

  if (article.blog?.handle && !article.handle.includes(article.blog?.handle)) {
    article_url = "/blogs/" + article.blog?.handle + "/" + article.handle;
    console.log("Article URL", article_url);
  } else {
    if (settings) {
      article_url = "/blogs/" + settings.blog?.handle + "/" + article.handle;
      console.log("Article URL", article_url);
    } else {
      article_url = "/blogs/" + article.handle;
      console.log("Article URL", article_url);
    }
  }

  return (
    <div
      className={
        "blog-article-tile flex flex-col text-[#54565A] pb-2 lg:pb-3 border-b border-[#99A8B2]"
      }
    >
      <div className={"h-[311px] overflow-hidden mb-[20px] relative"}>
        <Image
          imageUrl={
            typeof article.image === "object" && article.image.src
              ? article.image.src
              : article.image
          }
          classes={
            "mb-2 lg:mb-4 object-cover w-full top-0 absolute left-0 h-full"
          }
        />
      </div>
      <div className="flex flex-col justify-between grow">
        <div>
          <Headings text={article.title} type={"h4"} classes={"mb-4"} />

          <div className="mb-8">
            <Paragraph
              text={show_excerpt && truncateText(excerpt_text, charLimit)}
              type={""}
            />
          </div>
        </div>

        <div className="flex justify-between items-center">
          <a href={`${article_url}`} className="underline">
            Read More
          </a>
          <div className={"featured_blog__details flex"}>
            {tags.map((tag, i, arr) => {
              return (
                <React.Fragment key={i}>
                  <span className="h6 italic">{tag}</span>
                  {i < arr.length - 1 && arr.length > 1 && (
                    <span>&nbsp;|&nbsp;</span>
                  )}
                </React.Fragment>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlogArticleTile;
