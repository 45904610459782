import React, { useState, useEffect } from "react";

const LoadingSpinner = ({ id }) => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const handleShowSpinner = (event) => {
      const { detail } = event;

      // console.log('Spinner', `Showing spinner ${event.detail.id}`);
      if (detail && detail.id === id) {
        setIsVisible(true);
      }
    };

    const handleHideSpinner = (event) => {
      const { detail } = event;
      // console.log('Spinner', `Hiding spinner ${event.detail.id}`);
      if (detail && detail.id === id) {
        setIsVisible(false);
      }
    };

    window.addEventListener("show-spinner", handleShowSpinner);
    window.addEventListener("hide-spinner", handleHideSpinner);

    return () => {
      window.removeEventListener("show-spinner", handleShowSpinner);
      window.removeEventListener("hide-spinner", handleHideSpinner);
    };
  }, [id]);

  return (
    <div className={`spinner-container ${isVisible ? "visible" : "hidden"}`}>
      {isVisible && (
        <div className="spinner-overlay">
          <div className="spinner"></div>
        </div>
      )}
    </div>
  );
};

export default LoadingSpinner;
