import { useEffect } from 'react';

function useAnimationEnd(ref, callback) {
    useEffect(() => {
        const element = ref.current;

        if (!element) {
            console.log("Element not found");
            return;
        }

        const handleAnimationEnd = (event) => {
            console.log("Animation ended:", event);
            callback();
        };

        element.addEventListener('animationend', handleAnimationEnd);

        return () => {
            element.removeEventListener('animationend', handleAnimationEnd);
        };
    }, [ref, callback]);
}

export default useAnimationEnd;