import { useState } from "react";
import api from '../../js/api';

const useCustomerRecover = () => {
    const [email, setEmail] = useState("");
    const [errorMessage, setErrorMessage] = useState(null);
    const [successMessage, setSuccessMessage] = useState(null);
    const [loading, setLoading] = useState(false);

    const recoverCustomer = async (email) => {
        setLoading(true);
        setErrorMessage(null);
        setSuccessMessage(null);

        const token = api.getStorefrontToken(); // Assuming your api object has a method to get the token
        const url = '/api/2024-07/graphql.json'; // Shopify Storefront GraphQL API endpoint

        const query = `
          mutation customerRecover($email: String!) {
            customerRecover(email: $email) {
              customerUserErrors {
                code
                field
                message
              }
              userErrors {
                field
                message
              }
            }
          }
        `;

        try {
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'X-Shopify-Storefront-Access-Token': token, // Token from your api
                },
                body: JSON.stringify({
                    query,
                    variables: {email},
                }),
            });

            const responseData = await response.json();
            if (responseData.errors) {
                if (responseData.errors.length > 0) {
                    let errorMessage = '';
                    for (const error of responseData.errors) {
                        errorMessage += `${error.message}\n`;
                    }

                    console.error('Failed to recover password', responseData);
                    console.log('Error Message', errorMessage);
                    setErrorMessage(errorMessage);
                    throw new Error(errorMessage || 'Failed to recover password');
                }

            }
            if (responseData.data.customerRecover?.customerUserErrors) {
                if (responseData.data.customerRecover?.customerUserErrors.length > 0) {
                    let errorMessage = '';
                    for (const error of responseData.data.customerRecover.customerUserErrors) {
                        errorMessage += `${error.message}\n`;
                    }
                    console.error('Failed to recover password', responseData);
                    console.log('Error Message', errorMessage);
                    setErrorMessage(errorMessage);
                    throw new Error(errorMessage || 'Failed to recover password');
                }
            }
            setSuccessMessage(`A request to reset your password has been sent to your email. Please follow instructions on the email to reset your password.`);
            return responseData.data;
        } catch (error) {
            console.error('Error recovering customer:', error);
            const errorMessage = error.message;
            switch(true){
                case errorMessage.includes('not find'):
                    setErrorMessage( 'Email account does not exists. Please re-enter email.');
                    break;
                case errorMessage.includes('limit exceeded'):
                    setErrorMessage( 'You have exceeded the amount of password reset attempts, please try again later.');
                    break;
            }
        } finally {
            setLoading(false);
        }
    }
    return {
        email,
        setEmail,
        loading,
        errorMessage,
        successMessage,
        recoverCustomer
    };
};

export default useCustomerRecover;
