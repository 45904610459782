import React, { useState, useEffect } from "react";
import { Button } from "../../elements/Button";
import { Headings } from "@arctheme-components/elements/Headings/Headings";
import { Paragraph } from "@arctheme-components/elements/Paragraph/Paragraph";
import { TextField } from "@project-stories/elements/TextField/TextField";
import useMobileBreakpoint from "@src/hooks/useMobileBreakpoint";
import { mobileBreakpoint } from "@src/../entrypoints/theme";
import useCreateCustomer from "@src/hooks/useCreateCustomer";

export const NewsletterSignup = ({
  settings,
  heading,
  content,
  show_button,
  button_text,
  cta_text,
  onClick = null,
}) => {
  const [disabled, setDisabled] = useState(true);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isValidEmail, setIsValidEmail] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [dependenciesState, setDependenciesState] = useState({});
  const isCustomerLoggedIn = settings.globals.is_customer_logged_in;

  const { createCustomer, loading, error, customerId, success, reset } = useCreateCustomer();
  const isMobile = useMobileBreakpoint(mobileBreakpoint);

  let image = settings.section.image;

  if (isMobile) {
    if (settings.section.image_mobile) {
      image = settings.section.image_mobile;
    }
  }

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    console.log("newsletterSubscribe", params);
    const customer_posted = params.get("customer_posted");
    if (customer_posted === "true") {
      setIsSuccess(true);
    }
  }, []);


  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email);
  };

  // Function to update the global dependencies state
  const updateDependencyState = (name, value) => {
    setDependenciesState((prevState) => ({
      ...prevState,
      [name]: value,
    }));

  };

  const handleInputChange = (e) => {
    const { name, value, type: inputType, checked } = e.target;
    const newValue = inputType === "checkbox" ? checked : value;
    updateDependencyState(name, newValue);

    if (name === "email") {
      reset();
      const isValidEmail = validateEmail(value);
      console.log('isValidEmail', isValidEmail);

      setErrorMessage(""); // Clear any existing error message

      if (!value) {
        setErrorMessage("Email field cannot be empty.");
        setDisabled(true);
      } else if (!isValidEmail) {
        setDisabled(true);
        setErrorMessage("Please enter a valid email address.");
      }

      if (isValidEmail){
        setDisabled(false);
      }

      setIsSuccess(false);

    }
  };

  const handleClick = async (event) => {
    event.preventDefault();
    // Since this is newsletter, set optIn fields automatically
    updateDependencyState('optInEmail', true);
    updateDependencyState('optInSMS', true);

    const response = await createCustomer(dependenciesState);
  };

  return (
    <div className="calltoaction h-auto lg:h-[320px] relative flex flex-col lg:flex-row">
      <div
        className={"w-full h-full absolute top-0 left-0 content-over-media"}
        style={{
          backgroundImage: `url(${image})`,
          backgroundSize: "cover",
          backgroundPositionY: "center",
        }}
      ></div>
      <div className="calltoaction__content z-10 gap-y-[12px]">
        <Headings text={heading} type={"h2"} />
        <div
          className="calltoaction__content-text b2"
          dangerouslySetInnerHTML={{ __html: content.replace(/\n/g, "<br />") }}
        />
      </div>

      <form
        id={"at-newsletter-subscribe-form"}
        action={"/contact"}
        method={"POST"}
        className="calltoaction__container z-10 w-full lg:w-[500px] md:w-[700px] py-[24px]"
      >
        <input type="hidden" name="form_type" value="customer" />
        <input type="hidden" name="contact[email]" value="" />
        <input type="hidden" name="contact[tags]" value="newsletter" />
        <input type="hidden" name="utf8" value="✓" />

        {success && (
          <div
            className="success-message my-[16px] py-[16px] bg-[#36573B] px-4"
            role="alert"
          >
            <p className="bc">Thank you for subscribing!</p>
            <p className="b3">
              You have been successfully subscribed to our newsletter.
            </p>
          </div>
        )}

        <div className="flex w-full flex-col lg:flex-row gap-y-[16px] lg:gap-x-[4px]">
          {show_button && (
              <>
                <div className={"basis-[68%]"}>
                  <TextField
                      type={"default-input"}
                      id={"email"}
                      name={'email'}
                      inputType={"email"}
                      placeholder={"Enter your email here.."}
                      value={dependenciesState['email']}
                      onChange={handleInputChange}
                      style={{
                        borderColor: isValidEmail
                            ? "initial"
                            : errorMessage && "red",
                        color: isValidEmail ? "initial" : errorMessage && "red",
                      }}
                  />

                  {error && (
                      <div className="error-message text-[#D12727]" role="alert">
                        <p className="bc">Email is already subscribed</p>
                      </div>
                  )}
                  {errorMessage && (
                      <div className="error-message text-[#D12727]" role="alert">
                        <p className="bc">{errorMessage}</p>
                      </div>
                  )}
                </div>
                <button
                    className={`button btn-outline-light`}
                    disabled={disabled}
                    onClick={handleClick}>
                  {button_text}
                </button>
              </>
          )}
        </div>

        <div className="calltoaction__cta-text">
          <Paragraph text={cta_text} type={"b2"}/>
        </div>
      </form>
    </div>
  );
};

export default NewsletterSignup;
