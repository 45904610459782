import AccountPopup from "@project-stories/client/Account/AccountPopup";
import CompleteTheLook from "@project-stories/client/CompleteTheLook/CompleteTheLook";
import shopify from "../js/shopify";
import { useEffect, useState } from "react";

export function AccountPopupComponent({ ...props }) {
  return <AccountPopup settings={props.settings.block} />;
}

export function CompleteTheLookComponent({ ...props }) {
  const [products, setProducts] = useState([]);
  const { product } = props.settings;

  useEffect(() => {
    async function fetchRelatedProducts() {
      const relatedProducts = await shopify.getRelatedProductsByTag(
        product,
        "related:",
      );
      setProducts(relatedProducts);
    }

    fetchRelatedProducts();
  }, [product]);

  return (
    <CompleteTheLook
      products={products}
      settings={props.settings.section}
      title={props.settings.section.title}
    />
  );
}
