import React, { useEffect, useState } from "react";
import ToggleSwitch from "@project-stories/elements/ToggleSwitch/ToggleSwitch";
import { getAlpineStore } from "../../../../entrypoints/theme";

export const FiltersSidebar = ({
  isVisible,
  filters,
  metaobject_filters,
  toggleSidebar,
}) => {
  const store = getAlpineStore("modelview");

  const [activeFilters, setActiveFilters] = useState([]);
  const [modelView, setModelView] = useState(store.isModelView);

  useEffect(() => {
    setModelView(store.isModelView);

    const handleActiveFilters = (event) => {
      if (event.detail.activeFilters) {
        const values = Object.values(event.detail.activeFilters).flat();
        setActiveFilters(values);
      }
    };

    window.addEventListener("active-filters", handleActiveFilters);

    // Cleanup the event listeners on component unmount
    return () => {
      window.removeEventListener("active-fiilters", handleActiveFilters);
    };
  }, []);

  const handleToggleChange = (isChecked) => {
    window.dispatchEvent(
      new CustomEvent("modelview-toggle", {
        detail: { isModelView: isChecked },
      }),
    );
  };

  const FilterList = ({ filter, metaobjectFilter }) => (
    <div
      className="pb-5 mb-5 border-b border-[#eceeef] pointer-events-auto"
      style={{ order: metaobjectFilter.sort_order }}
    >
      <div className="bc uppercase font-medium">
        {metaobjectFilter.display_name}
      </div>
      <div className="filters__list flex flex-col space-y-2 mt-4">
        {filter.values.map((value) => (
          <div className="flex items-center cursor-pointer" key={value.key}>
            <input
              type="checkbox"
              name={value.key}
              defaultValue={value.key}
              x-on:click={`filter('${filter.key}', '${value.key}');`}
              defaultChecked={activeFilters.includes(value.key)}
              className={`form-checkbox cursor-pointer ${value.key}`}
            />
            <label htmlFor={value.key} className="filters__list--label ml-2">
              {value.label}
            </label>
            <span className="ml-auto">{value.count}</span>
          </div>
        ))}
      </div>
    </div>
  );

  const FilterButton = ({ filter, metaobjectFilter }) => {
    const [visibleCount, setVisibleCount] = useState(
      metaobjectFilter.display_limit,
    );
    const [showMore, setShowMore] = useState(true);

    // Extract labels from filter.values
    const filterLabels = filter.values.map((value) =>
      value.label.toLowerCase(),
    );

    // Filter metaobjectFilter.filter_buttons to include only buttons whose button_name matches the filter labels
    const matchedButtons = metaobjectFilter.filter_buttons.filter((button) =>
      filterLabels.includes(button.button_name.toLowerCase()),
    );

    const handleShowMore = () => {
      setVisibleCount(
        (prevCount) => prevCount + metaobjectFilter.display_limit,
      );
      setShowMore(false);
    };

    const handleShowLess = () => {
      setVisibleCount(metaobjectFilter.display_limit);
      setShowMore(true);
    };

    const visibleButtons = matchedButtons.slice(0, visibleCount);
    const hasMore = visibleCount < matchedButtons.length;

    return (
      <div
        className="flex flex-col pb-5 mb-5 border-b border-[#eceeef]  pointer-events-auto"
        style={{ order: metaobjectFilter.sort_order }}
      >
        <div className="bc uppercase font-medium">
          {metaobjectFilter.display_name}
        </div>
        <div className="grid grid-cols-4 gap-x-3 gap-y-5 mt-4 items-baseline">
          {visibleButtons.map((value) => (
            <div
              className="filters__button relative cursor-pointer flex flex-col items-center justify-center text-center max-w-[60px] w-full"
              key={value.button_name}
              x-on:click={`filter('${filter.key}', '${value.button_name}');`}
            >
              <input
                type="checkbox"
                defaultValue={value.button_name}
                defaultChecked={activeFilters.includes(value.button_name)}
                className={`absolute opacity-0 cursor-pointer ${value.button_name}`}
              />
              <div className="filters__button--wrapper border border-transparent p-1">
                {value.colour ? (
                  <div
                    className="filters__button--item w-full h-full"
                    style={{ backgroundColor: value.colour }}
                  ></div>
                ) : (
                  <img
                    className="filters__button--item"
                    loading="lazy"
                    src={value.image}
                    alt={value.image.alt}
                    width="120"
                    height="auto"
                  />
                )}
              </div>
              <p className="filters__button--label b3">{value.display_name}</p>
            </div>
          ))}
        </div>
        {hasMore ? (
          <button className="filters__show-hide mt-4" onClick={handleShowMore}>
            {`Show All ${metaobjectFilter.display_name}s (${filter.values.length})`}
          </button>
        ) : !showMore ? (
          <button className="filters__show-hide mt-4" onClick={handleShowLess}>
            {`Show Less`}
          </button>
        ) : null}
      </div>
    );
  };

  const FilterColour = ({ filter }) => (
    <div className="pb-5 mb-5 border-b border-[#eceeef]  pointer-events-auto">
      <div className="bc uppercase font-medium">{filter.label}</div>
      <div className="flex flex-wrap gap-x-3 gap-y-5 mt-4">
        {filter.values.map((value) => (
          <div
            className="filters__colour relative cursor-pointer flex flex-col items-center justify-center text-center max-w-[60px] h-full w-full"
            key={value.key}
            x-on:click={`filter('${filter.key}', '${value.key}');`}
          >
            <input
              type="checkbox"
              defaultValue={value.key}
              defaultChecked={activeFilters.includes(value.key)}
              className="absolute opacity-0 cursor-pointer"
            />
            <div className="filters__colour--wrapper border border-transparent p-1 ">
              <div
                className={`filters__colour--item filters__colour--${value.label.toLowerCase()} w-full h-full`}
              ></div>
            </div>
            <p className="b3">{value.label}</p>
          </div>
        ))}
      </div>
    </div>
  );

  const FilterSize = ({ filter, metaobjectFilter }) => {
    const displayLimit = metaobjectFilter.display_limit
      ? metaobjectFilter.display_limit
      : filter.values.length;
    const [visibleCount, setVisibleCount] = useState(displayLimit);
    const [showMore, setShowMore] = useState(true);

    const handleShowMore = () => {
      setVisibleCount((prevCount) => prevCount + displayLimit);
      setShowMore(false);
    };

    const handleShowLess = () => {
      setVisibleCount(displayLimit);
      setShowMore(true);
    };

    const visibleFilters = filter.values.slice(0, visibleCount);
    const hasMore = visibleCount < filter.values.length;

    return (
      <div
        className="flex flex-col pb-5 mb-5 border-b border-[#eceeef]  pointer-events-auto"
        style={{ order: metaobjectFilter.sort_order }}
      >
        <div className="bc uppercase font-medium">
          {metaobjectFilter.display_name}
        </div>
        <div className="grid grid-cols-4 mt-4 gap-x-4 gap-y-4">
          {visibleFilters.map((value) => (
            <div
              className="filters__size relative cursor-pointer w-full"
              key={value.key}
              x-on:click={`filter('${filter.key}', '${value.key}')`}
            >
              <input
                type="checkbox"
                defaultValue={value.key}
                defaultChecked={activeFilters.includes(value.key)}
                className={`absolute opacity-0 ${value.key}`}
              />
              <button className="filters__size--button px-0 capitalize w-full h-10 flex items-center justify-center text-[#00263E] bg-[#ECEBEB] border">
                {value.label}
              </button>
            </div>
          ))}
        </div>
        {hasMore ? (
          <button className="filters__show-hide mt-4" onClick={handleShowMore}>
            {`Show All ${metaobjectFilter.display_name}s (${filter.values.length})`}
          </button>
        ) : !showMore ? (
          <button className="filters__show-hide mt-4" onClick={handleShowLess}>
            {`Show Less`}
          </button>
        ) : null}
      </div>
    );
  };

  const renderFilterValues = (filter, metaobjectFilters) => {
    const metaobjectFilter = metaobjectFilters.find(
      (value) => value.filter_name.toLowerCase() === filter.key.toLowerCase(),
    );

    if (
      filter.key === "named_tags.swatch" ||
      filter.key === "named_tags.fabrtype"
    ) {
      return metaobjectFilter ? (
        <FilterButton filter={filter} metaobjectFilter={metaobjectFilter} />
      ) : (
        <FilterColour filter={filter} />
      );
    } else if (filter.key === "options.size") {
      return metaobjectFilter ? (
        <FilterSize filter={filter} metaobjectFilter={metaobjectFilter} />
      ) : (
        <FilterSize
          filter={filter}
          metaobjectFilter={{ sort_order: 0, display_name: filter.label }}
        />
      );
    } else {
      return metaobjectFilter ? (
        <FilterList filter={filter} metaobjectFilter={metaobjectFilter} />
      ) : (
        <FilterList
          filter={filter}
          metaobjectFilter={{ sort_order: 0, display_name: filter.label }}
        />
      );
    }
  };

  return (
    <>
      <div
        className={`fixed top-0 left-0 w-full h-full bg-black z-50 opacity-20  ${isVisible ? "" : "hidden"}`}
        onClick={toggleSidebar}
      ></div>
      <div
        className={`collection-sidebar fixed top-0 left-0 w-full md:w-[350px] h-full bg-white shadow-lg transition-transform z-50 transform ${isVisible ? "translate-x-0" : "-translate-x-full"}`}
      >
        <div
          className={
            "facets relative flex flex-col pt-6 lg:pt-[40px] px-4 top-0 left-0 overflow-y-auto h-[calc(100dvh-70px)]"
          }
        >
          <button
            type="button"
            className="absolute top-5 right-5 px-0 h-auto min-h-[auto] lg:self-end w-auto pointer-events-auto"
            onClick={toggleSidebar}
          >
            <span className="sr-only">Close</span>
            <span className="icon-close-rounded text-[20px] text-[#54565A]"></span>
          </button>
          {/* Model View Toggle */}
          <div className="lg:hidden items-center mb-[18px]">
            <ToggleSwitch
              label={"Model View"}
              onChange={handleToggleChange}
              initialChecked={modelView}
            />
          </div>
          {filters.map((filter) => (
            <React.Fragment key={filter.key}>
              {renderFilterValues(filter, metaobject_filters)}
              {/* <hr className={'my-[18px]'} /> */}
            </React.Fragment>
          ))}
        </div>

        <div className="text-center p-4 border-t border-[#BBBBBD] w-full bg-white fixed bottom-0 flex gap-x-4 pointer-events-auto">
          <button className="btn-outline w-full" x-on:click={`clear`}>
            CLEAR FILTER
          </button>
          <button
            className="btn-primary w-full md:hidden"
            onClick={toggleSidebar}
          >
            See Results
          </button>
        </div>
      </div>
    </>
  );
};

export default FiltersSidebar;
