import React, { useState, useEffect } from "react";
import useCustomerRecover from '@src/hooks/useCustomerRecover';
import Paragraph from "@src/stories/elements/Paragraph";

const AccountForgotPassword = ({
    settings,
    isAccountForgotPasswordInvalid,
   showInvalidToken,
    contentWidth = 800,
    headingFontClass = '',
    onCancel,
    showTitle = false,
}) => {
    const {
        email,
        setEmail,
        loading,
        errorMessage,
        successMessage,
        recoverCustomer,
    } = useCustomerRecover();

    const showLabel = false;
    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        await recoverCustomer(email);
    };


    return (
        <div className="account-reset-password">
            <div
                className={`account-reset-password-inner shell flex flex-col mx-auto w-full lg:max-w-[${contentWidth}px]`}
            >
                {showTitle && (
                    <h2 className={`account__reset--title mx-auto mb-4 ${headingFontClass}`}>
                        Recover Password
                    </h2>
                )}

                <div className="form-container">

                    {(isAccountForgotPasswordInvalid && showInvalidToken) && <div className="error-message text-red-500 mb-4">
                            <Paragraph type="b2" text={settings.section.invalid_token_message} />
                        </div>
                    }
                    {errorMessage && <div className="error-message text-red-500 mb-4">{errorMessage}</div>}
                    {successMessage && <div className="success-message mb-4">{settings.section.password_reset_success_message}</div> }
                    <form onSubmit={handleSubmit}>
                        <div className="email mb-4 floating-input">
                            <div className="text-field-wrap">
                                {showLabel && <label htmlFor="email">Email</label>}
                                <input
                                    className="w-full p-2"
                                    type="email"
                                    name="email"
                                    placeholder="Email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    required
                                />
                            </div>
                        </div>

                        <div className="submit mb-4 flex flex-col">
                            <button
                                className="button btn-dark w-full"
                                type="submit"
                                disabled={loading}
                            >
                                {loading ? 'Sending...' : 'Submit'}
                            </button>
                        </div>
                    </form>


                    <button
                        className="blabel font-medium w-full min-h-0 h-auto p-0 capitalize hover:underline"
                        x-on:click={`showPasswordReset = false`}
                        onClick={onCancel}
                    >
                        Cancel Reset
                    </button>
                </div>
            </div>
        </div>
    );
};

export default AccountForgotPassword;
