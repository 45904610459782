import * as React from "react";
import useMobileBreakpoint from "@src/hooks/useMobileBreakpoint";
import { mobileBreakpoint } from "../../../../entrypoints/theme";
import { ProductTile } from "@project-stories/ecommerce/Product/ProductTile";
import { useEffect, useState } from "react";
import HeaderSearchResults from "@src/stories/ecommerce/HeaderSearch/HeaderSearchResults";
import { Headings } from "@project-stories/elements/Headings/Headings";
import LoadingSpinner from "@src/stories/elements/Spinner/LoadingSpinner";

export const HeaderSearch = ({ settings }) => {
  const isMobile = useMobileBreakpoint(mobileBreakpoint);

  const [query, setQuery] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedCategory, setSelectedCategory] = useState("products");
  const [products, setProducts] = useState([]);
  const [productsTotal, setProductsTotal] = useState(0);
  const [articles, setArticles] = useState([]);
  const [articlesTotal, setArticlesTotal] = useState([]);
  const [categorySuggestions, setCategorySuggestions] = useState([]);
  const [keywordSuggestions, setKeywordSuggestions] = useState([]);

  const handleCategoryClick = (category) => {
    setSelectedCategory(category);
  };

  useEffect(() => {
    const handleProductsResults = (event) => {
      console.log("Received products from search");
      if (event.detail.type === "header") {
        setQuery(event.detail.query);
        setProducts(event.detail.products);
        setProductsTotal(event.detail.totalResults);
        window.dispatchEvent(
          new CustomEvent("hide-spinner", {
            detail: {
              id: "spinner-search",
            },
          }),
        );
        if (event.detail.totalResults === 0) {
          setSelectedCategory("articles");
        }
      }
    };
    const handleArticleResults = (event) => {
      console.log("Received articles from search");
      if (event.detail.type === "header") {
        setArticles(event.detail.articles);
        setArticlesTotal(event.detail.totalResults);
      }
    };
    const handleSuggestionsResults = (event) => {
      console.log("Received suggestions from search");
      if (event.detail.type === "header") {
        setCategorySuggestions(event.detail.collections);
        setKeywordSuggestions(event.detail.suggestions);
      }
    };

    window.addEventListener(
      "searchrequest-products-complete",
      handleProductsResults,
    );
    window.addEventListener(
      "searchrequest-articles-complete",
      handleArticleResults,
    );
    window.addEventListener(
      "searchrequest-suggestions-complete",
      handleSuggestionsResults,
    );

    // Cleanup the event listeners on component unmount
    return () => {
      window.removeEventListener(
        "searchrequest-products-complete",
        handleProductsResults,
      );
      window.removeEventListener(
        "searchrequest-articles-complete",
        handleArticleResults,
      );
      window.removeEventListener(
        "searchrequest-suggestions-complete",
        handleSuggestionsResults,
      );
    };
  }, []);

  return (
    <div className="header-search-results h-full bg-[#F5F5F5]">
      <div className="lg:px-4 lg:max-w-[1472px] w-full flex flex-col lg:flex-row mx-auto pb-8 lg:py-8 h-[calc(100dvh-60px)] lg:h-full overflow-y-auto lg:overflow-auto">
        <div className="border-b border-gray-200 mb-4 lg:hidden">
          <nav className="flex space-x-4">
            <div
              className={`bc py-[14px] px-6 cursor-pointer ${selectedCategory === "products" ? "selected text-black border-b border-[#54565A]" : "text-[#99A8B2]"} ${productsTotal > 0 ? "block" : "hidden"}`}
              onClick={() => handleCategoryClick("products")}
            >
              PRODUCTS ({productsTotal})
            </div>
            <div
              className={`bc py-[14px] px-6 cursor-pointer ${selectedCategory === "articles" ? "selected text-black border-b border-[#54565A]" : "text-[#99A8B2]"} ${articlesTotal > 0 ? "block" : "hidden"}`}
              onClick={() => handleCategoryClick("articles")}
            >
              ARTICLES ({articles.length})
            </div>
          </nav>
        </div>
        <div className="flex flex-col lg:flex-row flex-auto justify-center lg:gap-5 px-4 lg:px-0">
          {/* Sidebar */}
          <div className="search-sidebar flex flex-col flex-none lg:max-w-[193px] w-full">
            {(keywordSuggestions?.length > 0 ||
              categorySuggestions?.length > 0) && (
              <>
                <span className="b3 text-[#BBBBBD] mb-4 lg:mb-10">
                  Suggestions
                </span>
                <div className="b2 pb-4">
                  {keywordSuggestions?.length > 0 && (
                    <div className="mb-4">
                      <p className="bc">KEYWORD</p>
                      <ul>
                        {keywordSuggestions
                          .slice(0, 6)
                          .map((suggestion, index) => (
                            <li className="py-1" key={index}>
                              <a href={`/search?q=${suggestion.query}`}>
                                {suggestion.query}
                              </a>
                            </li>
                          ))}
                      </ul>
                    </div>
                  )}
                  {categorySuggestions?.length > 0 && (
                    <div>
                      <p className="bc">CATEGORY</p>
                      <ul>
                        {categorySuggestions
                          .slice(0, 6)
                          .map((suggestion, index) => (
                            <li className="py-1" key={index}>
                              <a href={`/collections/${suggestion.handle}`}>
                                {suggestion.title}
                              </a>
                            </li>
                          ))}
                      </ul>
                    </div>
                  )}
                </div>
              </>
            )}
          </div>

          {/* Main Content */}
          <div className="w-full lg:w-3/4 flex flex-col overflow-hidden flex-auto lg:h-[calc(100vh-210px)]">
            <LoadingSpinner id="spinner-search" />
            <>
              {productsTotal > 0 || articlesTotal > 0 ? (
                <>
                  <div className="border-b border-gray-200 hidden lg:block flex-none">
                    <nav className="flex space-x-4">
                      <div
                        className={`bc py-[14px] px-6 cursor-pointer ${selectedCategory === "products" ? "selected text-black border-b border-[#54565A]" : "text-[#99A8B2]"} ${productsTotal > 0 ? "block" : "hidden"}`}
                        onClick={() => handleCategoryClick("products")}
                      >
                        PRODUCTS ({productsTotal})
                      </div>
                      <div
                        className={`bc py-[14px] px-6 cursor-pointer ${selectedCategory === "articles" ? "selected text-black border-b border-[#54565A]" : "text-[#99A8B2]"} ${articlesTotal > 0 ? "block" : "hidden"}`}
                        onClick={() => handleCategoryClick("articles")}
                      >
                        ARTICLES ({articles.length})
                      </div>
                    </nav>
                  </div>
                  {selectedCategory === "products" && products && (
                    <>
                      <div className="grid grid-cols-2 lg:grid-cols-4 gap-x-2 lg:gap-x-5 gap-y-4 lg:gap-y-10 lg:overflow-y-auto lg:py-4">
                        <HeaderSearchResults
                          settings={settings}
                          results={products}
                          type={"products"}
                        />
                      </div>
                      {isMobile &&
                        selectedCategory === "products" &&
                        products && (
                          <div className="text-center mt-4 mb-6">
                            <a
                              href={"/search?q="}
                              className={
                                "button btn-outline hover:no-underline"
                              }
                            >
                              SEE ALL PRODUCTS ({productsTotal})
                            </a>
                          </div>
                        )}
                    </>
                  )}
                  {selectedCategory === "articles" && articles && (
                    <div className="grid grid-cols-1 lg:grid-cols-3 gap-x-2 lg:gap-x-5 gap-y-4 lg:gap-y-10 lg:overflow-y-auto  pb-4 lg:py-4">
                      <HeaderSearchResults
                        settings={settings}
                        results={articles}
                        type={"articles"}
                      />
                    </div>
                  )}
                  {!isMobile && selectedCategory === "products" && products && (
                    <div className="flex-none text-center mt-4">
                      <a
                        href={`/search?q=${query}`}
                        className={"button btn-outline hover:no-underline"}
                      >
                        SEE ALL PRODUCTS ({productsTotal})
                      </a>
                    </div>
                  )}
                </>
              ) : (
                <div className="flex flex-col gap-8 text-[#54565A] lg:h-[calc(100vh-148px)] lg:overflow-y-auto">
                  <p className="b1">
                    {settings.no_results.title.replace("&#39;", "'")}{" "}
                    <span className="text-black">"{query}"</span>
                  </p>
                  <div>
                    <p className="b2 mb-4">{settings.no_results.prompt}</p>
                    <ul className="b3 list-disc pl-5">
                      <li>{settings.no_results.first_recommendation}</li>
                      <li>{settings.no_results.second_recommendation}</li>
                      <li>{settings.no_results.third_recommendation}</li>
                    </ul>
                  </div>
                  <div className="lg:max-w-[826px] mb-5">
                    <Headings
                      text={"You might be interested in"}
                      type={"h3"}
                      classes={"mb-8"}
                    />
                    <div className="flex flex-1 lg:flex-0 gap-x-5">
                      {settings.section_settings.product_list.map((product) => (
                        <ProductTile
                          product={product}
                          key={product.id}
                          siblings={undefined}
                        />
                      ))}
                    </div>
                  </div>
                </div>
              )}
            </>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeaderSearch;
