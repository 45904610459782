import React, {useState, useEffect, useRef} from 'react';
import MegamenuFeaturedImage from "./MegamenuFeaturedImage";
import MegamenuLinks from "./MegamenuLinks";
import useMobileBreakpoint from "@src/hooks/useMobileBreakpoint";
import { clsx } from "clsx";
import {mobileBreakpoint} from "@src/../entrypoints/theme";
import useAnimationEnd from "@src/hooks/useAnimationEnd";

const Megamenu = ({ settings, menuItems, id, state }) => {
    const [anim, setAnim] = useState(false);
    const [isAnimating, setIsAnimating] = useState(false);
    const [hoveredLink, setHoveredLink] = useState(null);
    const [featuredImageBlocks, setFeaturedImageBlocks] = useState([]);
    const showInitialWhenNoLinks = true;
    const animClass = state === id ? "slide-in-element" : "slide-out-element";

    useEffect(() => {
        setIsAnimating(true);
    }, [state, id]);

    const onAnimationEnd = () => {
        if (state !== id) {
            setAnim(false);
            setIsAnimating(false);
        } else {
            setAnim(true);
            setIsAnimating(false);
        }
    };

    const handleLinkHover = (link, setInitial) => {
        if (link?.level <= 2) {
            // if (link.links.length > 0) {
                setHoveredLink(link);
                setActiveLink(link.handle);

                updateFeaturedImageBlocks(link);
            // } else {
            //     setHoveredLink(null);
            //     setActiveLink(null);
            // }
        }

        if (showInitialWhenNoLinks) {
            if (setInitial) {
                setFeaturedImageBlocks(getInitialFeaturedImageBlocks());
            }
        }
    };


    // Function to add active class based on handle
    function setActiveLink(handle) {
        const activeLink = document.querySelector('.megamenu__link-container.active');
        if (activeLink) {
            activeLink.classList.remove('active');
        }

        // Find the div with the corresponding data-link-id
        const linkDiv = document.querySelector(`div[data-link-id='${handle}']`);

        // Check if the div exists
        if (linkDiv) {
            // Add the active class
            linkDiv.classList.add('active');
        }
    }


    const getInitialFeaturedImageBlocks = () => {
        return menuItems.blocks.reduce((initialBlocks, block) => {
            if (block.type === 'toparea') {
                for (let i = 1; i <= 4; i++) {
                    const imageKey = `image_${i}`;
                    const mobileImageKey = `mobile_image_${i}`;
                    const titleKey = `title_${i}`;
                    const urlKey = `url_${i}`;
                    const buttonTextKey = `button_text_${i}`;

                    if (block.settings[imageKey]) {
                        initialBlocks.push({
                            image: block.settings[imageKey],
                            mobile_image: block.settings[mobileImageKey],
                            title: block.settings[titleKey],
                            url: block.settings[urlKey],
                            button_text: block.settings[buttonTextKey],
                        });
                    }
                }
            }
            return initialBlocks;
        }, []);
    };

    const updateFeaturedImageBlocks = (link) => {
        const updatedBlocks = menuItems.blocks.reduce((blocks, block) => {
            if (block.settings.title === link.title) {
                for (let i = 1; i <= 2; i++) {
                    const imageKey = `image_${i}`;
                    const mobileImageKey = `mobile_image_${i}`;
                    const titleKey = `title_${i}`;
                    const urlKey = `url_${i}`;
                    const buttonTextKey = `button_text_${i}`;

                    if (block.settings[imageKey]) {
                        blocks.push({
                            image: block.settings[imageKey],
                            mobile_image: block.settings[mobileImageKey],
                            title: block.settings[titleKey],
                            url: block.settings[urlKey],
                            button_text: block.settings[buttonTextKey],
                        });
                    }
                }
            }
            return blocks;
        }, []);
        setFeaturedImageBlocks(updatedBlocks);
    };

    useEffect(() => {
        setFeaturedImageBlocks(getInitialFeaturedImageBlocks());
    }, []);

    if (state !== id && !anim) {
        return null;
    }

    const LayoutComponent = Layouts[settings.section_settings.layout];
    return (
        <LayoutComponent
            settings={settings}
            menuItems={menuItems}
            id={id}
            state={state}
            animClass={animClass}
            onAnimationEnd={onAnimationEnd}
            hoveredLink={hoveredLink}
            onLinkHover={handleLinkHover}
            featuredImageBlocks={featuredImageBlocks}
        />
    );
};

const MegamenuLayout2Column1Image = ({ settings, menuItems, id, state, onAnimationEnd, hoveredLink, onLinkHover, featuredImageBlocks }) => {
    const firstLevelLinks = menuItems.subMenuItems[0]?.links || [];
    const defaultSecondLevelLinks = firstLevelLinks[0]?.links || [];
    const secondLevelLinks = hoveredLink?.links || defaultSecondLevelLinks;

    const isMobile = useMobileBreakpoint(mobileBreakpoint);
    // const slidingDivRef = useRef(null);

    const [menuState, setMenuState] = useState({
        menuAnimClass: "",
        firstLevelActive: false,
        secondLevelActive: false,
        navigationTitle: "",
        firstMenuTitle: "",
        transitionClass: "",
        primaryLinksAnim: "",
        isSecondLevelVisible: false
    });

    const updateMenuState = updates => setMenuState(prevState => ({ ...prevState, ...updates }));

    const handleFirstLevelClick = link => {
        if (link.links.length > 0) {
            updateMenuState({
                navigationTitle: link.title,
                transitionClass: "slide-out-left",
                firstLevelActive: false,
                secondLevelActive: true,
                isSecondLevelVisible: true,
                primaryLinksAnim: 'slide-in-from-right'
            });
            window.dispatchEvent(new Event('megamenu-menu-open'));
        }
    };

    const handleBackClick = () => {
        if (menuState.secondLevelActive) {
            updateMenuState({
                transitionClass: "slide-in-left",
                secondLevelActive: false,
                navigationTitle: menuState.firstMenuTitle,
                firstLevelActive: true,
                primaryLinksAnim: 'slide-in-left'
            });

            const drawerElement = document.querySelector('.megamenu__secondary--links');
            if (drawerElement) {
                const handleAnimationEnd = () => {
                    updateMenuState({ isSecondLevelVisible: false });
                    drawerElement.removeEventListener('animationend', handleAnimationEnd);
                };
                drawerElement.addEventListener('animationend', handleAnimationEnd);
            }
        } else {
            window.dispatchEvent(new Event('megamenu-menu-close'));
            updateMenuState({ primaryLinksAnim: 'slide-out-to-right', menuAnimClass: 'slide-out-to-right', isFirstLevelActive: true });
            const drawerElement = document.querySelector('.megamenu__primary--links');
            if (drawerElement) {
                const handleAnimationEnd = () => {
                    updateMenuState({ isSecondLevelVisible: false });
                    drawerElement.removeEventListener('animationend', handleAnimationEnd);
                };
                drawerElement.addEventListener('animationend', handleAnimationEnd);
            }
        }
    };

    const handleMenuClose = () => {
        updateMenuState({primaryLinksAnim: 'slide-out-to-right', menuAnimClass: 'slide-out-left', firstLevelActive: false, secondLevelActive: false, isSecondLevelVisible: false });
    };

    useEffect(() => {
        const handleMobileMenuClosed = () => handleMenuClose();
        window.addEventListener("mobile-menu-closed", handleMobileMenuClosed);

        if (isMobile && state === id) {
            onLinkHover(null);
            const newTitle = id.replace('header-', '');
            updateMenuState({
                navigationTitle: newTitle,
                firstMenuTitle: newTitle,
                firstLevelActive: true,
                menuAnimClass: "slide-in-from-right",
                primaryLinksAnim: "slide-in-from-right",
                transitionClass: ""
            });
        }

        return () => {
            window.removeEventListener("mobile-menu-closed", handleMobileMenuClosed);
        };
    }, [isMobile, state, id]);

    useEffect(() => {
        if (!isMobile) {
            onLinkHover(menuItems.subMenuItems[0]?.links[0], true);

        }
    }, [menuState]);

    const renderLinks = (links, isSecondary = false) => (
        links.map((subItem, index) => {
            const hasSubLinks = subItem.links.length > 0;
            return (
                <React.Fragment key={index}>
                    <MegamenuLinks
                        hasSubLinks={hasSubLinks}
                        subItem={subItem}
                        onLinkHover={onLinkHover}
                        onLinkLeave={() => {}}
                        onLinkClick={isSecondary ? () => {} : () => handleFirstLevelClick(subItem)}
                    />
                    {isSecondary && subItem.links.map((subItem2, idx) => (
                        <MegamenuLinks
                            key={idx}
                            subItem={subItem2}
                            onLinkHover={() => {}}
                            onLinkLeave={() => {}}
                            onLinkClick={() => {}}
                            hasSubLinks={hasSubLinks} // Pass hasSubLinks based on the nested subItem2
                        />
                    ))}
                </React.Fragment>
            );
        })
    );

    return (
        <nav className={`megamenu-nav ${clsx({ 'container': !isMobile })}`} onAnimationEnd={onAnimationEnd}>
            <div className="relative">
                <div className="megamenu-nav-area">

                    <div className={`megamenu-nav-list ${clsx({
                        'flex-col mobile-menu-drawer h-screen': isMobile,
                        ['relative block']: isMobile && (menuState.firstLevelActive || menuState.isSecondLevelVisible),
                        [menuState.menuAnimClass]: isMobile
                    })}`}>
                        {isMobile && (menuState.firstLevelActive || menuState.isSecondLevelVisible) && (
                            <div className="mobile-menu-item b2" onClick={handleBackClick}>
                                <i className="icon-chevron-left"></i>
                                {menuState.navigationTitle}
                            </div>
                        )}
                        <div className={`megamenu__primary--links ${clsx({
                            [menuState.primaryLinksAnim]: isMobile && menuState.firstLevelActive && !menuState.secondLevelActive,
                            'slide-out-left': isMobile && !menuState.firstLevelActive && menuState.secondLevelActive,
                        })}`}>
                            {renderLinks(firstLevelLinks)}
                        </div>
                        <div className={`megamenu__secondary--links ${clsx({
                            'slide-in-from-right': isMobile && menuState.secondLevelActive && !menuState.firstLevelActive,
                            'slide-out-to-right': isMobile && !menuState.secondLevelActive && menuState.isSecondLevelVisible,
                            'force-hidden': isMobile && !menuState.secondLevelActive && !menuState.isSecondLevelVisible,
                        })}`}>

                            {renderLinks(secondLevelLinks, true)}
                            {isMobile && featuredImageBlocks.length > 0 && (
                                <div className="mobile-menu__featured--blocks">
                                    <div className="megamenu__title b2">Featured</div>
                                    <div className={`gap-[16px] ${clsx({
                                        'stacked grid grid-cols-1': settings.section_settings.image_layout == 'stacked',
                                        [`grid grid-cols-${featuredImageBlocks.length}`]: settings.section_settings.image_layout == 'grid',
                                        [`grid-cols-${settings.section_settings.col_count}`]: featuredImageBlocks.length == 1 && settings.section_settings.image_layout == 'grid',
                                    })}`}>
                                        {featuredImageBlocks.map((block, index) => (
                                            <div key={index} style={{ marginBottom: settings.section_settings.image_spacing }}>
                                                <MegamenuFeaturedImage index={index} blockItem={block} section_settings={settings.section_settings} />
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                    {!isMobile && featuredImageBlocks.length > 0 && (
                        <div className="menu__featured--blocks">
                            <div className="megamenu__title relative flex justify-end items-end b2">Featured</div>
                            <div className={`gap-[20px] ${clsx({
                                'stacked grid grid-cols-1': settings.section_settings.image_layout == 'stacked',
                                [`grid grid-cols-${featuredImageBlocks.length}`]: settings.section_settings.image_layout == 'grid',
                                [`grid-cols-${settings.section_settings.col_count}`]: featuredImageBlocks.length == 1 && settings.section_settings.image_layout == 'grid',
                            })}`}>
                                {featuredImageBlocks.map((block, index) => (
                                    <div key={index} style={{ marginBottom: settings.section_settings.image_spacing }}>
                                        <MegamenuFeaturedImage index={index} blockItem={block} section_settings={settings.section_settings} />
                                    </div>
                                ))}
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </nav>
    );
};

const Layouts = {
    '2column_images': MegamenuLayout2Column1Image
};

export default Megamenu;