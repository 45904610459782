import * as React from "react";
import useMobileBreakpoint from "@src/hooks/useMobileBreakpoint";
import AlpineWidget from "@arctheme-components/AlpineWidget";

const HeaderActions = ({ item_count, settings }) => {
  const isMobile = useMobileBreakpoint("1024px");
  const isCustomerLoggedIn = settings.globals.is_customer_logged_in;

  return (
    <div className="flex items-center px-[24px] space-x-[24px] justify-end header-toolbar z-40">
      <AlpineWidget
        template={`
                <div
                class="antialiased [&>svg]:h-[28px] md:block"
                @click="$store.global.toggleSearch()"
                :aria-expanded="$store.global.isPredictiveSearchVisible ? 'true' : 'false'">

                  <i class="icon-search text-[22px]"></i>
            </div>`}
      />

      {settings.globals.enable_wishlist === "true" && (
        <a href="/pages/wishlist" className="antialiased [&>svg]:h-[28px]">
          <i className="icon-wishlist text-[22px]"></i>
        </a>
      )}

      {isCustomerLoggedIn && !isMobile && (
        <a
          href="/account?view=personal-details"
          className="account-icon customer-loggedin antialiased [&>svg]:h-[28px] no-underline"
        >
          <svg
            id="Layer_2"
            data-name="Layer 2"
            xmlns="http://www.w3.org/2000/svg"
            version="1.1"
            viewBox="320 308 440 600"
          >
            <path
              className="cls-1"
              d="M766.3,723.2c-4.5-17.5-9.3-35-14-52.5-19-72-67.7-114.6-138.1-117.2-50.8-1.9-101.8-1.6-152.7.1-58.3,2-108.8,41.3-127.1,96.7-7.9,24.1-14,48.8-20.8,73.3-6.9,24.5,4,38.7,29.6,38.7,65.4,0,130.9,0,196.3,0s130.9,0,196.3,0c26.9,0,37-13.1,30.4-39.2Z"
            />
            <path
              className="cls-1"
              d="M540.6,526.5c58.3-.3,104.4-47.1,103.9-105.4-.5-57.8-46.6-103.5-104.4-103.6-58.3,0-104.7,46.4-104.6,104.7.1,58.3,46.8,104.6,105.1,104.3Z"
            />
          </svg>
        </a>
      )}

      {!isCustomerLoggedIn &&
        !isMobile &&
        settings.section.use_account_popup &&
        settings.section.account_popup_trigger == "hover" && (
          <AlpineWidget
            template={`
                  <div href="#" class="account-icon antialiased [&>svg]:h-[28px]" @mouseenter="$nextTick(() => { $dispatch('toggle-display-open',{ message: 'header-account' }) })" @mouseleave="$nextTick(() => { $dispatch('toggle-display-close',{ message: 'header-account' }) })">
                       <i class="icon-account text-[22px]"></i>
                </div>`}
          />
        )}

      {!isCustomerLoggedIn &&
        !isMobile &&
        settings.section.use_account_popup &&
        settings.section.account_popup_trigger == "click" && (
          <AlpineWidget
            template={`
                <div href="#" class="account-icon antialiased [&>svg]:h-[28px]" @mousedown="$nextTick(() => { $dispatch('toggle-display',{ message: 'header-account' }) })">
                       <i class="icon-account text-[22px]"></i>
                </div>`}
          />
        )}

      {!isCustomerLoggedIn &&
        !isMobile &&
        !settings.section.use_account_popup && (
          <AlpineWidget
            template={`
                <a href="/account" class="account-icon antialiased [&>svg]:h-[28px]">
                   <i class="icon-account text-[22px]"></i>
                </a>`}
          />
        )}

      <AlpineWidget
        template={`
                <div class="relative [&>svg]:h-[28px] md:block" @click="$store.global.toggleMinicart()">
                    <i class="icon-bag text-[24px]" style="line-height: 26px"></i>
                </div>`}
      />
      {!isMobile && item_count > 0 && (
        <div className="b2 header-item-count">
          (<span data-ajax-cart-bind="item_count">{item_count}</span>)
        </div>
      )}
    </div>
  );
};

export default HeaderActions;
