import React from "react";
import NostoRecommendations from "@arctheme-components/addons/Nosto/Nosto";

export function NostoComponent({ ...props }) {
  const id = props.settings.section.nosto_placement_id;
  return (
    <>
      <NostoRecommendations placement_id={id} />
    </>
  );
}
