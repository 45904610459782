import React from 'react';
import ImageComponent from "@src/stories/elements/Image/Image";

const Stacked = ({   images,
                   onImageClick,
                   galleryType,
                   videoUrl,
                   isGiftCard
               }) => {

    return (
        <div className="image-gallery-stacked">
            {images.map((image, index) => (
                <div
                    key={index}
                    className="image-item"
                    onClick={() => onImageClick(image)}
                >
                    <img src={image.url} alt={image.alt}/>
                </div>
            ))}
        </div>
    );
};

export default Stacked;
