import * as React from "react";
import { useState } from "react";

import useMobileBreakpoint from "@src/hooks/useMobileBreakpoint";
import { mobileBreakpoint } from "../../../../entrypoints/theme";
import Breadcrumbs from "../Product/Breadcrumbs";
import { ProductTile } from "@project-stories/ecommerce/Product/ProductTile";
import FiltersSidebar from "@src/stories/ecommerce/Collection/FiltersSidebar";
import CollectionToolbar from "@project-stories/ecommerce/Collection/CollectionToolbar";

export const SearchResults = ({ settings }) => {
  const [query, setQuery] = useState([]);
  const [products, setProducts] = useState([]);
  const [filters, setFilters] = useState([]);
  const isMobile = useMobileBreakpoint(mobileBreakpoint);
  const [isSidebarVisible, setSidebarVisible] = useState(false);

  const toggleSidebar = () => {
    setSidebarVisible((prevState) => {
      const newState = !prevState;
      document.body.classList.toggle("no-scroll", newState);
      return newState;
    });
  };

  const {
    columns_mobile,
    columns_desktop,
    column_gap_mobile,
    column_gap_desktop,
    row_gap_mobile,
    row_gap_desktop,
    id,
    show_breadcrumbs,
  } = settings.section;

  React.useEffect(() => {
    const handleFiltersResults = (event) => {
      if (event.detail.type === "search") {
        if (event.detail.filters) {
          setFilters(event.detail.filters);
        }
      }
    };

    const handleProductsResults = (event) => {
      if (event.detail.type === "search") {
        setQuery(event.detail.query);
        setProducts(event.detail.products);
      }
    };

    const handleEscapeKey = (event) => {
      if (event.key === "Escape") {
        setSidebarVisible(false);
      }
    };

    window.dispatchEvent(new CustomEvent("collection-search-loaded"));
    window.addEventListener("product-list", handleProductsResults);
    window.addEventListener("filter-list", handleFiltersResults);
    window.addEventListener("keydown", handleEscapeKey);

    // Cleanup the event listeners on component unmount
    return () => {
      window.removeEventListener("product-list", handleProductsResults);
      window.removeEventListener("filter-list", handleFiltersResults);
      window.removeEventListener("keydown", handleEscapeKey);
    };
  }, []);

  return (
    <>
      <FiltersSidebar
        isVisible={isSidebarVisible}
        filters={filters}
        metaobject_filters={settings.filters}
        toggleSidebar={toggleSidebar}
      />
      <div className="search-results__toolbar flex justify-between mb-4 items-center">
        {!isMobile && show_breadcrumbs && (
          <Breadcrumbs
            templateName={settings.template.name}
            shop={settings.shop}
            collection={null}
            product={null}
            routes={settings.routes}
            blog={null}
            article={null}
            page={settings.page}
            request={settings.request}
            pageTitle={query}
          />
        )}
        <CollectionToolbar toggleSidebar={toggleSidebar} />
      </div>
      <div
        id="product-grid"
        className={`search product-grid grid grid-cols-${columns_mobile} lg:grid-cols-${columns_desktop} gap-x-[${column_gap_mobile}px] lg:gap-x-[${column_gap_desktop}px] gap-y-[${row_gap_mobile}px] lg:gap-y-[${row_gap_desktop}px] auto-cols-max`}
        data-id={id}
      >
        {products.map((product) => (
          <div key={product.id} className={"search-tile"}>
            <ProductTile product={product} siblings={undefined} />
          </div>
        ))}
      </div>
    </>
  );
};

export default SearchResults;
