import React from "react";

const ListProductsCTA = ({ section, isMobile }) => {
  return (
    <div className={"product-list__cta flex gap-[8px] items-center"}>
      {section.cta_button_text && (
        <>
          <a
            href={`${section.cta_url}`}
            className={`${isMobile ? `b3` : `b2`} button-${section.cta_style} flex gap-x-[8px]`}
          >
            {section.cta_button_text}
          </a>
          <i className={"icon icon-curved-arrow-up-right"}></i>
        </>
      )}
    </div>
  );
};

export default ListProductsCTA;
