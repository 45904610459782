import React, { useState } from "react";
import { Paragraph } from "@project-stories/elements/Paragraph";
import Image from "@arctheme-components/elements/Image/Image";

export const ProductAccordion = ({ items, show_header }) => {
  const [activeIndex, setActiveIndex] = useState(null);

  const onTitleClick = (index) => {
    setActiveIndex(index === activeIndex ? null : index);
  };

  // Filter items to only include those with a title and content
  const filteredItems = items.filter((dataitem) => dataitem.title);

  return (
    <div className="accordion">
      {filteredItems.map((item, index) => (
        <div key={index} className="border-b py-4">
          <div
            className="accordion__header"
            onClick={() => onTitleClick(index)}
          >
            <span className="accordion__image-container">
              <div className="accordion__image">
                {item.icon && <Image imageUrl={item.icon} />}
              </div>
              <div className="accordion__title bc">{item.title}</div>
            </span>
            <span className="accordion__toggle">
              <i
                className={`icon ${activeIndex === index ? "icon-minus" : "icon-plus"}`}
              ></i>
            </span>
          </div>
          <div
            className={`accordion-content font-light ${activeIndex === index ? "active" : ""}`}
            style={{
              maxHeight: activeIndex === index ? "1000px" : "0",
            }}
          >
            <Paragraph text={item.content} type={"b2"} />
          </div>
        </div>
      ))}
    </div>
  );
};

export default ProductAccordion;
