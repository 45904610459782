import * as React from "react";
import { useEffect, useState } from "react";
import useMobileBreakpoint from "@src/hooks/useMobileBreakpoint";
import { mobileBreakpoint } from "../../../../entrypoints/theme";
import { ProductTile } from "@project-stories/ecommerce/Product/ProductTile";
import BlogArticleTile from "@project-stories/blog/BlogArticleTile/BlogArticleTile";

export const HeaderSearchResultsProducts = ({ settings, type, results }) => {
  const isMobile = useMobileBreakpoint(mobileBreakpoint);

  return (
    <>
      {type == "products" &&
        results &&
        results.map((product) => (
          <ProductTile
            product={product}
            key={product.id}
            siblings={undefined}
          />
        ))}
      {type == "articles" &&
        results.map((article) => (
          <BlogArticleTile
              article={article}
              key={article.id ? article.id : article.objectID}
              settings={settings}
          />
        ))}
    </>
  );
};

export default HeaderSearchResultsProducts;
