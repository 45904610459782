import React from "react";
import { Paragraph } from "@arctheme-components/elements/Paragraph/Paragraph";
import { Headings } from "@arctheme-components/elements/Headings/Headings";
import {Button} from "@mindarc/arctheme-components/frontend/src/stories/components/elements/Button/Button";

const BannerOverlay = ({ blockItem, section_settings, isMobile }) => {
    console.log(blockItem);
  return (
      <div
          className={`banner_overlay 
                              text-${blockItem.settings.content_alignment_mobile}
                              pt-[${blockItem.settings.padding_top_mobile}px] 
                              lg:pt-[${blockItem.settings.padding_top_desktop}px] 
                              pb-[${blockItem.settings.padding_bottom_mobile}px] 
                              lg:pb-[${blockItem.settings.padding_bottom_desktop}px] 
                              px-[${blockItem.settings.padding_left_right_mobile}px] 
                              lg:px-[${blockItem.settings.padding_left_right_desktop}px]
                              lg:text-${blockItem.settings.content_alignment_desktop} 
                              items-${blockItem.settings.content_position_mobile} 
                              justify-${blockItem.settings.content_position_mobile_hor}
                              lg:items-${blockItem.settings.content_position_desktop} 
                              lg:justify-${blockItem.settings.content_position_desktop_hor}`}>
        <div
            className={`banner_overlay_content w-[${section_settings.mobile_content_width}px]
                              lg:w-[${section_settings.content_width}px] gap-y-[24px]
                              ${!isMobile && `color-${blockItem.color_schema}`}
                              ${isMobile && `color-${blockItem.color_schema_mobile}`}`}
            style={{width: `${isMobile ? blockItem.settings.banner_width_mobile : blockItem.settings.banner_width_desktop}%`}}
        >
          <Paragraph
              text={blockItem.settings.tagline}
              type={"bc"}
          />
          <Headings
              text={blockItem.settings.title}
              type={blockItem.settings.title_heading_type}
          />
          <Paragraph
              text={blockItem.settings.subtext}
              type={blockItem.settings.subtext_type}
          />
          <div className={"banner_overlay_cta"}>
            {blockItem.settings.button_text && (
                <Button
                    type={blockItem.settings.button_style}
                    label={blockItem.settings.button_text}
                    link={blockItem.settings.button_url}
                    onClick={undefined}
                />
            )}
          </div>
        </div>
      </div>
  );
};

export default BannerOverlay;
