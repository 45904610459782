import * as React from "react";
import { useState, useEffect } from "react";

import useMobileBreakpoint from "@src/hooks/useMobileBreakpoint";
import {mobileBreakpoint} from "@src/../entrypoints/theme";

export const SearchResultsHeader = ({ settings }) => {
    const [resultsTotal, setResultsTotal] = useState(0);
    const [query, setQuery] = useState(0);
    const [CustomTagDesktop, setCustomTagDesktop] = useState('h1');
    const [CustomTagMobile, setCustomTagMobile] = useState('h1');
    const isMobile = useMobileBreakpoint(mobileBreakpoint);

    useEffect(() => {
        setCustomTagDesktop(settings.section_settings.heading_type_desktop as keyof JSX.IntrinsicElements);
        setCustomTagMobile(settings.section_settings.heading_type_mobile as keyof JSX.IntrinsicElements);
    }, [settings.section_settings.heading_type_desktop, settings.section_settings.heading_type_mobile]);

    useEffect(() => {
        const handleResults = (event) => {
            if (event.detail.type === 'search') {
                setResultsTotal(event.detail.totalResults);
                setQuery(event.detail.query.replaceAll('\"', ''));
            }
        };

        window.addEventListener('product-list', handleResults);

        // Cleanup the event listeners on component unmount
        return () => {
            window.removeEventListener('product-list', handleResults);
        };
    }, []);

    return (
        <div className={`flex flex-col text-center h-full justify-${settings.section_settings.content_vertical_position_mobile} lg:justify-${settings.section_settings.content_vertical_position_desktop} pt-[${settings.section_settings.padding_top_mobile}px] lg:pt-[${settings.section_settings.padding_top_desktop}px] pb-[${settings.section_settings.padding_bottom_mobile}px] lg:pb-[${settings.section_settings.padding_bottom_desktop}px] px-[${settings.section_settings.padding_left_right_mobile}px] lg:px-[${settings.section_settings.padding_left_right_desktop}px]`}>
            {!isMobile &&
                <CustomTagDesktop className="search-header__title mobile">
                    {resultsTotal} {'results for'} <span className="hc italic">{query}</span>
                </CustomTagDesktop>
            }
            {isMobile &&
                <CustomTagMobile className="search-header__title">
                    {resultsTotal} {'results for'} <span className="hc italic">{query}</span>
                </CustomTagMobile>
            }
        </div>
    )
}

export default SearchResultsHeader;