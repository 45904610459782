import * as React from "react";
import { ElementLink } from "@arctheme-components/elements/Link/Link";
import ImageComponent from "@arctheme-components/elements/Image/Image";
import Collapsible from "@project-stories/components/Collapsible/Collapsible";

const Footer = ({ globals, settings, links, type }) => {
  const renderComponents = () => {
    switch (type) {
      default:
        return (
          <div className={type}>
            <div className="footer-links grid-container">
              <div className="footer__company-details">
                <a href="/" className="footer__company-logo">
                  <ImageComponent imageUrl={settings.company_icon} />
                </a>
                <div className="footer-links-company">
                  {settings.company_name}
                  <p className="sm-text">Developed by MindArc</p>
                </div>
              </div>
              {links.map((list_links, id) => (
                <div key={id} className="footer-links-list">
                  <h3 className="b3">{list_links.links_title}</h3>
                  <nav>
                    <ul aria-label="footermenu" role="menu">
                      {list_links.links.map((link, linkId) => (
                        <li key={linkId} role="none" className="my-2">
                          <ElementLink text={link.title} link={link.url} />
                        </li>
                      ))}
                    </ul>
                  </nav>
                </div>
              ))}
            </div>
          </div>
        );
      case "footer-layout-links-only":
        return (
          <div className={type}>
            <div
              className={`footer-links lg:max-w-[${settings.links_container_width}]`}
            >
              {links.map((list_links, id) => {
                return (
                  <div
                    key={id}
                    className={`footer-links-list lg:max-w-[${settings.column_width}]`}
                  >
                    <Collapsible
                      title={list_links.links_title}
                      openIcon="icon-chevron-down"
                      desktopDisabled
                    >
                      <nav>
                        <ul aria-label="footermenu" role="menu">
                          {list_links.links.map((link, id) => {
                            return (
                              <li
                                key={id}
                                role="none"
                                className="b3 font-normal"
                              >
                                <ElementLink
                                  text={link.title}
                                  link={link.url}
                                />
                              </li>
                            );
                          })}
                        </ul>
                      </nav>
                    </Collapsible>
                  </div>
                );
              })}
            </div>
          </div>
        );
    }
  };

  return <footer>{renderComponents()}</footer>;
};

export default Footer;
