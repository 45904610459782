import React from "react";

import { Headings } from "@arctheme-components/elements/Headings/Headings";
import { Paragraph } from "@arctheme-components/elements/Paragraph/Paragraph";
import AlpineWidget from "@arctheme-components/AlpineWidget";
import { ProductTile } from "@project-stories/ecommerce/Product/ProductTile";

export default function ListProductsTabbed({ products, settings }) {
  const { section } = settings;

  return (
    <div className="product-list tabbed-list">
      <div className={"section-top"}>
        <Headings text={section?.title} type={section?.header_style} />
        <Paragraph text={section?.subtext} type={section?.subtext_style} />
      </div>

      <div className="carousel-container" x-data="carouselFilter()">
        <div className="tabbed-list__container">
          <div className={"tabbed-list__tabs"}>
            {products.map((product, i) => (
              <AlpineWidget
                key={product.id}
                template={`<a key=${i} class='carousel-tab-link button-link'
								x-on:click="changeActive(${i})" x-bind:class="{ 'selected': ${i} === active }">${product.settings.title}</a>`}
              />
            ))}
          </div>
        </div>
        {products.map((product, index) => (
          <div
            className="h-1/2 row-start-2 col-start-1"
            key={index} // Add a React key prop here for list rendering
            x-show={`active == ${index}`}
          >
            <div
              className="carousel-item-container"
              {...{
                "x-data": "carousel",
                "x-init":
                  "$nextTick(() => { initialise(false, true, false, false) })",
              }}
            >
              <div
                className="carousel col-start-1 row-start-1"
                x-ref="carousel"
              >
                {product?.collection?.products?.map((product, productIndex) => (
                  <div className="carousel-item" key={product.id}>
                    {" "}
                    {/* Correct placement of key prop */}
                    <ProductTile
                      product={product}
                      swatches={product.swatches}
                      siblings={undefined}
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
