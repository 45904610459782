import React, { useSyncExternalStore } from "react";
import { navigation } from "../js/alpine/stores/navigation";
import Footer from "@project-stories/navigation/Footer/Footer";
import Header from "@project-stories/navigation/Header/Header";
import Megamenu from "@project-stories/navigation/Megamenu/Megamenu";
import PromoBar from "@project-stories/sections/PromotionBar/PromotionBar";
import useMobileBreakpoint from "../src/hooks/useMobileBreakpoint";

export function PromobarComponent({ ...props }) {
  return (
    <>
      <PromoBar settings={props.settings} />
    </>
  );
}

export function HeaderComponent({ ...props }) {
  return (
    <>
      <Header
        settings={props.settings}
        links={props.settings.links}
        item_count={props.settings.cart.item_count}
        type={props.settings.section.layout}
        show_search_bar={props.settings.section.show_search_bar}
      />
    </>
  );
}

export function FooterComponent({ ...props }) {
  return (
    <>
      <Footer
        globals={props.settings.globals}
        settings={props.settings.section}
        links={props.settings.blocks}
        type={props.settings.section.layout}
      />
    </>
  );
}

export function MegamenuBlock({ ...props }) {
  const isMobile = useMobileBreakpoint("1024px");

  const id =
    "header-" +
    props.settings.section.attach_to.replace(" ", "-").toLowerCase();

  const menuItems = {
    id: id,
    label: props.settings.section.title,
    subMenuItems: props.settings.links,
    blocks: props.settings.blocks,
  };

  const settings = {
    section_settings: props.settings.section,
  };

  function useMenuId() {
    // return 'header-shop'
    return useSyncExternalStore(
      navigation.store().subscribe,
      navigation.store().getMenuId,
    );
  }

  return (
    <>
      {isMobile && (
        <div>
          <Megamenu
            settings={settings}
            menuItems={menuItems}
            id={id}
            state={useMenuId()}
          />
        </div>
      )}
      {!isMobile && (
        <div
          onMouseLeave={function () {
            navigation.store().setMenuId(null);
          }}
        >
          <Megamenu
            settings={settings}
            menuItems={menuItems}
            id={id}
            state={useMenuId()}
          />
        </div>
      )}
    </>
  );
}
