import * as React from "react";

export const Paragraph = ({ text, type = '' }) => {
    if (typeof text === 'string') {
        // If the text is a string, render it with <br /> for newlines and use dangerouslySetInnerHTML
        return <p className={type} dangerouslySetInnerHTML={{ __html: text.replace(/\n/g, '<br />') }} />;
    } else if (Array.isArray(text)) {
        // If the text is an array of valid React elements, render them
        return (
            <div className={type}>
                {text.map((item, index) =>
                    Array.isArray(item) ? (
                        // If it's a valid React element, render it directly
                        <React.Fragment key={index}>{item}</React.Fragment>
                    ) : (
                        // If it's not a valid React element, treat it as plain text
                        <p key={index} dangerouslySetInnerHTML={{ __html: item.replace(/\n/g, '<br />') }}></p>
                    )
                )}
            </div>
        );
    } else {
        console.log('Here 3', text);
        // Handle non-string, non-array cases or log an error
        return <p className={type}>{text}</p>;
    }
};
export default Paragraph;
