import React, { useState, useEffect } from "react";
import useCustomerLogin from "@src/hooks/useCustomerLogin";
import AccountForgotPassword from "@project-stories/ecommerce/Account/AccountForgotPassword";
import useCustomerAccessToken from "@src/hooks/useCustomerAccessToken";
import useUrlContains from "@src/hooks/useUrlContains";

const AccountLoginForm = ({ settings, isAccountForgotPasswordInvalid }) => {
    const { loginCustomer, loading, error, success } = useCustomerLogin();
    const { getAccessToken, loading: accessLoading, errorMessage: accessError, successMessage: accessSuccess } = useCustomerAccessToken();
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [showForgotPassword, setShowForgotPassword] = useState(false);
    const [showInvalidToken, setShowInvalidToken] = useState(false);

    useEffect(() => {
        if (isAccountForgotPasswordInvalid){
            setShowForgotPassword(true);
            setShowInvalidToken(true);
        }
    }, [isAccountForgotPasswordInvalid]);

    const showCreateAccountButton = false;

    const handleSubmit = async (e) => {
        e.preventDefault();
        const data: any = await getAccessToken(email, password)

        if (data.success) {
            await loginCustomer(email, password);
        }
    };

    const toggleForgotPassword = (e) => {
        e.preventDefault();
        setShowForgotPassword(!showForgotPassword);
        setShowInvalidToken(false);
    }
    return (
        <>
            {!showForgotPassword && <form data-shopify-captcha="true" id="loginForm"
                                          onSubmit={handleSubmit}
                                          className="account-page__form">

                <div className={"py-[16px]"}>
                    {(error || accessError) && <p style={{ color: 'red' }}>
                        {accessError && accessError.includes("Unidentified")
                            ? settings.section.incorrect_details_message
                            : accessError}
                    </p>}
                    {(success || accessSuccess) && <p style={{ color: 'green' }}>{settings.section.success_message}</p>}
                </div>

                <div className={`account-page__input-container  space-y-[16px]`}>

                    <div className={`account-page__input-container floating-input`}>

                        <input
                            type="email"
                            id="email"
                            name={"email"}
                            placeholder={" "}
                            className="account-page__input form-field"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                        />
                        <label htmlFor="email">Email</label>
                    </div>

                    <div className={`account-page__input-container floating-input`}>

                        <input
                            type="password"
                            id="password"
                            placeholder={" "}
                            name={"password"}
                            className="account-page__input form-field w-full p-2 tracking-[0.35rem]"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            required
                        />
                        <label htmlFor="password">Password</label>
                    </div>
                </div>

                <p className="account-page__forgot-password forgot-password blabel font-medium flex justify-end mb-4">
                    <a href="#forgotpassword" onClick={toggleForgotPassword}>
                        Forgot password?
                    </a>
                </p>

                <div className={`account-page__actions-container space-y-[16px]`}>
                    <div className={'flex flex-col'}>
                        <button type="submit" className="account-page__submit-btn button btn-dark w-full">
                            {loading ? "Signing in..." : "Sign In"}
                        </button>
                    </div>

                    {showCreateAccountButton && <div className={'flex flex-col submit'}>
                        <a href="/account/register" className="account-page__submit-btn button btn-outline w-full">
                            {"Create Account"}
                        </a>
                    </div>}

                    <div className="text-center font-light text-sm">
                        <div className="account-inquiry my-2 font-light hidden">
                            <span>Don't have an account? <a href="{{ routes.account_register_url }}">Sign Up.</a></span>
                        </div>
                        <div className="account-inquiry blabel font-medium">
                            <a href="/">Cancel and go back home</a>
                        </div>

                    </div>
                </div>
            </form>}

            {showForgotPassword && <AccountForgotPassword
                settings={settings}
                isAccountForgotPasswordInvalid={isAccountForgotPasswordInvalid}
                showInvalidToken={showInvalidToken}
                onCancel={toggleForgotPassword}/>
            }
        </>
    );
};

export default AccountLoginForm;
