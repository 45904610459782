import React from "react";
import useMobileBreakpoint from "@src/hooks/useMobileBreakpoint";
import { mobileBreakpoint } from "../../../../entrypoints/theme";
import Button from "@src/stories/elements/Button";
import ImageComponent from "@project-stories/elements/Image/Image";

export const PromotionTile = ({ promotion }) => {
    const isMobile = useMobileBreakpoint(mobileBreakpoint);
    return (
        <div
            className={`promotion-tile relative`}
            style={{color: promotion.settings?.text_colour || 'white' }}
        >
            <div className="relative pt-[150%]">
                <div className="h-full absolute top-0 left-0 right-0 flex">
                    <ImageComponent
                        imageUrl={promotion.image}
                        isLazy={true}
                        classes={'object-cover h-full w-full'}
                    />
                    <div className={`category-block__content flex flex-col h-full w-full absolute bottom-0 left-0 
                    gap-y-[${promotion.settings?.content_gap_mobile || '4'}px] 
                    lg:gap-y-[${promotion.settings?.content_gap_desktop || '8'}px] 
                    text-${promotion.settings?.content_alignment_mobile || 'center'} 
                    lg:text-${promotion.settings?.content_alignment_desktop || 'center'} 
                    justify-${promotion.settings?.content_vertical_position_mobile || 'center'} 
                    lg:justify-${promotion.settings?.content_vertical_position_desktop || 'center'} 
                    py-[${promotion.settings?.padding_top_botton_mobile}px] 
                    lg:py-[${promotion.settings?.padding_top_bottom_desktop}px] 
                    px-[${promotion.settings?.padding_left_right_mobile}px] 
                    lg:px-[${promotion.settings?.padding_left_right_desktop}px]`}>
                        <div className="category-block__content--subtitle">
                            {isMobile &&
                                <h4 className={`${promotion.settings?.subtitle_font_class_mobile}`}>{promotion.subtitle}</h4>
                            }
                            {!isMobile &&
                                <h4 className={`${promotion.settings?.subtitle_font_class_desktop}`}>{promotion.subtitle}</h4>
                            }
                        </div>
                        <div className="category-block__content--title">
                            {isMobile &&
                                <h3 className={`${promotion.settings?.title_font_class_mobile}`}>{promotion.title}</h3>
                            }
                            {!isMobile &&
                                <h3 className={`${promotion.settings?.title_font_class_desktop}`}>{promotion.title}</h3>
                            }
                        </div>
                        {isMobile &&
                            <Button
                                label={promotion.link_label}
                                type={promotion.settings?.button_style_mobile || 'btn-outline-light'}
                                icon={promotion.settings?.has_icon_mobile ? 'icon-chevron-right' : ''}
                                link={promotion.link}
                                onClick={undefined}
                            />
                        }
                        {!isMobile &&
                            <Button
                                label={promotion.link_label}
                                type={promotion.settings?.button_style_desktop || 'btn-outline-light'}
                                icon={promotion.settings?.has_icon_desktop ? 'icon-chevron-right' : ''}
                                link={promotion.link}
                                onClick={undefined}
                            />
                        }
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PromotionTile;
