import { useState } from 'react';
import api from '../../js/api';

const useCustomerUpdate = () => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(false);

    const updateCustomer = async (customerData) => {
        setLoading(true);
        setError(null);
        setSuccess(false);
        const accessToken = localStorage.getItem('accessToken');
        const CUSTOMER_UPDATE_MUTATION = `
          mutation customerUpdate($customerAccessToken: String!, $customer: CustomerUpdateInput!) {
            customerUpdate(customerAccessToken: $customerAccessToken, customer: $customer) {
              customer {
                firstName
                lastName
                email
                phone
              }
              customerUserErrors {
                code
                field
                message
              }
            }
          }
        `;

        try {
            const response = await fetch('/api/2024-07/graphql.json', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'X-Shopify-Storefront-Access-Token': api.getStorefrontToken(), // Replace with your Storefront Access Token
                },
                body: JSON.stringify({
                    query: CUSTOMER_UPDATE_MUTATION,
                    variables: {
                        customerAccessToken: accessToken,
                        customer: customerData,
                    },
                }),
            });

            const responseData = await response.json();

            if (responseData.errors) {
                throw new Error(responseData.errors[0].message);
            }

            const userErrors = responseData.data.customerUpdate.customerUserErrors;
            if (userErrors.length > 0) {
                throw new Error(userErrors[0].message);
            }

            setSuccess(true);
        } catch (err) {
            setError(err.message);
        } finally {
            setLoading(false);
        }
    };

    return {
        updateCustomer,
        loading,
        error,
        success,
    };
};

export default useCustomerUpdate;
