import React from 'react';

const ProductPrice = ({ product, templateName }) => {
    const variant = product.variants[0];
    const isSale = product.compare_at_price > product.price;
    const discountPercentage = Math.round(((product.compare_at_price - product.price) * 100) / product.compare_at_price);

    return (
        <div className="pdp-price stacked-price" data-prodify-price-container data-sale={isSale}>
            <div className="flex flex-row justify-between">
                <div className="price-container">
                    <span className="sr-only">Regular Price</span>
                    <span className={variant.price !== 0 ? 'bp' : ''}>
                        {variant.price ? `${(variant.price / 100).toFixed(2)}` : ''}
                    </span>
                    {variant.compare_at_price && variant.compare_at_price !== 0 && (
                        <span className="bpb line-through">
                            {`${(variant.compare_at_price / 100).toFixed(2)}`}
                        </span>
                    )}
                    {product.compare_at_price > 0 && templateName === 'product' && (
                        <div className="discount_percent text-xs top hidden">
                            Save {discountPercentage}%
                        </div>
                    )}
                </div>
                <div>
                    <span className="b3 text-[#CDA176]">25% off applied at checkout</span>
                </div>
            </div>
        </div>
    );
};

export default ProductPrice;
