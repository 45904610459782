import React from "react";
import Paragraph from "@project-stories/elements/Paragraph";

const ProductDescription = ({ product, useDescription = false }) => {
  const description = product.description;
  const metafieldDescription = product.metafields?.ap21?.atfStatement;

  return (
    <div className="pdp-description b3 mb-4 mt-4">
      <Paragraph
        text={
          description && useDescription ? description : metafieldDescription
        }
        type={"b3"}
      />
    </div>
  );
};

export default ProductDescription;
