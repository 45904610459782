import React, { useState, useEffect } from "react";
import BlogArticleTile from "@project-stories/blog/BlogArticleTile/BlogArticleTile";
import Paragraph from "@project-stories/elements/Paragraph";

const BlogArticlesList = ({ title, initialArticles, section, settings }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [articlesPerPage] = useState(section.articles_per_page || 6);
  const [articles, setArticles] = useState(initialArticles || []);
  const [currentArticles, setCurrentArticles] = useState(
    initialArticles.slice(0, articlesPerPage) || [],
  );

  useEffect(() => {
    const handleArticlesComplete = (event) => {
      setCurrentPage(1);
      setArticles(event.detail.articles);
    };

    const handleNextPage = (event) => {
      if (event.detail.type === "pagination") {
        setCurrentPage((prevPage) => prevPage + 1);
      }
    };

    window.addEventListener("blog-articles-complete", handleArticlesComplete);
    window.addEventListener("nextpage", handleNextPage);

    return () => {
      window.removeEventListener(
        "blog-articles-complete",
        handleArticlesComplete,
      );
      window.removeEventListener("nextpage", handleNextPage);
    };
  }, []);

  useEffect(() => {
    const indexOfLastArticle = currentPage * articlesPerPage;
    const newArticles = articles.slice(0, indexOfLastArticle);
    setCurrentArticles(newArticles);
  }, [currentPage, articles, articlesPerPage]);

  return (
    <>
      <div className={`w-full my-[50px] color-${section.color_schema}`}>
        <h2
          className={`title--primary ${section.animations_reveal_on_scroll ? " scroll-trigger animate--fade-in" : ""}`}
        >
          {title}
        </h2>

        <div
          className="blog-articles flex flex-col py-4"
          x-data="pagination"
          x-init={`init(${articles.length}, ${articlesPerPage}, true, 'articles')`}
        >
          {currentArticles.length > 0 && (
            <>
              <div
                className={`article-items ${section.layout === "grid" ? `grid grid-cols-1 lg:grid-cols-${section.columns_count}` : ""} gap-x-[20px]`}
              >
                {currentArticles.map((article, index) => (
                  <div
                    className="my-4 blog-articles__article article"
                    key={index}
                  >
                    <BlogArticleTile
                      article={article}
                      settings={settings}
                    />
                  </div>
                ))}
              </div>
              <div
                x-ref="paginationContainer"
                className="flex justify-center items-center space-x-4 pt-[64px] lg:pt-[87px]"
              ></div>
            </>
          )}
          {currentArticles.length <= 0 && (
            <div className={"blog-articles-empty"}>
              <Paragraph
                text={"There are no articles found with that filter."}
                type={"b2"}
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default BlogArticlesList;
