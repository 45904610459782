import { useMemo } from 'react';

const useVideoSource = (videoSources, previewImage, isMobile, videoQuality) => {
    return useMemo(() => {
        let highestResolution = 0;
        let highestSource = '';
        let fallbackSource = '';
        let videoPoster = previewImage.src;
        let exactMatchFound = false;

        videoSources.forEach((source) => {
            // Check for mp4 format and handle exact match or highest resolution as a fallback
            if (source.format === 'mp4') {
                // Exact match for the desired quality
                if (source.height === parseInt(videoQuality)) {
                    highestResolution = source.height;
                    highestSource = source.url;
                    videoPoster = previewImage.src;
                    exactMatchFound = true;
                }

                // Save the highest available resolution for fallback if no exact match is found
                if (!exactMatchFound && source.height > highestResolution) {
                    highestResolution = source.height;
                    fallbackSource = source.url;
                }
            }
        });

        // If no exact match was found, fall back to the highest available resolution
        if (!exactMatchFound) {
            highestSource = fallbackSource;
        }

        const videoUrl = highestSource;

        return { videoUrl, videoPoster };
    }, [isMobile, videoQuality, previewImage, videoSources]);
};

export default useVideoSource;
