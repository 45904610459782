import * as Navigation from "../../components/Navigation";
import * as Sections from "../../components/Sections";
import * as Product from "../../components/Product";
import * as Ecommerce from "../../components/Ecommerce";
import * as List from "../../components/List";
import * as Blocks from "../../components/Blocks";
import * as Blogs from "../../components/Blogs";
import * as Pages from "../../components/Pages";
import * as Styleguide from "../../components/Styleguide";
import * as Client from "../../components/Client";
import * as Addons from "../../components/Addons";
import * as Elements from "../../components/Elements";

export function getComponents() {
  return [
    // NAVIGATION
    // Add components related to the site's navigation. These include headers, footers, menus, and any promotional bars.
    { name: "theme__textwithmedia", element: Sections.SectionTextWithImage },
    { name: "theme__testimonials", element: Sections.SectionTestimonials },
    { name: "theme__banner", element: Sections.SectionBanner },
    {
      name: "theme__elements-product-status",
      element: Product.ProductStockStatus,
    },
    {
      name: "theme__newsletter-subscribe",
      element: Sections.SectionNewsletterSubscribe,
    },
    {
      name: "theme__recommendedproducts",
      element: List.RecommendedProductList,
    },
    {
      name: "theme__ecommerce-product-gallery",
      element: Product.ProductGalleryComponent,
    },
    { name: "theme__elements-accordion", element: Product.Accordion },
    { name: "theme__multicolumn", element: Sections.MulticolumnBlockSection },
    { name: "theme__collectionfilters", element: Sections.CollectionFilters },
    { name: "theme__navigation-megamenu", element: Navigation.MegamenuBlock },
    {
      name: "theme__ecommerce-find-in-store",
      element: Product.FindInStoreBlock,
    },
    {
      name: "theme__ecommerce-track-your-order",
      element: Ecommerce.TrackYourOrder,
    },
    { name: "theme__nosto", element: Addons.NostoComponent },
    { name: "theme__videocontainer", element: Sections.VideoContainerSection },
    { name: "theme__storelocator", element: Pages.StoreLocatorPage },
    { name: "theme__styleguide", element: Styleguide.StyleguidePage },
    { name: "theme__navigation-header", element: Navigation.HeaderComponent },
    { name: "theme__ecommerce-minicart", element: Ecommerce.MinicartComponent },
    {
      name: "theme__navigation-promobar",
      element: Navigation.PromobarComponent,
    },
    { name: "theme__navigation-footer", element: Navigation.FooterComponent },
    {
      name: "theme__section-featured-companies",
      element: Sections.SectionFeaturedCompanies,
    },
    { name: "theme__section-marquee", element: Sections.MarqueeSection },
    { name: "theme__block-tags", element: Blocks.TagListBlock },
    { name: "theme__list-products", element: List.ProductsListComponent },
    {
      name: "theme__ecommerce-reviews-list",
      element: Ecommerce.ReviewsListComponent,
    },
    {
      name: "theme__list-recently-viewed-products",
      element: List.RecentlyViewedProductsList,
    },
    {
      name: "theme__product-swatches",
      element: Product.ProductSwatchesComponent,
    },
    {
      name: "theme__blog-featured-products",
      element: Product.FeaturedProductsComponent,
    },
    {
      name: "theme__blog-articles-list",
      element: Blogs.BlogArticlesListComponent,
    },

    // Below add new client components that are made for this project (name should be theme__<client>-<area>-<component>)
    {
      name: "theme__mjbale-navigation-footer",
      element: Navigation.FooterComponent,
    },
    {
      name: "theme__mjbale-social-links",
      element: Blocks.SocialLinksComponent,
    },
    { name: "theme__block-logo", element: Blocks.LogoComponent },
    { name: "theme__block-title", element: Blocks.TitleComponent },
    {
      name: "theme__block-description",
      element: Blocks.DescriptionComponent,
    },
    { name: "theme__block-button", element: Blocks.ButtonComponent },
    { name: "theme__product-tile", element: Product.ProductTileComponent },
    {
      name: "theme__mjbale-category-banner",
      element: Sections.SectionCategoryBanner,
    },
    {
      name: "theme__mjbale-collection-header",
      element: Sections.SectionCollectionHeader,
    },
    {
      name: "theme__mjbale-search-results-header",
      element: Sections.SectionSearchResultsHeader,
    },
    {
      name: "theme__header-account-popup",
      element: Client.AccountPopupComponent,
    },
    {
      name: "theme__mjbale-completethelook",
      element: Client.CompleteTheLookComponent,
    },
    {
      name: "theme__mjbale-search-header-results",
      element: Ecommerce.HeaderSearchComponent,
    },
    {
      name: "theme__mjbale-collection-main",
      element: Ecommerce.CollectionPageComponent,
    },
    {
      name: "theme__mjbale-search-main",
      element: Ecommerce.SearchResultsPageComponent,
    },
    {
      name: "theme__mjbale-collection-sidebar",
      element: Ecommerce.FiltersSidebarComponent,
    },
    {
      name: "theme__mjbale-collection-toolbar",
      element: Ecommerce.CollectionToolbarComponent,
    },
    {
      name: "theme__account-forgotpassword",
      element: Ecommerce.AccountForgotPasswordComponent,
    },
    {
      name: "theme__account-landing",
      element: Ecommerce.AccountLandingComponent,
    },
    {
      name: "theme__account-information-details",
      element: Ecommerce.AccountInformationDetailsComponent,
    },
    { name: "theme__pdp-main", element: Product.PDPMainComponent },
    { name: "theme__blog-tile", element: Blogs.BlogArticleTileComponent },
  ];
}
