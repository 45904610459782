import React, {useState} from 'react';
import ImageComponent from "@src/stories/elements/Image/Image";

const Thumbnail = ({   images,
                   onImageClick,
                   galleryType,
                   videoUrl,
                   isGiftCard
               }) => {
    const [selectedImage, setSelectedImage] = useState(images[0]);
    const [currentIndex, setCurrentIndex] = useState(0);


    return (
        <div className="product-image-gallery">
            <div className="thumbnail-list">
                {images.map((image, index) => (
                    <div
                        key={index}
                        className={`thumbnail-item ${selectedImage === image ? "selected" : ""}`}
                        onClick={() => setSelectedImage(image)}
                    >
                        <img src={image.url} alt={image.alt}/>
                    </div>
                ))}
            </div>
            <div className="main-image">
                <img src={selectedImage.url} alt={selectedImage.alt}/>
            </div>
        </div>
    );
};

export default Thumbnail;
