import * as React from "react";
import useMobileBreakpoint from "@src/hooks/useMobileBreakpoint";
import { mobileBreakpoint } from "../../../../entrypoints/theme";
import Stacked from "@src/stories/components/Tile/Layouts/Stacked";
import Overlayed from "@src/stories/components/Tile/Layouts/Overlayed";
import "./Tile.scss";

const Layouts = {
  default: Overlayed,
  stacked: Stacked,
};

export const Tile = ({
  data,
  layout = "default",
  height,
  settings,
}) => {
  const LayoutComponent = Layouts[layout];
  const isMobile = useMobileBreakpoint(mobileBreakpoint);

  return <LayoutComponent data={data} height={height} settings={settings} isMobile={isMobile} />;
};

export default Tile;
