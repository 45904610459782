import React, { useState, useEffect } from "react";

const HeaderMenu = ({ link_list }) => {
    const [hoveredLink, setHoveredLink] = useState(null);
    const menuLinks = link_list[0].links;

    useEffect(() => {
        window.addEventListener("megamenu-closed", handleMenuClose);

        if (hoveredLink !== null) {
            const event = new CustomEvent('megamenu-hover', {
                detail: { message: `header-${hoveredLink}` }
            });
            window.dispatchEvent(event);
        }

        return () => {
            setHoveredLink(null);
        };
    }, []);

    const handleMenuClose = () => {
        setHoveredLink(null);
    };

    return (
        <div className="header-links">
            <div className="block">
                <ul className="header-menu inline-flex">
                    {menuLinks.map((link, id) => {
                        const titleLowerCase = link.title.toLowerCase();
                        const dataId = titleLowerCase.replace(' ', '-');
                        const activeClass = link.active ? 'active' : 'inactive';
                        const hoverClass = hoveredLink === dataId ? 'hovered' : '';

                        return (
                            <li className="b2" data-id={`header-${dataId}`} key={id}>
                                <a
                                    href={link.url}
                                    className={`${activeClass} ${hoverClass} menu-link`}
                                    onMouseEnter={() => setHoveredLink(dataId)}
                                >
                                    {link.title}
                                </a>
                            </li>
                        );
                    })}
                </ul>
            </div>
        </div>
    );
};

export default HeaderMenu;