import * as React from "react";

import { Button } from "@project-stories/elements/Button";
import useMobileBreakpoint from "@src/hooks/useMobileBreakpoint";
import { mobileBreakpoint } from "../../../../entrypoints/theme";

export const ButtonBlock = ({ settings }) => {
  const isMobile = useMobileBreakpoint(mobileBreakpoint);
  const renderComponents = () => {
    return (
      <div
        className={`block-button flex items-center justify-${settings.section_settings.content_alignment_mobile} lg:justify-${settings.section_settings.content_alignment_desktop} mb-[${settings.block_settings.margin_bottom_mobile}px] lg:mb-[${settings.block_settings.margin_bottom_desktop}px]`}
      >
        <Button
          label={settings.block_settings.button_text}
          type={settings.block_settings.button_style}
          link={settings.block_settings.button_url}
          onClick={undefined}
        />
      </div>
    );
  };

  if (settings.block_settings.render_type !== "all") {
    if (isMobile && settings.block_settings.render_type !== "mobile-only")
      return;
    if (!isMobile && settings.block_settings.render_type !== "desktop-only")
      return;
  }

  return <>{renderComponents()}</>;
};

export default ButtonBlock;
