import React from "react";
import { Headings } from "@project-stories/elements/Headings/Headings";
import { Paragraph } from "@arctheme-components/elements/Paragraph/Paragraph";
import { ProductTile } from "@project-stories/ecommerce/Product/ProductTile";
import ListProductsCTA from "@src/stories/sections/ListProducts/ListProductsCTA/ListProductsCTA";
import useMobileBreakpoint from "@arctheme-hooks/useMobileBreakpoint";

export default function ListProductsGrid({ products, settings }) {
  const { section, mobile_breakpoint } = settings;
  const isMobile = useMobileBreakpoint(mobile_breakpoint);
  let ctaPosition = "top";

  if (isMobile) {
    ctaPosition = "bottom";
  }

  if (products.length <= 0) {
    return false;
  }

  return (
    <div className="product-list">
      <div className={"section-top"}>
        <div className={"section-bar w-full flex flex-row justify-between"}>
          <div className={"section-info"}>
            <Headings text={section?.title} type={section?.header_style} />
            <Paragraph text={section?.subtext} type={section?.subtext_style} />
          </div>
          {ctaPosition === "top" && (
            <ListProductsCTA section={section} isMobile={isMobile} />
          )}
        </div>
      </div>

      <div className={"w-full"}>
        <div className="row-start-1 col-start-1">
          <div className="carousel-item-container w-full">
            <div className="product-list__grid grid grid-cols-2 lg:grid-cols-4 gap-[8px] lg:gap-[24px] w-full">
              {products.map((product, id) => (
                <div key={id} className="grid-item">
                  <ProductTile
                    product={product}
                    swatches={product?.swatches}
                    siblings={undefined}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      <div className={"section-bottom flex justify-center mt-4"}>
        {ctaPosition === "bottom" && (
          <ListProductsCTA section={section} isMobile={isMobile} />
        )}
      </div>
    </div>
  );
}
