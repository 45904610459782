import * as React from "react";

import { Headings } from "@arctheme-components/elements/Headings/Headings";
import { Paragraph } from "@arctheme-components/elements/Paragraph/Paragraph";
import useMobileBreakpoint from "@src/hooks/useMobileBreakpoint";
import { mobileBreakpoint } from "../../../../entrypoints/theme";

export const Title = ({ settings }) => {
  const isMobile = useMobileBreakpoint(mobileBreakpoint);

  const renderComponents = () => {
    return (
      <div
        className={`block-title mt-[${settings.block_settings.margin_top_mobile}px] lg:mt-[${settings.block_settings.margin_top_desktop}px] mb-[${settings.block_settings.margin_bottom_mobile}px] lg:mb-[${settings.block_settings.margin_bottom_desktop}px] text-${settings.section_settings.content_alignment_mobile} lg:text-${settings.section_settings.content_alignment_desktop}`}
      >
        {isMobile ? (
          <Paragraph
            text={settings.block_settings.title}
            type={`${settings.block_settings.mobile_class}`}
          />
        ) : (
          <Headings
            text={settings.block_settings.title}
            type={settings.block_settings.heading_type}
          />
        )}
      </div>
    );
  };

  if (settings.block_settings.render_type !== "all") {
    if (isMobile && settings.block_settings.render_type !== "mobile-only")
      return;
    if (!isMobile && settings.block_settings.render_type !== "desktop-only")
      return;
  }

  return <>{renderComponents()}</>;
};

export default Title;
