import React, { useEffect, useState } from "react";
import ToggleSwitch from "@project-stories/elements/ToggleSwitch/ToggleSwitch";
import { getAlpineStore } from "../../../../entrypoints/theme";
import cloneDeep from "lodash/cloneDeep";

export const CollectionToolbar = ({ toggleSidebar }) => {
  const store = getAlpineStore("modelview");
  const options = cloneDeep(window.sortOptions);
  const defaultSort = cloneDeep(window.defaultSort);
  const extractedSortBy = cloneDeep(window.extractedSortBy);

  const [selectedOption, setSelectedOption] = useState(
    extractedSortBy ? extractedSortBy : defaultSort,
  );
  const [modelView, setModelView] = useState(store.isModelView);

  useEffect(() => {
    setModelView(store.isModelView);
  }, []);

  const handleSortParams = (event) => {
    console.log("event - sort", event);
    const newValue = event.detail.index;
    setSelectedOption(newValue);
  };

  const handleSelectChange = (event) => {
    const newValue = event.target.value;
    setSelectedOption(newValue);

    window.dispatchEvent(
      new CustomEvent("sort-products", {
        detail: {
          sortOption: newValue,
        },
      }),
    );
  };

  const handleToggleChange = (isChecked) => {
    window.dispatchEvent(
      new CustomEvent("modelview-toggle", {
        detail: { isModelView: isChecked },
      }),
    );
  };

  return (
    <div className="flex items-center space-x-4 border-b border-t lg:border-0 justify-end w-full">
      <div
        className={
          "flex toolbar-items pl-4 pr-1 lg:py-2 py-3 justify-between lg:justify-normal lg:gap-10 border-0 lg:border w-full lg:w-auto"
        }
      >
        {/* Filters Button */}
        <button
          className="flex p-0 w-auto h-auto min-h-[auto] items-center bbutton uppercase font-medium focus:outline-none mr-4"
          onClick={toggleSidebar}
        >
          <i className={"icon icon-filter-simple"}></i>
          FILTERS
        </button>

        {/* Sort By Dropdown */}
        <div className="relative flex items-center justify-center">
          <span className="bbutton uppercase font-medium whitespace-nowrap">
            SORT BY:
          </span>
          <select
            value={selectedOption}
            onChange={handleSelectChange}
            className="pl-2 pb-0 border-0 b2 text-[#636b6f]"
          >
            {options.map((option) => (
              <option key={option.key} value={option.key}>
                {option.value}
              </option>
            ))}
          </select>
        </div>

        {/* Model View Toggle */}
        <div className="hidden lg:flex items-center">
          <ToggleSwitch
            label={"Model View"}
            onChange={handleToggleChange}
            initialChecked={modelView}
          />
        </div>
      </div>
    </div>
  );
};

export default CollectionToolbar;
