import * as React from "react";

export const ProductSwatches = ({ swatches, currentColor }) => {

  return (
    <div className={"product__swatches-container flex gap-x-[8px]"}>
      {currentColor && (
        <span className={"b3 text-[#54565A]"}>{currentColor}</span>
      )}
      <div className={"product__swatches"}>
        {swatches.map((swatch, id) => (
          <a
            key={id}
            href={`${swatch.url}`}
            className={`${swatch.is_product && "border-b-2 border-[#54565A]"}`}
          >
            <div className={"product__swatch"}>
              {swatch.color && (
                <div className={"border"}>
                  <div
                    className={"product__swatch_color"}
                    style={{ backgroundColor: swatch.color }}
                  ></div>
                </div>
              )}
              {swatch.image && (
                <div className={"border"}>
                  <img className={"product__swatch_image"} src={swatch.image} />
                </div>
              )}
              {swatch.show_name && swatch.name && swatch.name}
            </div>
          </a>
        ))}
      </div>
    </div>
  );
};

export default ProductSwatches;
