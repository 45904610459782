import { ListProducts } from "@project-stories/sections/ListProducts/ListProducts";
import recentlyviewed from "../js/alpine/components/recentlyviewed";
import RecommendedProducts from "@project-stories/sections/RecommendedProducts/RecommendedProducts";
import { useEffect, useState } from "react";
import shopify from "../js/shopify";
import recommendedProducts from "@arctheme-components/ecommerce/RecommendedProducts/RecommendedProducts";
import FeaturedProducts from "@project-stories/components/FeaturedProducts/FeaturedProducts";
import { mobileBreakpoint } from "../entrypoints/theme";

const sampleProducts = [
  {
    product: {
      id: "1",
      title: "Product A",
      image: "https://via.placeholder.com/150",
      price: 99.99,
      url: "/",
    },
  },
  {
    product: {
      id: "2",
      title: "Product B",
      image: "https://via.placeholder.com/150",
      price: 149.99,
      url: "/",
    },
  },
  {
    product: {
      id: "3",
      title: "Product C",
      image: "https://via.placeholder.com/150",
      price: 199.99,
      url: "/",
    },
  },
];

export function RecommendedProductList({ ...props }) {
  const [products, setProducts] = useState([]);
  const { product } = props.settings;

  useEffect(() => {
    async function fetchProductRecommendations(productId) {
      const recommendedProducts =
        await shopify.getRecommendedProducts(productId);
      setProducts(recommendedProducts);
    }

    if (product?.id) {
      fetchProductRecommendations(product.id);
    }
  }, [product]);

  const recentlyviewedproducts = recentlyviewed.component().get();

  if (products?.length === 0 || recentlyviewedproducts?.length === 0) {
    return null;
  }

  return (
    <RecommendedProducts
      products={products}
      settings={props.settings.section}
      recentlyviewed_products={recentlyviewedproducts}
    />
  );
}

export function FeaturedProductsComponent({ ...props }) {
  const [products, setProducts] = useState([]);
  const { product_handles } = props.settings;

  useEffect(() => {
    if (product_handles && product_handles.length > 0) {
      // Extract product IDs from the handles
      const productIds = product_handles
        .map((handle) => {
          const match = handle.match(/Product\/(\d+)/);
          return match ? match[1] : null;
        })
        .filter((id) => id !== null);

      async function fetchProducts(productIds) {
        const products = await shopify.getShopifyProductList(productIds);
        setProducts(products);
      }

      fetchProducts(productIds);
    }
  }, [product_handles]);

  // Only render Featured Products if products are found
  if (products.length === 0) {
    return null;
  }

  return (
    <FeaturedProducts products={products} settings={props.settings.section} />
  );
}

export function ProductsListComponent({ ...props }) {
  const [products, setProducts] = useState([]);
  const { product_handles } = props.settings;
  props.settings.mobile_breakpoint = mobileBreakpoint;

  useEffect(() => {
    if (product_handles && product_handles.length > 0) {
      // Extract product IDs from the handles
      const productIds = product_handles
        .map((handle) => {
          if (handle != "" && handle != null) {
            // Check if the handle contains "Product/"
            if (handle.includes("Product/")) {
              const match = handle.match(/Product\/(\d+)/);
              return match ? match[1] : null;
            }
            return handle;
          }
        })
        .filter((id) => id !== null);

      async function fetchProducts(productIds) {
        const products = await shopify.getShopifyProductList(productIds);
        setProducts(products);
      }

      fetchProducts(productIds);
    }
  }, [product_handles]);

  return <ListProducts products={products} settings={props.settings} />;
}

export function RecentlyViewedProductsList({ ...props }) {
  console.log("Retrieiving Products from Recently Viewed Component");
  const products = recentlyviewed.component().get();

  if (products.length <= 0) {
    return false;
  }

  return (
    <ListProducts
      products={products}
      settings={{
        classes: "list-default",
        is_dynamic: true,
      }}
    />
  );
}
