import * as React from "react";
import useMobileBreakpoint from "@src/hooks/useMobileBreakpoint";
import Paragraph from "@project-stories/elements/Paragraph";
import { Headings } from "@project-stories/elements/Headings/Headings";
import {mobileBreakpoint} from "@src/../entrypoints/theme";

export const AccountPopup = ({ settings, show_always = false}) => {
  const popupRef = React.useRef(null); // Reference to the popup container
  const isMobile = useMobileBreakpoint(mobileBreakpoint);

  const handleClickOutside = (event) => {

    if (!show_always) {
      const isClickInsidePopup = popupRef.current && popupRef.current.contains(event.target);
      const isClickOnAccountIcon = event.target.closest(".account-icon");

      if (!isClickInsidePopup && !isClickOnAccountIcon) {
        const closeEvent = new CustomEvent("toggle-display-close", {
          detail: {message: "header-account"},
        });
        window.dispatchEvent(closeEvent);
      }
    } else {
      // const openEvent = new CustomEvent("toggle-display-open", {
      //   detail: {message: "header-account"},
      // });
      // window.dispatchEvent(openEvent);
    }
  };

  React.useEffect(() => {
    if (!show_always) {
      // Add the event listener when the component is mounted
      document.addEventListener("click", handleClickOutside);
    }

    // Remove the event listener when the component is unmounted
    return () => {
      if (!show_always) {
        document.removeEventListener("click", handleClickOutside);
      }
    };
  }, []);

  // Prevent event propagation when clicking inside the popup to stop the event from closing it
  const handlePopupClick = (event) => {
    event.stopPropagation();
  };

  const renderComponents = () => {
    return (
        <div ref={popupRef} className="account-popup relative container" onClick={handlePopupClick}>
          <div
              className="account-popup-details absolute right-0 w-full lg:w-auto"
              x-data="toggledisplay"
              x-init="$nextTick(() => {
            init('header-account');
          })"
          >
            <div className="flex flex-col w-full lg:w-[371px] h-[288px] p-[24px] justify-center items-center bg-white z-50 gap-[16px] text-center"
            x-show={`${ show_always ? "true" : "is_open"}`}>
              <div className="flex w-full">
                <a href="/account/login" className="button btn-outline w-full">
                  {settings.cta_button_signin_text}
                </a>
              </div>
              <Headings text={settings.cta_title} type={"h5"} />
              <Paragraph text={settings.cta_text} type={"b3"} />
              <div className="flex w-full">
                <a href="/account/register" className="button btn-primary w-full">
                  {settings.cta_button_signup_text}
                </a>
              </div>
            </div>
          </div>
        </div>
    );
  };

  return <>{renderComponents()}</>;
};

export default AccountPopup;
