import VideoContainer from "@arctheme-components/sections/VideoContainer/VideoContainer";
import { Testimonials } from "@arctheme-components/sections/Testimonials/Testimonials";
import { CollectionFilter } from "@arctheme-components/elements/Filters/Filters";
import Logo from "@arctheme-components/sections/Logo/Logo";
import Marquee from "@arctheme-components/sections/Marquee/Marquee";
import { FAQ } from "@arctheme-components/sections/FAQ/FAQ";

// Not in Core
import CategoryBanner from "@project-stories/ecommerce/Collection/CollectionBanner/CollectionBanner";
import CollectionHeader from "@project-stories/ecommerce/Collection/CollectionHeader/CollectionHeader";
import SearchResultsHeader from "@project-stories/ecommerce/Search/SearchResultsHeader/SearchResultsHeader";

// ALPINE JS
import newsletter from "../js/alpine/components/newsletter";
import Multicolumn from "@project-stories/sections/Multicolumn/Multicolumn";
import TextWithImage from "@project-stories/sections/TextWithImage/TextWithImage";
import Banner from "@project-stories/sections/Banner/Banner";
import NewsletterSignup from "@project-stories/ecommerce/NewsletterSignup/NewsletterSignup";

export function MulticolumnBlockSection({ ...props }) {
  return (
    <Multicolumn
      settings={props.settings}
      headerText={props.settings.section.title}
      subtext={props.settings.section.subtext}
      tagline={props.settings.section.tagline}
      columns={props.settings.blocks}
      columns_count={props.settings.section.columns_count}
      gap={props.settings.section.gap}
    />
  );
}

export function VideoContainerSection({ ...props }) {
  return (
    <VideoContainer
      containerTitle={props.settings.section.title}
      settings={props.settings.section}
      videos={props.settings.blocks}
    />
  );
}

export function MarqueeSection({ ...props }) {
  return <Marquee marquee_blocks={props.settings.blocks} />;
}

export function SectionTextWithImage({ ...props }) {
  return (
    <TextWithImage
      reverselayout={false}
      props={props.settings}
      settings={props.settings.section}
      blocks={props.settings.blocks}
    />
  );
}

export function FAQComponent({ ...props }) {
  return <FAQ settings={props.settings} />;
}

export function SectionTestimonials({ ...props }) {
  return (
    <Testimonials
      title={props.settings.section.title}
      subtext={props.settings.section.subtext}
      reviews={props.settings.blocks}
    />
  );
}

export function SectionNewsletterSubscribe({ ...props }) {
  async function customerSubscribe(email) {
    console.log("Subscribing Customer: " + email);
    await newsletter.component().subscribe(email);
  }

  return (
    <NewsletterSignup
      settings={props.settings}
      show_button={true}
      heading={props.settings.section.cta_title}
      content={props.settings.section.content}
      button_text={props.settings.section.button_text}
      cta_text={props.settings.section.cta_text}
      onClick={customerSubscribe}
    />
  );
}

export function SectionBanner({ ...props }) {

  return (
    <Banner
      banners={props.settings.blocks}
      useCarousel={props.settings.section.use_carousel}
      section_settings={props.settings.section}
      props={props.settings}
    />
  );
}

export function SectionCategoryBanner({ ...props }) {
  return <CategoryBanner settings={props.settings} />;
}

export function SectionCollectionHeader({ ...props }) {
  return <CollectionHeader settings={props.settings} />;
}

export function SectionSearchResultsHeader({ ...props }) {
  return <SearchResultsHeader settings={props.settings} />;
}

export function CollectionFilters({ ...props }) {
  return (
    <CollectionFilter
      title={props.settings.section.title}
      filters={props.settings.blocks}
    />
  );
}

export function SectionFeaturedCompanies({ ...props }) {
  return (
    <Logo
      title={props.settings.section.title}
      logos={props.settings.blocks}
      use_greyscale={props.settings.section.use_greyscale}
    />
  );
}
