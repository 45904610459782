import * as React from "react";
import { Headings } from "@arctheme-components/elements/Headings/Headings";
import { ProductTile } from "@project-stories/ecommerce/Product/ProductTile";
import useMobileBreakpoint from "@src/hooks/useMobileBreakpoint";
import { mobileBreakpoint } from "../../../../entrypoints/theme";

const RecommendedProducts = ({
  products = [],
  settings,
  recentlyviewed_products = [],
}) => {
  const [selectedCategory, setSelectedCategory] =
    React.useState("youMayAlsoLike");
  const isMobile = useMobileBreakpoint(mobileBreakpoint);

  const handleCategoryClick = (category) => {
    setSelectedCategory(category);
  };

  const renderProducts = (productList) => (
    <div
      className="recommendedproducts__container flex-col w-full max-w-max"
      x-data="carousel"
      x-init={
        `$nextTick(() => { initialise(${settings.show_indicators},${settings.show_navbuttons},${settings.fade_edgeitems},false,false, 'completethelook', false, ${settings.use_freescroll}) })`
      }
    >
      <div x-ref="carousel" className="carousel w-full">
        {productList.map((product) => (
          <div key={product.id} className="carousel-item w-full">
            <ProductTile
              product={product}
              swatches={product.swatches}
              siblings={undefined}
            />
          </div>
        ))}
      </div>
    </div>
  );

  let headingClass = "h3 cursor-pointer";

  if (isMobile) {
    headingClass = "bc";
  }
  return (
    <>
      {(products.length > 0 || recentlyviewed_products.length > 0) && (
        <div className="container recommendedproducts lg:px-[106px]">
          <div className="recommendedproducts__header">
            <div className="flex items-center mb-4">
              <div
                className={`basis-1/2 lg:basis-auto lg:px-[24px] carousel-tab-header`}
              >
                <div
                  className={`${headingClass} ${selectedCategory === "youMayAlsoLike" ? "selected text-black" : "text-gray-200"}`}
                  onClick={() => handleCategoryClick("youMayAlsoLike")}
                >
                  You may also like
                </div>
              </div>
              {recentlyviewed_products.length > 0 && (
                <div
                  className={
                    "basis-1/2 lg:basis-auto lg:px-[24px] carousel-tab-header"
                  }
                >
                  <div
                    className={`${headingClass} ${selectedCategory === "recentlyViewed" ? "selected text-black" : "text-gray-200"}`}
                    onClick={() => handleCategoryClick("recentlyViewed")}
                  >
                    Recently viewed
                  </div>
                </div>
              )}
              <div className="flex-1 border-b border-gray-300 hidden lg:flex"></div>
            </div>
          </div>
          {selectedCategory === "youMayAlsoLike" &&
            products &&
            renderProducts(products)}
          {selectedCategory === "recentlyViewed" &&
            recentlyviewed_products &&
            renderProducts(recentlyviewed_products)}
        </div>
      )}
    </>
  );
};

export default RecommendedProducts;
