import * as React from "react";
import ImageComponent from "@project-stories/elements/Image/Image";
import {getImageUrl} from "@src/stories/components/Tile/TileMethods";

const TileImage = ({ data, height, isMobile }) => {
    const imageUrl = getImageUrl(data, isMobile)

    let heightStyle = "100%";
    if (parseInt(height) > 0){
        heightStyle = height;
    }

    return (
        <>
        {data.settings.image && (
                <div
                    className={`image-container overflow-hidden ${data.settings.darken_image && "content-over-media"} relative`}
                    style={{ height: heightStyle }}
                >
                    <ImageComponent
                        imageUrl={imageUrl}
                        alt={data.settings.title}
                        classes={`object-cover h-full w-full`}
                        style={{ borderRadius: data.settings.media_rounding }}
                    />
                </div>
            )}
        </>
    );
};

export default TileImage;
