import * as React from "react";
import { clsx } from "clsx";

type ButtonProps = {
  icon?: string;
  type?: "button" | "submit" | "reset" | "link" | undefined;
  style?:
    | "primary"
    | "secondary"
    | "tertiary"
    | "text"
    | "dark-primary"
    | "dark-secondary"
    | "dark-tertiary"
    | "dark-text";
  text?: boolean;
  label?: string;
  link?: string;
  icon_size?: number;
  className?: string;
  customStyle?: object;
  reverse?: boolean;
  disabled?: boolean;
  onClick?: Function;
};

export function Button({
  icon,
  type = "button",
  style = "primary",
  text = true,
  link,
  icon_size,
  className,
  customStyle,
  reverse,
  label,
  disabled,
  onClick,
}: ButtonProps) {
  function _click() {
    if (onClick) {
      onClick();
    }
  }

  return (
    <>
      {link && type === "link" && (
        <div className="flex">
          <a
            href={link}
            className={`btn-link w-full lg:w-auto flex items-center`}
          >
            {text && label}
          </a>
          {icon && <i className={`icon ${icon} text-[${icon_size}px]`}></i>}
        </div>
      )}

      {!link && (
        <button
          type="submit"
          className={`${type} ${className} ${reverse ? "reverse" : ""}`}
          onClick={_click}
          disabled={disabled}
          style={customStyle}
        >
          {text && label}
          {icon && <i className={`icon ${icon} text-[${icon_size}px]`}></i>}
        </button>
      )}

      {link && type !== "link" && (
        <a href={link} className="w-full lg:w-auto">
          <button
            className={`${type} ${className} button ${style} btn-link ${reverse ? "reverse" : ""}`}
            style={customStyle}
          >
            {text && label}
            {icon && <i className={`icon ${icon} text-[${icon_size}px]`}></i>}
          </button>
        </a>
      )}
    </>
  );
}

export default Button;
