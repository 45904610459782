import React, {useEffect, useState} from "react";
import { Headings } from "@arctheme-components/elements/Headings/Headings";
import { Paragraph } from "@arctheme-components/elements/Paragraph/Paragraph";
import { Button } from "../../elements/Button";
import useMobileBreakpoint from "@src/hooks/useMobileBreakpoint";
import { mobileBreakpoint } from "../../../../entrypoints/theme";
import { clsx } from "clsx";
import ImageComponent from "@project-stories/elements/Image/Image";
import useVideoSource from "@src/hooks/useVideoSourceQuality";

export const TextWithImage = ({
  reverselayout,
  description,
  settings,
  blocks,
  props,
}) => {
  const isMobile = useMobileBreakpoint(mobileBreakpoint);
  const [videoSources, setVideoSources] = useState([]);

  const { globals } = props;
  let desktopImage = settings.image?.src ?? settings.image;
  let mobileImage = settings.image_mobile?.src ?? settings.image_mobile;

  // Pre-process the video sources using the hook
    const videoSettings = isMobile
        ? settings?.video_mobile
        : settings?.video;

    const videoSourcesToCheck = videoSettings?.sources;

    // Call the hook to get video URL and poster for each video block
  let processedVideos: any = null;
  let videoUrl: any = null;
  let videoPoster: any = null;
  if (videoSourcesToCheck) {
    const {videoUrl, videoPoster} = useVideoSource(
        videoSourcesToCheck,
        videoSettings?.preview_image,
        isMobile,
        globals.video_quality
    );

    processedVideos = {videoUrl, videoPoster};
  }

  useEffect(() => {
    if (processedVideos) {

      setVideoSources(processedVideos);
    }
  }, []); // useEffect will trigger when processedVideos changes

  let heading = settings.title;
  let type = "twi-twocolumn";
  const imageWidth = isMobile ? settings.width_mobile : settings.width;

  if (settings) {
    if (settings.content) {
      description = settings.content;
    }
    if (settings.reverse_layout) {
      reverselayout = settings.reverse_layout;
    }
    if (settings.classes) {
      type = settings.classes;
    }
  }

  if (settings.render_type !== "all") {
    if (isMobile && settings.render_type !== "mobile-only") return null;
    if (!isMobile && settings.render_type !== "desktop-only") return null;
  }

  const renderComponents = () => {
    switch (type) {
      case "twi-twocolumn":
        return (
          <section className={`w-full ${type}`}>
            <div
              className={`twi-twocolumn__container lg:h-[${settings.container_height}px] overflow-hidden relative`}
            >
              <div
                className={`twi-twocolumn__content gap-x-[20px] ${clsx({
                  "flex-col": isMobile,
                  "flex-col-reverse":
                    isMobile && settings.reverse_layout_mobile,
                  "flex-row-reverse": reverselayout && settings.reverse_layout,
                })}`}
              >
                <div
                  className="twi-container"
                  style={{
                    flexBasis: `calc(${100 - parseFloat(imageWidth)}%)`,
                  }}
                >
                  <div
                    className={`twi-content text-${settings.content_alignment_mobile} lg:text-${settings.content_alignment_desktop} items-${settings.content_position_desktop}`}
                  >
                    <div className={"w-full lg:w-[381px]"}>
                      <Paragraph text={settings.tagline} type={"bc"} />
                      <Headings text={heading} type={settings.heading_type} />
                      <Paragraph text={description} type={"b3 py-[16px]"} />
                      {blocks.map((block, index) => (
                        <div key={block.key || index}>
                          {block.settings.button_text && (
                            <Button
                              label={block.settings.button_text}
                              type={block.settings.button_style}
                              link={block.settings.button_url}
                              icon={block.settings.button_icon}
                              icon_size={20}
                              onClick={undefined}
                            />
                          )}
                        </div>
                      ))}
                    </div>
                  </div>
                </div>

                <div
                  className="flex flex-col justify-center space-y-4 lg:basis-1/2"
                  style={{ flexBasis: imageWidth + "%" }}
                >
                  {!isMobile && (
                    <div className={`flex h-full w-full`}>
                      {desktopImage ? (
                        <ImageComponent
                          imageUrl={desktopImage}
                          classes={"object-cover h-full w-full"}
                        />
                      ) : videoSources.videoUrl ? (
                        <video
                          className={"object-cover h-full w-full"}
                          autoPlay
                          loop
                          muted
                          poster={videoSources.videoPoster}
                          preload="metadata"
                          playsInline
                        >
                          <source src={videoSources.videoUrl} type="video/mp4"></source>
                        </video>
                      ) : null}
                    </div>
                  )}
                  {isMobile && (
                    <div className={`flex h-full w-full`}>
                      {mobileImage ? (
                        <ImageComponent
                          imageUrl={mobileImage}
                          classes={"object-cover h-full w-full"}
                        />
                      ) : videoSources.videoUrl ? (
                        <video
                          className={"object-cover h-full w-full"}
                          autoPlay
                          loop
                          muted
                          poster={videoSources.videoPoster}
                          preload="metadata"
                          playsInline
                        >
                          <source src={videoSources.videoUrl} type="video/mp4"></source>
                        </video>
                      ) : null}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </section>
        );
      case "twi-overlayed":
        return (
          <div className={`${type}`}>
            <div className={`image-container overlay content-over-media`}>
              {!isMobile && (
                <>
                  {desktopImage ? (
                    <ImageComponent
                      imageUrl={desktopImage}
                      classes={"object-cover h-full w-full"}
                    />
                  ) : videoUrl ? (
                    <video
                      className={"object-cover h-full w-full"}
                      autoPlay
                      loop
                      muted
                      poster={videoPoster}
                      preload="metadata"
                      playsInline
                    >
                      <source src={videoUrl} type="video/mp4"></source>
                    </video>
                  ) : null}
                </>
              )}
              {isMobile && (
                <>
                  {mobileImage ? (
                    <ImageComponent
                      imageUrl={mobileImage}
                      classes={"object-cover h-full w-full"}
                    />
                  ) : videoUrl ? (
                    <video
                      className={"object-cover h-full w-full"}
                      autoPlay
                      loop
                      muted
                      poster={videoPoster}
                      preload="metadata"
                      playsInline
                    >
                      <source src={videoUrl} type="video/mp4"></source>
                    </video>
                  ) : null}
                </>
              )}
              <div className={`twi-container`}>
                <div className="twi-content">
                  <Headings text={heading} type={settings.heading_type} />
                  <Paragraph text={description} type={"b3"} />
                  <div className={"flex"}>
                    {blocks.map((block, index) => (
                      <div key={block.key || index}>
                        {block.settings.button_text && (
                          <Button
                            type={block.settings.button_style}
                            label={block.settings.button_text}
                            link={block.settings.button_url}
                            icon={block.settings.button_icon}
                            icon_size={20}
                            onClick={undefined}
                          />
                        )}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
    }
  };

  return <>{renderComponents()}</>;
};

export default TextWithImage;
