import React, { useState, useEffect } from "react";
import ImageComponent from "@project-stories/elements/Image/Image";
import Carousel from "@src/stories/ecommerce/Product/ProductGallery/Layouts/Carousel";
import Thumbnail from "@src/stories/ecommerce/Product/ProductGallery/Layouts/Thumbnail";
import Stacked from "@src/stories/ecommerce/Product/ProductGallery/Layouts/Stacked";
import Tiled from "@src/stories/ecommerce/Product/ProductGallery/Layouts/Tiled";

export const ProductGallery = ({
  images,
  onImageClick,
  galleryType,
  videoUrl,
  isGiftCard,
}) => {

  return (
    <div>
      {galleryType === "tiled" && (

          <Tiled images={images} onImageClick={onImageClick} galleryType={galleryType} videoUrl={videoUrl} isGiftCard={isGiftCard} />
      )}

      {galleryType === "stacked" && (

          <Stacked images={images} onImageClick={onImageClick} galleryType={galleryType} videoUrl={videoUrl} isGiftCard={isGiftCard} />
      )}

      {galleryType === "carousel" && (
          <Carousel images={images} onImageClick={onImageClick} galleryType={galleryType} videoUrl={videoUrl} isGiftCard={isGiftCard} />
      )}

      {galleryType === "thumbnail" && (

          <Thumbnail images={images} onImageClick={onImageClick} galleryType={galleryType} videoUrl={videoUrl} isGiftCard={isGiftCard} />
      )}
    </div>
  );
};

export default ProductGallery;
