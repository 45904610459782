import React from 'react';
import ImageComponent from "@src/stories/elements/Image/Image";

const Tiled = ({   images,
                      onImageClick,
                      galleryType,
                      videoUrl,
                      isGiftCard
                  }) => {

    const handleVideoClick = () => {
        const videoElement = document.getElementById("product-video");
        if (videoElement) {
            const offset = -150; // Adjust this value as needed
            const elementPosition =
                videoElement.getBoundingClientRect().top + window.pageYOffset;
            const offsetPosition = elementPosition + offset;

            window.scrollTo({
                top: offsetPosition,
                behavior: "smooth",
            });
            // @ts-ignore
            videoElement.querySelector("iframe").src += "&autoplay=1";
        }
    };
    return (
        <>
            <div className="image-gallery relative">
                {videoUrl && (
                    <div
                        className="absolute m-[23px] cursor-pointer top-0 left-0 w-[104px] opacity-[0.6] shadow border border-white"
                        onClick={handleVideoClick}
                    >
                        <ImageComponent
                            imageUrl={images[1].url}
                            alt={images[1].alt}
                            isLazy={true}
                        />
                        <div className="absolute inset-0 flex items-center justify-center">
                            <svg
                                className="w-12 h-12 text-white"
                                fill="currentColor"
                                viewBox="0 0 24 24"
                            >
                                <path d="M8 5v14l11-7z" />
                            </svg>
                        </div>
                    </div>
                )}
                {images.map((image, index) => (
                    <div
                        key={index}
                        className="image-item"
                        onClick={() => onImageClick(image)}
                    >
                        <img src={image.url} alt={image.alt} />
                    </div>
                ))}
                {videoUrl && (
                    <div id="product-video" key={"video"} className="video-item">
                        <div
                            className="video-iframe relative w-full h-full top-0 left-0 object-cover"
                            data-v-2da62827=""
                        >
                            <iframe
                                className="absolute w-full h-full top-0 left-0"
                                src={videoUrl}
                                width="100%"
                                height="100%"
                                data-v-2da62827=""
                            ></iframe>
                        </div>
                    </div>
                )}
            </div>
        </>
    );
};

export default Tiled;
