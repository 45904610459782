import React, {useRef} from 'react';
import ImageComponent from "@src/stories/elements/Image/Image";
import CarouselNavigation from "@src/stories/client/NavigationElements/CarouselNavigation";

const Carousel = ({   images,
                      onImageClick,
                      galleryType,
                      videoUrl,
                      isGiftCard
}) => {
    const carouselRef = useRef(null);
    return (
        <div className={`product-gallery__carousel`}>
            <div
                x-data="carousel"
                className={`image-carousel`}
                x-init={`$nextTick(() => { initialise(true, false, false, false, false, 'gallery', false, ${false}) })`}
            >

                <div
                    className={`carousel-images carousel w-full h-auto ${isGiftCard ? "gift-card" : ""}`}
                    x-ref={images.length > 1 ? "carousel" : ""}
                    ref={carouselRef}
                >
                    {images.map((image, index) => (
                        <div key={index} className="w-full">
                            <ImageComponent
                                key={index}
                                imageUrl={image.url}
                                alt={image.alt}
                                isLazy={true}
                            />
                        </div>
                    ))}
                    {videoUrl && (
                        <div
                            key={"video"}
                            className="video-item"
                            style={{height: "100%", width: "100%", pointerEvents: "none"}}
                        >
                            <div className="video-iframe h-full" data-v-2da62827="">
                                <iframe
                                    src={videoUrl}
                                    width="100%"
                                    height="100%"
                                    data-v-2da62827=""
                                ></iframe>
                            </div>
                        </div>
                    )}
                </div>
            </div>
            <div className={"banner-carousel__controls z-[5]"}>
                <CarouselNavigation
                    shiftPageDots={true}
                    carouselRef={carouselRef}
                    spread={true}
                />
            </div>
        </div>
    );
};

export default Carousel;
