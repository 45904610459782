import * as React from "react";
import useMobileBreakpoint from "@src/hooks/useMobileBreakpoint";

const MegamenuFeaturedImage = ({
  blockItem,
  index,
  section_settings,
}) => {
  const isMobile = useMobileBreakpoint("1024px");

  let imageWidth = section_settings.image_width;
  if (imageWidth == 0) {
    imageWidth = "100%";
  }
  let imageHeight = section_settings.image_height;
  if (imageHeight == 0) {
    imageHeight = "100%";
  }

  // Check if mobile_image is available and use it, otherwise use the default image
  let imageUrl = blockItem.image?.src ?? blockItem.image;
  if (isMobile) {
    imageUrl = blockItem.mobile_image
      ? blockItem.mobile_image
      : blockItem.image?.src ?? blockItem.image;
  }

  return (
    <div
      key={`cta-${index}`}
      className="megamenu__cta-container relative h-full"
    >
      <a href={blockItem.url} className="megamenu__cta-item">
        <div className="w-full h-full">
          <img
            src={imageUrl}
            className="w-full h-full object-cover"
            alt={blockItem?.title}
          />
        </div>
        <div className={"absolute bottom-0 left-0 p-4 text-white"}>
          <div className="h4 mb-5">{blockItem?.title}</div>
          <div className="megamenu__cta-item">{blockItem?.button_text}</div>
        </div>
      </a>
    </div>
  );
};

export default MegamenuFeaturedImage;
